import React from 'react';
import { currencyFormatter } from '@utils';
import { ProgressionSum, MilestoneListColumnTypeV2 } from './common';
import Stack from '@mui/material/Stack';

/**
 * 
 *  <HeaderText tooltipText="">
      <ColumnLabel>Retainage balance</ColumnLabel>
      <ColumnLabel>
        to date{' '}
        {project?.retainage_rate && `(${percentFormatter({ value: project?.retainage_rate })})`}
      </ColumnLabel>
    </HeaderText>
 */

const retainageBalanceTodateApproved: MilestoneListColumnTypeV2 = {
  field: 'retainage_balance_to_date',
  headerAlign: 'right',
  headerClassName: 'number',
  cellClassName: 'number',
  headerName: 'Retainage balance to date ($)',
  description: 'Amount retainage balance to date ($)',
  renderCell: ({ row }) => {
    const progress = -1 * row.retainage_release_approved;
    const sum = row.retainage_balance_to_date + row.retainage_release_approved;

    return (
      <Stack lineHeight="14px" alignItems="flex-end">
        {currencyFormatter(sum, '-')}
        <ProgressionSum sum={sum} progress={progress} />
      </Stack>
    );
  },
  editable: false,
  minWidth: 120,
};

export default retainageBalanceTodateApproved;
