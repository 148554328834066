import React, { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import { FilterButtonV2, unitsFilter } from '@components';
import { getTypeFilter, usePHBGrouping, usePHBNaming } from '@utils';
import { useParams } from 'react-router-dom';

const UnitsFilter: FC<{
  handleUnitsFilterClick: (value: string[]) => void;
  unitsFilterValues: string[];
}> = ({ handleUnitsFilterClick, unitsFilterValues }) => {
  const { unitName } = usePHBNaming();
  const { projectId } = useParams();
  const { unitLineItemGrouping } = usePHBGrouping();

  const filterConfig = {
    ...unitsFilter,
    getDataParamsPaginated: (pagination, q) => ({
      ...unitsFilter.getDataParamsPaginated(pagination, q),
      args: { projectId, groupBy: unitLineItemGrouping },
    }),
    title: unitName,
  };

  return (
    <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
      <Typography variant="body2SemiBold">Type: </Typography>
      <Typography variant="body2" sx={{ minWidth: '190px' }}>
        {getTypeFilter(!!unitsFilterValues?.length).filterValue}
      </Typography>
      <FilterButtonV2
        handleFiltersChange={(_key, value) => handleUnitsFilterClick(value)}
        initValues={unitsFilterValues}
        maxWidth="300px"
        minWidth="150px"
        closeOnChange
        defaultFilterLabel="Not selected"
        {...filterConfig}
      />
    </Stack>
  );
};
export default UnitsFilter;
