import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import {
  StringFieldModel,
  useStringFieldModel,
  usePhoneFieldModel,
  PhoneFieldModel,
} from '@models';
import { addUserToTeams, getCompanyTeams } from '@globalService';
import {
  AddUserToTeamsPayload,
  EnumTypeForList,
  ErrorDual,
  ITeam,
  PermissionNamesEnums,
  QueryNamesEnums,
} from '@interfaces';
import { checkIsOwner, getErrorText, getTeamRole, isRestricted, regexValidation } from '@utils';
import { AuthContext, PermissionsContext } from '@context';
import { useSafeSnackbar } from '@hooks';

interface ControllerInterface {
  email: StringFieldModel;
  firstName: StringFieldModel;
  lastName: StringFieldModel;
  phone: PhoneFieldModel;
  handleAddCompanyUser: () => void;
  isSubmitting: boolean;
  teams: EnumTypeForList[];
  optionsLoading: boolean;
  isOpened: boolean;
  setOpened: Dispatch<SetStateAction<boolean>>;
  onCloseCallback: () => void;
  selectedTeams: EnumTypeForList[];
  setSelectedTeams: Dispatch<SetStateAction<EnumTypeForList[]>>;
  inviteDisabled: boolean;
  isEditUsersEnabled: boolean;
}

export const useAddCompanyUser = ({ borrowerCompanyId }): ControllerInterface => {
  const { user } = useContext(AuthContext);
  const teamRole = getTeamRole(user);
  const { companyId } = useParams();
  const { permissions } = useContext(PermissionsContext);

  // this query param is used to determine if response should contains
  // all company teams or only teams that has shared projects with current user company
  // to implement independent borrower teams for different lenders concept
  const is_on_shared_project = useMemo(() => Boolean(borrowerCompanyId), [borrowerCompanyId]);
  const [isOpened, setOpened] = useState(false);
  const { enqueueSnackbar } = useSafeSnackbar();
  const queryClient = useQueryClient();
  const companyTeamsQuery = useQuery<{ results: ITeam[] }, Error>(
    [
      QueryNamesEnums.GET_COMPANY_TEAMS,
      { companyId: companyId || borrowerCompanyId, is_on_shared_project },
    ],
    getCompanyTeams.bind(this, companyId || borrowerCompanyId, is_on_shared_project),
    { enabled: Boolean(companyId || borrowerCompanyId) },
  );

  const [selectedTeams, setSelectedTeams] = useState<EnumTypeForList[]>([]);

  const email = useStringFieldModel({
    initValue: '',
    validationRule: (value) => regexValidation('email', value) && Boolean(value?.trim()),
  });

  const firstName = useStringFieldModel({
    initValue: '',
  });
  const lastName = useStringFieldModel({
    initValue: '',
  });
  const phone = usePhoneFieldModel({
    initValue: '',
  });

  const addCompanyUserToSeveralTeamsMutation = useMutation<
    Response,
    ErrorDual,
    AddUserToTeamsPayload
  >(addUserToTeams, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryNamesEnums.GET_COMPANY_USERS]);
      queryClient.invalidateQueries([QueryNamesEnums.GET_COMPANY_TEAMS]);
      queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_COMPANIES]);
      onCloseCallback();
    },
    onError: (error: ErrorDual) => {
      enqueueSnackbar(getErrorText(error), {
        variant: 'error',
      });
    },
  });

  const handleAddCompanyUser = useCallback(() => {
    const isTeamValid = selectedTeams.length > 0;
    const isEmailValid = email.validate();
    const isPhoneValid = phone.validate();

    const isAllFieldsValid = isTeamValid && isEmailValid && isPhoneValid;
    if (!isAllFieldsValid) return;

    addCompanyUserToSeveralTeamsMutation.mutateAsync({
      companyId: borrowerCompanyId || companyId,
      data: {
        teams: selectedTeams.map((o) => o.id),
        user: {
          email: email.value,
          first_name: firstName.value,
          last_name: lastName.value,
          phone: phone.valueToSave,
        },
      },
    });
  }, [companyId, email, selectedTeams, firstName, lastName, phone, borrowerCompanyId]);

  const teams = useMemo(
    () =>
      companyTeamsQuery.data?.results?.map((o) => ({
        id: o.id,
        name_display: o.name,
        name: o.id,
      })) || [],
    [companyTeamsQuery.data],
  );

  const setInitialValues = useCallback(() => {
    if (teams?.length === 1 || (checkIsOwner(teamRole) && teams?.length > 1))
      setSelectedTeams(teams);

    email.setValue('');
    firstName.setValue('');
    lastName.setValue('');
    phone.setValue('');
  }, [teams]);

  useEffect(() => {
    setInitialValues();

    return () => {
      setSelectedTeams([]);
    };
  }, [teams]);

  const onCloseCallback = useCallback(() => {
    setInitialValues();
    setOpened(false);
  }, []);

  const isEditUsersEnabled = useMemo(
    () =>
      companyId
        ? !isRestricted(PermissionNamesEnums.COMPANY_USERS_EDIT, permissions) ||
          !isRestricted(PermissionNamesEnums.COMPANY_TEAMS_EDIT, permissions)
        : !isRestricted(PermissionNamesEnums.PROJECT_ONBOARDING, permissions),
    [permissions],
  );

  return {
    email,
    firstName,
    lastName,
    phone,
    handleAddCompanyUser,
    isSubmitting: addCompanyUserToSeveralTeamsMutation.isLoading,
    teams,
    optionsLoading: companyTeamsQuery.isLoading,
    isOpened,
    setOpened,
    onCloseCallback,
    selectedTeams,
    setSelectedTeams,
    inviteDisabled: !teams?.length,
    isEditUsersEnabled,
  };
};
