import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useMutation, useQueries, useQuery, useQueryClient } from 'react-query';
import {
  CSVPHBBudgetSectionsKeysEnum,
  DeleteAllListItemsParam,
  ErrorDual,
  IFilterOption,
  IMenuItem,
  IMilestone,
  IMilestoneTotal,
  IPHBTableItem,
  IProjectMilestone,
  MutationKeyEnum,
  PatchListItem,
  PatchListItemParam,
  PatchProjectProdBuildGroupParam,
  QueryNamesEnums,
  TableKeyEnum,
} from '@interfaces';
import {
  deleteProjectBuildingModels,
  deleteProjectMilestones,
  getProject,
  getProjectDrawRequestsList,
  getProjectMilestonesList,
  patchProjectProdBuildGroup,
  pathMilestoneItem,
} from '@globalService';
import { useParams } from 'react-router-dom';
import {
  checkHasAmountChanges,
  getItemLocalHighlight,
  getPHBViewTypes,
  getTypeFilter,
  isCreatedProject,
  parsePathErrorDual,
  replaceMilestoneData,
  sortByUnitName,
  useExpandCollapseTable,
  useLoadingSkeleton,
  usePHBFilters,
  usePHBGrouping,
  usePHBNaming,
} from '@utils';
import { useSafeSnackbar } from '@hooks';
import { DeleteIcon } from '@svgAsComponents';
import { Typography } from '@mui/material';
import { colors } from '@theme';
import { SettingsContext, useLaunchDarklyFlags } from '@context';

export type ControllerInterface = {
  initColumns: string[];
  units: IPHBTableItem[];
  onExpandClick: (id: string, isExpanded: boolean) => void;
  filterOptions: IFilterOption[];
  filterValue: string;
  handleFilterClick: (value: string) => void;
  isLoading: boolean;
  menuItems: IMenuItem[];
  isDeleteBudgetPopupOpen: boolean;
  setIsDeleteBudgetPopupOpen: React.Dispatch<React.SetStateAction<boolean>>;
  deleteBudget: (selectedKeys: CSVPHBBudgetSectionsKeysEnum[]) => void;
  isDeleting: boolean;
  totals: IMilestoneTotal;
  patchMsGroup: (params) => void;
  activeView: string;
  setActiveView: Dispatch<SetStateAction<string>>;
  filteredMilestones: IPHBTableItem[];
  isLineItemsView: boolean;
  viewTypes: { label: string; value: string }[];
  patchMilestone: (args: PatchListItem) => void;
  handleUnitsFilterClick: (value: string[]) => void;
  unitsFilterValues: string[];
};

export const useBudgetTable = (): ControllerInterface => {
  const { projectId } = useParams();
  const queryClient = useQueryClient();
  const [listItems, setListItems] = useState([]);
  const [isDeleteBudgetPopupOpen, setIsDeleteBudgetPopupOpen] = useState(false);
  const { showLoadingSkeleton, showTemporaryLoadingSkeleton } = useLoadingSkeleton();
  const { enqueueSnackbar } = useSafeSnackbar();
  const { isCurrentProjectActive } = useContext(SettingsContext);
  const { unitLineItemGrouping } = usePHBGrouping();
  const { unitName } = usePHBNaming();
  const flags = useLaunchDarklyFlags();

  const viewTypes = useMemo(() => getPHBViewTypes(unitName), [unitName]);
  const [activeView, setActiveView] = useState<string>(viewTypes[0].value);
  const [unitsFilterValues, setUnitsFilterValues] = useState<string[]>([]);

  const handleUnitsFilterClick = (value: string[]) => {
    setUnitsFilterValues(value);
  };

  const query = '{*}';
  const totalsQuery = '{totals}';
  const requestedDataQueries = useQueries([
    {
      queryKey: [QueryNamesEnums.GET_PROJECT, { projectId }],
      queryFn: getProject.bind(this, projectId),
    },
    {
      queryKey: [QueryNamesEnums.GET_PROJECT_DRAW_REQUEST_LIST, { projectId }],
      queryFn: getProjectDrawRequestsList.bind(this, projectId),
    },
  ]);

  const project = requestedDataQueries[0].data;
  const drawRequests = requestedDataQueries[1].data?.results;

  const { filterValue, handleFilterClick, filterOptions, filterKey } = usePHBFilters({
    tableKey: TableKeyEnum.PHB_LINE_ITEMS,
    isNewView: true,
  });

  const projectMilestonesTotalsQuery = useQuery<
    { results: IProjectMilestone[]; totals: IMilestoneTotal },
    Error
  >(
    [QueryNamesEnums.GET_PROJECT_MILESTONES, { projectId, query: totalsQuery }],
    getProjectMilestonesList.bind(this, { projectId, query: totalsQuery }),
    { enabled: Boolean(projectId) },
  );

  const typeFilterKey = useMemo(
    () => getTypeFilter(!!unitsFilterValues?.length).filterKey,
    [unitsFilterValues],
  );

  const filteredMilestonesQuery = useQuery<{ results: IProjectMilestone[]; count: number }, Error>(
    [
      QueryNamesEnums.GET_PROJECT_MILESTONES,
      {
        projectId,
        query,
        filterKey: typeFilterKey,
        milestoneTags: unitsFilterValues.join(),
      },
    ],
    getProjectMilestonesList.bind(this, {
      projectId,
      query,
      filterKey: typeFilterKey,
      milestoneTags: unitsFilterValues.join(),
    }),
    {
      enabled: Boolean(projectId && typeFilterKey),
    },
  );

  const projectMilestonesQuery = useQuery<{ results: IProjectMilestone[]; count: number }, Error>(
    [
      QueryNamesEnums.GET_PROJECT_MILESTONES,
      {
        projectId,
        query,
        groupBy: unitLineItemGrouping,
        filterKey,
      },
    ],
    getProjectMilestonesList.bind(this, {
      projectId,
      query,
      groupBy: unitLineItemGrouping,
      filterKey,
    }),
    { enabled: Boolean(projectId && unitLineItemGrouping) },
  );
  const projectMilestones = projectMilestonesQuery.data;

  const deleteBudgetMutation = useMutation<Response, ErrorDual, DeleteAllListItemsParam>(
    deleteProjectMilestones,
    {
      mutationKey: MutationKeyEnum.MILESTONE_DELETE,
      onSuccess: () => {
        queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_MILESTONES, { projectId }]);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
      onSettled: () => {
        setIsDeleteBudgetPopupOpen(false);
      },
    },
  );

  const deleteAllModelsMutation = useMutation<Response, ErrorDual, { project: string }>(
    deleteProjectBuildingModels,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_BUILDING_MODELS, { projectId }]);
        queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_BUILDING, { projectId }]);
        queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_MILESTONE_TAGS, { projectId }]);
      },
      onError: (error) => {
        enqueueSnackbar(parsePathErrorDual(error), { variant: 'error' });
      },
    },
  );

  useEffect(() => {
    setListItems(null);
  }, [filterValue, projectMilestones?.count]);

  useEffect(() => {
    if (!projectMilestones?.results?.length || listItems?.length) return;

    setListItems(
      projectMilestones.results.map((item, index) => createTableObject({ item, index })),
    );
  }, [projectMilestones?.results, listItems]);

  const createTableObject = ({
    item,
    isExpanded = false,
    isNested = false,
    index,
  }): IPHBTableItem => ({
    ...item,
    activeToEdit: false,
    localNew: false,
    localHighlight: getItemLocalHighlight(item),
    canBeExpanded: item?.milestone_groups?.length > 0,
    isExpanded,
    isNested,
    index,
    project_milestone: { ...item.project_milestone, index },
  });

  const { onExpandClick, updateListItemsWithParentGroup } = useExpandCollapseTable({
    setListItems,
    createTableObject,
  });

  const retainageRate = useMemo(() => project?.retainage_rate, [project]);

  const hasAmountChanges = useMemo(
    () => checkHasAmountChanges(projectMilestones?.results),
    [projectMilestones?.results],
  );

  const initColumns = useMemo(
    () => [
      'productionBuildExpand',
      'nameV2',
      'costType',
      'originalConstructionBudget',
      'prefundingCost',
      'originalEstimate',
      ...(retainageRate ? ['retainageRateBudget'] : []),
      'revisedConstructionBudget',
      ...(hasAmountChanges ? ['adjustmentsTotalApprovedBudget'] : []),
      'revisedMilestoneAmount',
      'balanceToFinish',
      'balanceToFinishRate',
      'inspectorAllowance',
      'inspectorAllowanceRate',
      'approvedAmountCumulative',
      'lenderAllowanceRate',
      'varianceToLenderAllowanceRate',
      'documentsPhotosUploaderMenu',
      ...(flags?.['ENG_8397_docs_photos_circle_indication']
        ? ['documentsPhotosGalleryMenuV2']
        : ['documentsPhotosGalleryMenu']),
      ...(isCurrentProjectActive ? [] : ['deleteLineItem']),
      'comments',
    ],
    [retainageRate, hasAmountChanges, isCurrentProjectActive, flags],
  );

  const menuItems = useMemo(
    () =>
      isCreatedProject(project?.status) && !drawRequests?.length
        ? [
            {
              text: (
                <Typography variant="body3SemiBold" sx={{ color: colors.status.error.medium }}>
                  Delete budget section
                </Typography>
              ),
              icon: <DeleteIcon size={16} color={colors.status.error.medium} />,
              action: () => setIsDeleteBudgetPopupOpen(true),
              dataTestName: 'project__budget__delete__button',
            },
          ]
        : [],
    [project, drawRequests],
  );

  const removeAllModels = async () =>
    deleteAllModelsMutation.mutateAsync({
      project: projectId,
    });

  const removeAllMilestones = async () =>
    deleteBudgetMutation.mutateAsync({
      projectId,
    });

  const removeBudgetSection = ({ isVerticalSelected, isHorizontalSelected }) => {
    deleteBudgetMutation.mutateAsync({
      projectId,
      isVertical: isVerticalSelected,
      isHorizontal: isHorizontalSelected,
    });
  };

  const deleteBudget = (selectedKeys: CSVPHBBudgetSectionsKeysEnum[]) => {
    if (!selectedKeys?.length) {
      setIsDeleteBudgetPopupOpen(false);
      return;
    }

    const isVerticalSelected = selectedKeys.includes(CSVPHBBudgetSectionsKeysEnum.IS_VERTICAL);
    const isHorizontalSelected = selectedKeys.includes(CSVPHBBudgetSectionsKeysEnum.IS_HORIZONTAL);
    const isAllSelected = selectedKeys.includes(CSVPHBBudgetSectionsKeysEnum.ALL);

    if (isAllSelected) {
      removeAllMilestones();
      removeAllModels();
    } else if (isVerticalSelected && isHorizontalSelected) {
      removeAllMilestones();
    } else {
      removeBudgetSection({ isVerticalSelected, isHorizontalSelected });
    }
  };

  const totals = useMemo(
    () => ({ ...projectMilestonesTotalsQuery.data?.totals, displayAll: true }),
    [projectMilestonesTotalsQuery.data?.totals],
  );

  const patchProjectProdBuildGroupMutation = useMutation<
    IMilestone,
    ErrorDual,
    PatchProjectProdBuildGroupParam
  >(patchProjectProdBuildGroup, {
    mutationKey: MutationKeyEnum.MILESTONE_PATCH,
    onSuccess: (data) => {
      queryClient.setQueriesData<{ results: IMilestone[] }>(
        {
          queryKey: [
            QueryNamesEnums.GET_PROJECT_MILESTONES,
            {
              projectId,
              query,
              groupBy: unitLineItemGrouping,
              filterKey,
            },
          ],
        },
        (old) =>
          replaceMilestoneData({
            milestones: old,
            milestoneId: data.id,
            json: data,
          }),
      );
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_PROJECT_MILESTONES,
        { projectId, query, filterKey: typeFilterKey, milestoneTags: unitsFilterValues.join() },
      ]);
      updateListItemsWithParentGroup(data);
    },
  });

  const patchMilestoneMutation = useMutation<Response, ErrorDual, PatchListItemParam>(
    pathMilestoneItem,
    {
      mutationKey: MutationKeyEnum.MILESTONE_PATCH,
      onError: (error) => {
        enqueueSnackbar(parsePathErrorDual(error), { variant: 'error' });
      },
      onSuccess: () => {
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_PROJECT_MILESTONES,
          { projectId, query, groupBy: unitLineItemGrouping, filterKey },
        ]);
        queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_FUNDS, { projectId }]);
        queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT, { projectId }]);
      },
    },
  );

  const patchMilestone = useCallback(
    (args: PatchListItem) =>
      patchMilestoneMutation.mutate({
        project: projectId,
        milestone: args.milestone,
        json: args.json,
      }),
    [projectId],
  );

  const patchMsGroup = useCallback(
    (params) =>
      patchProjectProdBuildGroupMutation.mutate({
        project: projectId,
        group_by: unitLineItemGrouping,
        tags: params.milestoneTags,
        json: params.json,
        filterKey,
      }),
    [projectId, unitLineItemGrouping, filterKey],
  );

  const isLineItemsView = useMemo(() => activeView === viewTypes[1].value, [activeView]);

  const filteredMilestones = useMemo(
    () =>
      filteredMilestonesQuery.data?.results
        ?.sort(sortByUnitName)
        ?.map((item, index) =>
          createTableObject({ item: { ...item, milestone_groups: [] }, index }),
        ),
    [filteredMilestonesQuery.data?.results],
  );

  return {
    initColumns,
    units: listItems,
    onExpandClick,
    filterOptions,
    filterValue,
    handleFilterClick: (value: string) => {
      showTemporaryLoadingSkeleton();
      handleFilterClick(value);
    },
    menuItems,
    isDeleteBudgetPopupOpen,
    setIsDeleteBudgetPopupOpen,
    deleteBudget,
    isDeleting: deleteBudgetMutation.isLoading,
    isLoading:
      showLoadingSkeleton ||
      projectMilestonesQuery.isLoading ||
      projectMilestonesQuery.isIdle ||
      projectMilestonesTotalsQuery.isLoading,
    totals,
    patchMsGroup,
    activeView,
    setActiveView,
    filteredMilestones,
    isLineItemsView,
    viewTypes,
    patchMilestone,
    handleUnitsFilterClick,
    unitsFilterValues,
  };
};
