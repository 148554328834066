import React from 'react';
import { CellRenderWithError } from './common';
import { currencyFormatter } from '@utils';
import { CurrencyInput } from '@components';
import { isFooterRow, MilestoneListColumnTypeV2 } from '../columnsV2/common';
import { GridEditInputCell } from '@mui/x-data-grid';

// TODO add permissions
const approvedAdjustments: MilestoneListColumnTypeV2 = {
  field: 'approved_adjustments',
  headerAlign: 'right',
  headerClassName: 'number',
  cellClassName: 'number',
  headerName: 'Approved +/- change ($)',
  description: 'Approved changes to revised scheduled value for this request',
  editableByMilestone: (row) => row.isEditableV2,
  editableByPermissions: (_pr) => true,
  renderEditCell: (props) => <GridEditInputCell {...props} inputComponent={CurrencyInput} />,
  renderCell: (params) => <CellRenderWithError params={params} />,
  valueFormatter: (value, row) =>
    currencyFormatter(isFooterRow(row) ? row.approved_budget_change : value, '-'),
  minWidth: 120,
};

export default approvedAdjustments;
