import React, { FC } from 'react';
import { Grid, IconButton, Skeleton, Stack, Typography, Button } from '@mui/material';

import { HookState } from '@interfaces';
import {
  InviteUsersButton,
  LoadingSkeleton,
  ServiceMessage,
  StyledBox,
  UsersList,
} from '@components';
import { ArrowBackIcon, DotIcon, TeamEnrollmentImage } from '@svgAsComponents';
import * as Controller from './controller';
import { colors } from '@theme';
import { teamRolesMap } from '@constants';

const ProjectPeople: FC = () => {
  const controller = Controller.useProjectPeople();

  const {
    companies,
    goBack,
    project,
    columns,
    state,
    projectQueryState,
    borrowerCompany,
    isProjectStatusCreated,
    goToAddBorrower,
  } = controller;

  return (
    <Stack direction="column" sx={{ height: '100%' }}>
      <Stack sx={{ p: 2, height: 72 }} direction="row" alignItems="center">
        {projectQueryState === HookState.FETCHING && <Skeleton sx={{ width: '100%' }} />}
        {projectQueryState === HookState.SUCCESS && (
          <>
            <IconButton onClick={goBack}>
              <ArrowBackIcon />
            </IconButton>
            <Typography sx={{ ml: 2 }} variant="h2">
              {project?.name}
            </Typography>
          </>
        )}
      </Stack>
      <Stack sx={{ flex: 1, backgroundColor: colors.background.gray, p: 3 }} spacing={2}>
        <StyledBox
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Grid item>
            <Typography variant="h2">People</Typography>
          </Grid>
        </StyledBox>
        {state === HookState.FETCHING && (
          <StyledBox>
            <LoadingSkeleton type="overviewBlock" />
          </StyledBox>
        )}
        {state === HookState.ERROR && (
          <StyledBox>
            <ServiceMessage text="Project people" />
          </StyledBox>
        )}
        {state === HookState.SUCCESS && (
          <>
            {!borrowerCompany?.id && (
              <StyledBox sx={{ p: 0 }}>
                <Stack flexDirection="row" alignItems="center" sx={{ pt: 2, pl: 2 }}>
                  <Typography variant="body1SemiBold" mr={2}>
                    Borrower
                  </Typography>
                </Stack>
                <Stack alignItems="center" justifyContent="center" spacing={3} sx={{ mb: 6 }}>
                  <TeamEnrollmentImage size={'200'} />
                  <Typography variant="h2">No added borrower yet</Typography>
                  <Button
                    data-cy="project_people__link_to_add_borrower"
                    onClick={() => goToAddBorrower()}
                  >
                    Add borrower
                  </Button>
                </Stack>
              </StyledBox>
            )}

            {companies.map((company) => (
              <StyledBox key={company.name} sx={{ p: 0 }}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ pt: 2, px: 2 }}
                >
                  <Stack flexDirection="row" alignItems="center">
                    <Typography variant="body1SemiBold" mr={2}>
                      {company.role}
                    </Typography>
                    <DotIcon />
                    <Typography variant="body1" color={colors.text.medium} ml={2}>
                      Company:&nbsp;
                    </Typography>
                    <Typography variant="body1SemiBold">{company.name}</Typography>
                  </Stack>

                  {teamRolesMap.OWNER === company.role && (
                    <InviteUsersButton
                      title={isProjectStatusCreated ? 'Add borrower' : 'Invite borrower'}
                      borrowerCompanyId={borrowerCompany?.id}
                      isProjectStatusCreated={isProjectStatusCreated}
                      size="small"
                    />
                  )}
                </Stack>

                {Boolean(company.company_members?.length) && (
                  <Stack mt={3}>
                    <UsersList
                      columns={
                        teamRolesMap.OWNER === company.role
                          ? columns
                          : columns.filter((column) => column !== 'optionsMenu')
                      }
                      showRemoveFromTeamOption
                      users={company.company_members}
                      isActivationAllowed={[teamRolesMap.LENDER, teamRolesMap.OWNER].includes(
                        company.role,
                      )}
                      showCoordinator={teamRolesMap.LENDER === company.role}
                      coordinator={project.coordinator}
                      fixedWidth
                    />
                  </Stack>
                )}
              </StyledBox>
            ))}
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default ProjectPeople;
