import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { DocumentSectionContextEnum, IDrawRequest, QueryNamesEnums } from '@interfaces';
import { getDrawRequest } from '@globalService';
import { isChangeRequest, isRequestDraftOrInReview } from '@utils';
import { ComponentProps } from './interface';

export const usePhotoPanel = ({ drawRequestId, milestoneId, inspectionId }): ComponentProps => {
  const { projectId } = useParams();
  const [totalDocCount, setTotalDocCount] = useState({
    [DocumentSectionContextEnum.PRIMARY as string]: -1,
    [DocumentSectionContextEnum.OTHER as string]: -1,
  });

  const noPhotos = useMemo(
    () =>
      totalDocCount[DocumentSectionContextEnum.PRIMARY] === 0 &&
      totalDocCount[DocumentSectionContextEnum.OTHER] === 0,
    [totalDocCount],
  );

  const isProjectLevel = useMemo(
    () => !drawRequestId && !inspectionId,
    [drawRequestId, inspectionId],
  );

  const drawRequestQuery = useQuery<IDrawRequest, Error>(
    [QueryNamesEnums.GET_DRAW_REQUEST, { projectId, drawRequestId }],
    getDrawRequest.bind(this, { projectId, drawRequestId }),
    { enabled: Boolean(drawRequestId) },
  );

  const canUploadPhoto = useMemo(
    () =>
      isRequestDraftOrInReview(drawRequestQuery?.data?.status) || isProjectLevel || inspectionId,
    [drawRequestQuery?.data?.status, isProjectLevel],
  );

  const headerText = useMemo(() => {
    if (isProjectLevel) return 'Project';

    if (inspectionId) return 'Inspection';

    return `${isChangeRequest(drawRequestQuery?.data) ? 'Change' : 'Draw'} #${drawRequestQuery?.data?.number}`;
  }, [isProjectLevel, drawRequestQuery, milestoneId, inspectionId]);

  const updateDocumentsCount = () => {
    setTotalDocCount((prev) => {
      return {
        ...prev,
        [DocumentSectionContextEnum.PRIMARY]: prev[DocumentSectionContextEnum.PRIMARY] + 1,
      };
    });
  };

  return {
    headerText,
    canUploadPhoto,
    setTotalDocCount,
    noPhotos,
    updateDocumentsCount,
  };
};
