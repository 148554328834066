import { PermissionsContext } from '@context';
import { TableKeyEnum } from '@interfaces';
import {
  GridCellParams,
  GridEventListener,
  GridState,
  GridTreeNode,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import { diffObjects } from '@utils';
import { useState, useEffect, useCallback, useRef, useContext, useMemo } from 'react';
import { TRIGGERHEIGHT } from './styled';
import { useUpdateUiSettings } from '@hooks';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';

const useDataTable = (
  tableKey: TableKeyEnum,
  rows: any[],
  errors: any,
  rowUpdateApi?: ({ id, json }) => void,
) => {
  const { userSettings, updateTableSettings } = useUpdateUiSettings();
  const { permissions } = useContext(PermissionsContext);
  const apiRef = useGridApiRef();
  const tabelRef = useRef<HTMLElement>();
  const [isTablePinned, setIsTablePinned] = useState(false);

  const mainElement = useRef(document.querySelector('body'));

  const initialState = useMemo<GridInitialStatePremium>(
    () => userSettings.tableV3?.[tableKey] || {},
    [userSettings.tableV3?.[tableKey]],
  );

  const requiredToPin = useMemo(() => {
    if (!apiRef.current.state) return true;
    const { dimensions } = apiRef.current.state;
    const totalHeight = dimensions.headersTotalHeight + dimensions.rowHeight * rows.length;
    const maxHeight = mainElement.current.getBoundingClientRect().height;
    return totalHeight > maxHeight;
  }, [rows, apiRef.current.state, mainElement]);

  const checkPosition = useCallback(() => {
    if (tabelRef.current) {
      const rect = tabelRef.current.getBoundingClientRect();
      const isPinned = rect.top <= TRIGGERHEIGHT;
      setIsTablePinned(isPinned);
    }
  }, [tabelRef]);

  const isCellEditable = useCallback(
    (params: GridCellParams<any, any, any, GridTreeNode>) => {
      if (params.row.id === 'totals') return false;
      const errorForCurrentCell = apiRef.current.state['errors']?.[params.field]?.[params.row.id];
      const byMilestone = params.colDef['editableByMilestone']?.(params.row);
      return !!errorForCurrentCell || !!byMilestone;
    },
    [permissions, apiRef.current.state],
  );

  const processRowUpdate = useCallback(
    (updatedRow, originalRow) => {
      if (!rowUpdateApi) return updatedRow;
      const updateDelta = diffObjects(updatedRow, originalRow);
      if (Object.keys(updateDelta).length) {
        rowUpdateApi({
          id: updatedRow.id,
          json: { ...updateDelta },
        });
      }
      return updatedRow;
    },
    [rowUpdateApi],
  );

  const saveCurrentSet = useCallback(() => {
    const currentState = apiRef.current.exportState();
    updateTableSettings(tableKey, currentState);
  }, [updateTableSettings, apiRef.current]);

  const handleColumnOrderChange: GridEventListener<'columnOrderChange'> = useCallback(
    saveCurrentSet,
    [],
  );

  const handlePinnedColumnsChange = useCallback(saveCurrentSet, []);

  const handleColumnWidthChange: GridEventListener<'columnWidthChange'> = useCallback(
    saveCurrentSet,
    [],
  );

  useEffect(() => {
    apiRef.current.setState((state: GridState) => ({
      ...state,
      errors,
    }));
    apiRef.current.forceUpdate();
  }, [errors]);

  useEffect(() => {
    window.addEventListener('scroll', checkPosition);
    return () => {
      window.removeEventListener('scroll', checkPosition);
    };
  }, [requiredToPin]);

  return {
    isTablePinned,
    tabelRef,
    setIsTablePinned,
    apiRef,
    isCellEditable,
    processRowUpdate,
    requiredToPin,
    handleColumnWidthChange,
    handlePinnedColumnsChange,
    handleColumnOrderChange,
    initialState,
    settingsLoaded: userSettings,
  };
};

export default useDataTable;
