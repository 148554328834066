import { MilestoneListColumnTypeV2 } from './common';
import { currencyFormatter } from '@utils';

const previousApprovedAmountCumulative: MilestoneListColumnTypeV2 = {
  field: 'previous_approved_amount_cumulative',
  headerAlign: 'right',
  headerClassName: 'number',
  cellClassName: 'number',
  headerName: 'Approved to date ($)',
  description:
    'Implied cumulative amount eligible to be paid per the inspector across all line items',
  valueFormatter: (value) => currencyFormatter(value, '-'),
  minWidth: 120,
  editable: false,
};

export default previousApprovedAmountCumulative;
