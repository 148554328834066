import React, { FC, useContext } from 'react';
import { Grid, Typography, Stack, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import truncate from 'lodash/truncate';

import { dateFormatter, isCustomer } from '@utils';
import { AuthContext } from '@context';
import { IProjectComment } from '@interfaces';
import { colors } from '@theme';
import { ArrowRightIcon } from '@svgAsComponents';

const MAX_SENDER_TEXT_LENGTH = 33;
const MAX_RECIPIENT_TEXT_LENGTH = 15;
const StyledSpan = styled('span')(() => ({
  color: colors.text.medium,
  fontWeight: 700,
}));

export const CommentHeader: FC<{
  comment: IProjectComment;
  source: string;
  index: number;
  showRecipient?: boolean;
}> = ({ comment, source, index, showRecipient = false }) => {
  const { user } = useContext(AuthContext);

  const getRecipient = () => {
    if (!showRecipient || !comment.received_by_company) return null;
    const receivedBy = comment.received_by_company?.name;
    return (
      <Tooltip title={receivedBy?.length > MAX_RECIPIENT_TEXT_LENGTH ? receivedBy : ''}>
        <Stack direction="row" spacing={1} alignItems="center">
          <ArrowRightIcon size={12} />
          <Typography variant="labelSemiBold">
            {truncate(receivedBy, { length: MAX_RECIPIENT_TEXT_LENGTH }) || ''}
          </Typography>
        </Stack>
      </Tooltip>
    );
  };

  const getSenderCompanyOrTeam = () => {
    const sentBy =
      comment.created_by?.full_name +
      ' / ' +
      (isCustomer(user) ? comment.team?.name : comment.team?.company?.name);
    return (
      <Tooltip title={sentBy?.length > MAX_SENDER_TEXT_LENGTH ? sentBy : ''}>
        <Typography variant="labelSemiBold">
          {truncate(sentBy, { length: MAX_SENDER_TEXT_LENGTH })}
        </Typography>
      </Tooltip>
    );
  };

  const headerText = () => {
    if (comment?.is_external_comment && comment?.commenter) {
      return (
        <>
          <b>{comment.commenter.split(' / ')?.[0]}</b>
          <StyledSpan> {' / ' + comment.commenter.split(' / ')?.[1]}</StyledSpan>
        </>
      );
    }
    if (!comment.created_by) return '';
    return (
      <Stack direction="row" spacing={1} alignItems="center">
        {getSenderCompanyOrTeam()}
        {getRecipient()}
      </Stack>
    );
  };

  return (
    <Grid container justifyContent="space-between">
      <Typography variant="label" data-cy={`${source}__comment__index_${index}__author`}>
        {headerText()}
      </Typography>
      <Typography
        variant="label"
        color={colors.text.medium}
        data-cy={`${source}__comment__index_${index}__date`}
      >
        {dateFormatter({ date: comment.created_at, withTime: true })}
      </Typography>
    </Grid>
  );
};

export default CommentHeader;
