import React, { FC } from 'react';
import { Divider, Stack, Box, Typography } from '@mui/material';

import { AuditLogItem, ServiceMessage, LoadingSkeleton, TablePaginationNew } from '@components';
import { useAuditLog } from './controller';
import { HookState } from '@interfaces';

const AuditLog: FC<{ projectId: string; sorting: boolean; filters: string[]; source: string }> = ({
  projectId,
  sorting,
  filters,
  source,
}) => {
  const {
    state,
    auditLogData,
    tablePagination: { page, rowsPerPage, rowsPerPageOptions, onPageChange, onRowsPerPageChange },
    itemsCount,
  } = useAuditLog({ projectId, sorting, filters });
  switch (state) {
    case HookState.FETCHING: {
      return <LoadingSkeleton type="overviewBlock" />;
    }
    case HookState.ERROR: {
      return <ServiceMessage text="audit log" />;
    }

    case HookState.SUCCESS: {
      return (
        <>
          <Stack alignItems="flex-end" mt={2}>
            <TablePaginationNew
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={rowsPerPageOptions}
              itemsCount={itemsCount}
              onPageChange={onPageChange}
              onRowsPerPageChange={onRowsPerPageChange}
              source={source}
            />
          </Stack>

          {auditLogData?.length ? (
            <Stack justifyContent="flex-start" alignItems="stretch" sx={{ py: 2 }}>
              {auditLogData?.map((item, index) => (
                <Box key={item?.created_at}>
                  <AuditLogItem item={item} source={source} index={index} />
                  {index !== auditLogData?.length - 1 && <Divider sx={{ mt: 2, mb: 3 }} />}
                </Box>
              ))}
            </Stack>
          ) : (
            <Typography>No Activity Log data yet</Typography>
          )}
        </>
      );
    }
  }
};

export default AuditLog;
