import { useMutation, useQuery, useQueryClient } from 'react-query';
import { INotifications, PermissionNamesEnums, QueryNamesEnums } from '@interfaces';
import { getUserNotifications, patchUserNotifications } from '@globalService';
import { useSafeSnackbar } from '@hooks';
import { useCallback, useContext, useEffect, useState } from 'react';
import { PermissionsContext, useLaunchDarklyFlags } from '@context';
import { isRestricted } from '@utils';

interface ICategory {
  name: string;
  value: string;
  isChecked: boolean;
  subText: string[];
}

interface ControllerInterface {
  categories: ICategory[];
  updateNotifications: (category: string, value: boolean) => void;
  onSetupClick: () => void;
}

export const useEmailNotifications = (
  saveDataCategoryClick: boolean,
  onClose?: () => void,
): ControllerInterface => {
  const { enqueueSnackbar } = useSafeSnackbar();
  const queryClient = useQueryClient();
  const { permissions } = useContext(PermissionsContext);
  const flags = useLaunchDarklyFlags();
  const [categories, setCategories] = useState<ICategory[] | null>(null);

  const userNotificationsQuery = useQuery<INotifications, Error>(
    [QueryNamesEnums.GET_USER_NOTIFICATIONS],
    getUserNotifications.bind(this),
  );

  useEffect(() => {
    if (permissions && userNotificationsQuery.data && !categories && flags) {
      setCategories([
        ...(flags?.['eng-8897-notification-group-critical-project-update']
          ? [
              {
                name: 'Critical project updates',
                value: 'critical_project_updates_enabled',
                subText: [
                  'Notifications powered by our AI, which keep you updated only on critical events of a project.',
                ],
                isChecked: userNotificationsQuery.data.critical_project_updates_enabled,
              },
            ]
          : []),
        {
          name: 'Project updates',
          value: 'project_updates_enabled',
          subText: [
            'Notifications for project status changes and milestones for projects you are watching.',
          ],
          isChecked: userNotificationsQuery.data.project_updates_enabled,
        },
        {
          name: 'Request updates',
          value: 'draw_request_updates_enabled',
          subText: [
            'Notifications for draw requests awaiting your approval. (watching project is not required)',
            'Notifications for all draw events for projects you are watching.',
          ],
          isChecked: userNotificationsQuery.data.draw_request_updates_enabled,
        },
        ...(isRestricted(PermissionNamesEnums.INSPECTIONS_VIEW, permissions)
          ? []
          : [
              {
                name: 'Services updates',
                value: 'service_updates_enabled',
                subText: [
                  'Notifications for completed automated inspections in projects you are watching.',
                ],
                isChecked: userNotificationsQuery.data.service_updates_enabled,
              },
            ]),
      ]);
    }
  }, [userNotificationsQuery.data, categories, permissions, flags]);

  const updateNotificationsMutation = useMutation<
    INotifications,
    Error,
    { params: Partial<INotifications> }
  >(patchUserNotifications, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryNamesEnums.GET_USER_NOTIFICATIONS);
      if (saveDataCategoryClick) enqueueSnackbar('Notification updated', { variant: 'success' });
      if (onClose) onClose();
    },
    onError: (error) => {
      userNotificationsQuery.refetch();
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const updateNotifications = useCallback((category: string, value: boolean) => {
    setCategories((prev) =>
      prev.map((prevCategory) => {
        if (prevCategory.value === category) {
          return { ...prevCategory, isChecked: value };
        }
        return prevCategory;
      }),
    );
    if (saveDataCategoryClick) {
      updateNotificationsMutation.mutateAsync({
        params: { [category]: value },
      });
    }
  }, []);

  const categoriesToObject = (categories) => {
    return categories.reduce((acc, category) => {
      acc[category.value] = category.isChecked;
      return acc;
    }, {});
  };

  const onSetupClick = useCallback(() => {
    const params = categoriesToObject(categories);
    updateNotificationsMutation.mutateAsync({
      params,
    });
  }, [categories]);

  return { categories, updateNotifications, onSetupClick };
};
