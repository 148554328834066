import React, { useContext } from 'react';
import { Box, Divider, Stack, Typography } from '@mui/material';

import { ItemCheckedIcon, ItemSkippedIcon, ItemUncheckedIcon } from '@svgAsComponents';
import { PoliciesListItemComponentProps } from './interface';
import {
  dateFormatter,
  isCompletionDatePolicyItem,
  isInspectionPolicyItem,
  isPolicyItemDefault,
  isRestricted,
} from '@utils';
import { PoliciesListItemIcons } from './components';
import { PermissionNamesEnums } from '@interfaces';
import { PermissionsContext } from '@context';

const PoliciesListItem = ({
  isTemplate,
  showActionIcons,
  item,
  index,
  source,
}: PoliciesListItemComponentProps) => {
  const { permissions } = useContext(PermissionsContext);

  if (!item) return null;

  const { label, checked, exception_reasons, note, type, metadata } = item;

  const itemIcom = () => {
    if (checked) return <ItemCheckedIcon />; // done
    if (exception_reasons?.length) return <ItemSkippedIcon />; // skipped
    return <ItemUncheckedIcon />;
  };

  const policyItemIconTestName = () => {
    if (checked) return 'done';
    if (exception_reasons?.length) return 'skipped';
    return 'undone';
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', flex: 1 }}>
          {!isTemplate && (
            <Box
              sx={{ height: 24 }}
              data-cy={`${source}_policy__items__list__${policyItemIconTestName()}__icon__index_${index}`}
            >
              {itemIcom()}
            </Box>
          )}
          <Stack sx={{ ml: 0.5 }} direction="row" spacing={1}>
            <Typography
              variant="body2"
              data-cy={`${source}_policy__items__list__label__index_${index}`}
            >
              {label}{' '}
              {isPolicyItemDefault(type) &&
                !isRestricted(PermissionNamesEnums.POLICIES_VIEW, permissions) && (
                  <Typography variant="label">(Default)</Typography>
                )}
            </Typography>
          </Stack>
        </Box>
        <Stack sx={{ ml: 1, alignItems: 'flex-end' }}>
          <PoliciesListItemIcons
            item={item}
            index={index}
            source={source}
            showActionIcons={showActionIcons}
          />
        </Stack>
      </Box>
      {isCompletionDatePolicyItem(type) && checked && (
        <Stack sx={{ ml: '28px' }}>
          <Typography
            variant="body3"
            data-cy={`${source}_policy__items__list__completion_date_comment__index_${index}`}
          >
            {'Completion date: ' +
              (metadata?.date && metadata?.reasons.length
                ? `${dateFormatter({
                    date: metadata?.date,
                  })} Reasons: ${metadata?.reasons.join(', ')}`
                : `${dateFormatter({ date: metadata?.date })} (not updated)`)}
          </Typography>
        </Stack>
      )}
      {isInspectionPolicyItem(type) && checked && metadata?.date && (
        <Stack sx={{ ml: '28px' }}>
          <Typography
            variant="body3"
            data-cy={`${source}_policy__items__list__inspection_comment__index_${index}`}
          >
            {`No earlier than: ${dateFormatter({ date: metadata?.date })}`}
          </Typography>
        </Stack>
      )}
      {!checked &&
        exception_reasons?.map((reason) => (
          <Stack
            key={reason.id}
            sx={{
              ml: '28px',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ flex: 1 }}>
              <Typography
                dangerouslySetInnerHTML={{ __html: `Reason: ${reason.exception_reason}` }}
                variant="body3"
                data-cy={`${source}_policy__items__list__exception_reason__index_${index}`}
              />
            </Box>
          </Stack>
        ))}
      {checked && (note || metadata?.comment) && (
        <Stack sx={{ ml: '28px' }}>
          <Typography
            dangerouslySetInnerHTML={{ __html: `${note || metadata?.comment}` }}
            variant="body3"
            data-cy={`${source}_policy__items__list__comment__index_${index}`}
          />
        </Stack>
      )}
      <Divider sx={{ mt: 1.5 }} />
    </>
  );
};

export default PoliciesListItem;
