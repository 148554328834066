import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { IActivityLogItem, QueryNamesEnums, ITablePagination, HookState } from '@interfaces';
import { getHookState } from '@utils';
import { getProjectAuditLog } from '@globalService';
import { useTablePagination } from '@hooks';
import { useLaunchDarklyFlags } from '@context';
import { defaultRowsPerPageOptions } from '@constants';

interface ControllerInterface {
  state: HookState;
  auditLogData: IActivityLogItem[];
  tablePagination: ITablePagination;
  itemsCount: number;
}

export const useAuditLog = ({ projectId, sorting, filters }): ControllerInterface => {
  const flags = useLaunchDarklyFlags();
  const tablePagination = useTablePagination({
    initialRowsPerPage: defaultRowsPerPageOptions.documets[0],
    rowsPerPageOptions: defaultRowsPerPageOptions.documets,
  });

  const queryURL = useMemo(() => {
    let stringQueryParams = `&offset=${
      tablePagination.page * tablePagination.rowsPerPage
    }&limit=${tablePagination.rowsPerPage}&sorting=${sorting ? '-created_at' : 'created_at'}`;

    if (filters.length) {
      stringQueryParams += `&tags=${filters.join(',')}`;
    }
    return stringQueryParams;
  }, [tablePagination.page, tablePagination.rowsPerPage, filters, flags, sorting]);

  const projectAuditLogQuery = useQuery<{ results: IActivityLogItem[]; count: number }, Error>(
    [QueryNamesEnums.GET_PROJECT_AUDIT_LOG, { projectId, queryURL }],
    getProjectAuditLog.bind(this, projectId, queryURL),
    {
      staleTime: 0,
    },
  );

  const itemsCount = useMemo(() => projectAuditLogQuery.data?.count, [projectAuditLogQuery.data]);
  return {
    state: getHookState(projectAuditLogQuery),
    auditLogData: projectAuditLogQuery.data?.results,
    tablePagination,
    itemsCount,
  };
};
