import {
  INotifications,
  IRefreshTokens,
  ITeam,
  ITokens,
  IUser,
  PatchCompanyUserPayload,
  UpdateUserFields,
} from '@interfaces';
import {
  cancelInvitationPath,
  companyUserPath,
  createPasswordPath,
  emailVerifyPath,
  forgotPasswordPath,
  loginPath,
  logoutPath,
  mfaStatusPath,
  notifications,
  projectCompanies,
  refreshTokenPath,
  resendInvitePath,
  resetPasswordPath,
  sendInvitationPath,
  user,
} from '../paths';
import { dataProvider } from '@api';

export const refreshTokens = async (): Promise<IRefreshTokens> =>
  dataProvider.post(refreshTokenPath()).json();

export const postLogout = async (): Promise<Response> => dataProvider.post(logoutPath()).json();

export const getUserMe = async (): Promise<IUser> => dataProvider.get(user()).json();

export const updateUserMe = async ({
  first_name,
  last_name,
  phone,
}: UpdateUserFields): Promise<IUser> =>
  dataProvider.put(user(), { json: { first_name, last_name, phone } }).json();

export const postAgreedToTerms = async (): Promise<Response> =>
  dataProvider.put(user(), {
    json: {
      agreed_to_terms: true,
    },
  });

export const postLogin = async (params): Promise<ITokens> =>
  await dataProvider
    .post(loginPath(), {
      json: params,
    })
    .json();

export const checkMfaStatus = async (params): Promise<{ is_mfa_enabled: boolean }> =>
  dataProvider
    .post(mfaStatusPath(), {
      json: params,
    })
    .json();

export const createPassword = async (password): Promise<Response> =>
  await dataProvider.post(createPasswordPath(), {
    json: { password },
  });

export const forgotPassword = async (email): Promise<Response> =>
  await dataProvider.post(forgotPasswordPath(), {
    json: { email },
  });

export const resetPassword = async (params): Promise<Response> =>
  await dataProvider.post(resetPasswordPath(), {
    json: params,
  });

export const patchCompanyUser = async ({
  companyId,
  userId,
  status,
}: PatchCompanyUserPayload): Promise<Response> =>
  dataProvider.patch(companyUserPath(companyId, userId), { json: { status } });

export const sendInvitation = async ({ userId }): Promise<Response> =>
  dataProvider.post(sendInvitationPath(userId));

export const cancelInvitation = async ({ userId }): Promise<Response> =>
  dataProvider.post(cancelInvitationPath(userId));

export const getProjectCompanies = async (projectId): Promise<ITeam[]> =>
  dataProvider.get(projectCompanies(projectId)).json();

export const getUserNotifications = async (): Promise<INotifications> =>
  dataProvider.get(notifications()).json();

export const patchUserNotifications = async ({
  params,
}: {
  params: Partial<INotifications>;
}): Promise<INotifications> => dataProvider.patch(notifications(), { json: params }).json();

export const resendInvite = async (email): Promise<Response> =>
  dataProvider.post(resendInvitePath(), {
    json: { email },
  });

export const emailVerify = async (token): Promise<Response> =>
  dataProvider.post(emailVerifyPath(), {
    json: { email_confirm_token: token },
  });
