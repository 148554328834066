import React, { FC, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Stack, Typography } from '@mui/material';
import {
  MessagePanelTabsEnum,
  PermissionNamesEnums,
  PolicyItemTypesEnum,
  TableKeyEnum,
} from '@interfaces';
import {
  ButtonWithTooltip,
  Filter,
  LineItemEdit,
  MessagePanel,
  MessagePanelButton,
  MilestoneListWithPatch,
  ProjectNameLabel,
  RightDrawer,
  StyledBox,
} from '@components';
import SubmissionSummary from '../SubmissionSummary';
import {
  getDefaultChecklistItem,
  isRestricted,
  useCallbackByConditions,
  WithPermission,
} from '@utils';
import {
  IChecklistController,
  IMilestoneList,
  IRequestController,
  IValidationController,
} from '../../main/interfaces';
import { colors } from '@theme';
import SubmissionAutofill from '../SubmissionAutofill';
import { PermissionsContext, SettingsContext, useLaunchDarklyFlags } from '@context';
import { ProductionRequestTable, ProductionRequestTableV2 } from '../../../productionBuild';
import { LoadingButton } from '@mui/lab';
import { BLOCKED_POLICY_REASON, SubmissionSectionKeyMap } from '@constants';

const MilestoneListModal: FC<{
  controller: IMilestoneList;
  checklistController: IChecklistController;
  validationController: IValidationController;
  requestController: IRequestController;
  isReallocationAllowed: boolean;
}> = ({
  controller,
  checklistController,
  validationController,
  isReallocationAllowed,
  requestController,
}) => {
  const flags = useLaunchDarklyFlags();
  const { permissions } = useContext(PermissionsContext);
  const { isPHBProject } = useContext(SettingsContext);
  const navigate = useNavigate();
  const {
    lineItemModal,
    setLineItemModal,
    isReallocateComplete,
    openEditMilestoneModal,
    filterValue,
    handleFiltersChange,
    filterOptions,
    isMilestoneMutatingOrFetching,
  } = controller;
  const { checklistItems, handleDone } = checklistController;
  const { onSectionButtonsClick, validatedSection } = validationController;
  const {
    project: projectData,
    drawRequest,
    handleRightDrawerOpenerClick,
    rightMenu,
  } = requestController;

  const { run, isWaiting } = useCallbackByConditions({
    callback: () => navigate(`/projects/${projectData.id}/requests/${drawRequest.id}/submission/`),
    conditions: !isMilestoneMutatingOrFetching,
  });

  const checklistItem = getDefaultChecklistItem(
    checklistItems,
    PolicyItemTypesEnum.DRAW_REQUEST_FORM,
  );
  const sectionKey = SubmissionSectionKeyMap[PolicyItemTypesEnum.DRAW_REQUEST_FORM];
  const disabled = !validatedSection?.[sectionKey]?.isValid;

  return (
    <Stack sx={{ height: '100%' }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ p: 3 }}>
        <Typography variant="h1">Request amounts</Typography>
        <Stack direction="row" spacing={2} justifyContent="flex-end">
          <ProjectNameLabel project={projectData} />
          {flags?.['ENG_8324_add_commenting_button_to_borrower_submission'] && (
            <MessagePanelButton
              commentsPreview={projectData?.comments_preview}
              handleRightDrawerOpenerClick={handleRightDrawerOpenerClick}
              source="request_submission__edit_request_table"
            />
          )}
        </Stack>
        <RightDrawer {...rightMenu}>
          <MessagePanel
            projectId={projectData?.id}
            requestId={drawRequest?.id}
            source={'project__comments'}
            tab={MessagePanelTabsEnum.BORROWER}
          />
        </RightDrawer>
      </Stack>

      <Stack
        sx={{
          backgroundColor: colors.background.gray,
          flex: 1,
          p: 3,
          mb: 11,
        }}
      >
        <Stack spacing={1} width="100%">
          <SubmissionSummary
            retainageRate={projectData.retainage_rate}
            projectLoan={projectData.loan}
            request={drawRequest}
            isDrawRequest={controller.isDrawRequest}
            isReallocateComplete={isReallocateComplete}
            isReallocationAllowed={isReallocationAllowed}
            canBeCollapsed
          />
          {isPHBProject ? (
            flags?.['ENG-8622-new-phb-view'] ? (
              <ProductionRequestTableV2 isSubmissionProcess />
            ) : (
              <ProductionRequestTable isSubmissionProcess />
            )
          ) : (
            <StyledBox sx={{ p: 0 }}>
              <MilestoneListWithPatch
                requestStatus={drawRequest?.status}
                responsiveColumns
                withColumnIndication
                tableKey={TableKeyEnum.SUBMISSION}
                withProgress
                headerLeftPart={[
                  {
                    Component: (
                      <Filter
                        filterLabel="Show"
                        onChangeCallback={handleFiltersChange}
                        options={filterOptions}
                        filterValue={filterValue}
                        disabled={isMilestoneMutatingOrFetching}
                      />
                    ),
                  },
                ]}
                headerRightPart={[
                  {
                    Component: (
                      <Stack flexDirection="row">
                        {isReallocationAllowed && (
                          <WithPermission
                            permissionKey={PermissionNamesEnums.DRAWREQUESTS_LINE_ITEM_CREATE}
                          >
                            <Button
                              size="small"
                              variant="new"
                              color="secondary"
                              onClick={controller.addLineList}
                              disabled={!controller.isEditable}
                            >
                              Add line item
                            </Button>
                          </WithPermission>
                        )}
                        {controller.isDrawRequest &&
                          (Boolean(projectData?.retainage_rate) ||
                            !isRestricted(
                              PermissionNamesEnums.DRAWREQUESTS_AUTOFILL_INSPECTOR_ALLOWANCE_REQUESTED,
                              permissions,
                            )) && (
                            <SubmissionAutofill
                              drawRequestId={drawRequest?.id}
                              retainageRate={projectData?.retainage_rate}
                            />
                          )}
                      </Stack>
                    ),
                  },
                ]}
                requestId={drawRequest?.id}
                refetch={controller.refetch}
                initColumns={controller.initColumns}
                milestones={controller.filteredMilestones}
                totals={controller.totals}
                contingencyMode={drawRequest?.contingency_mode}
                openEditMilestoneModal={openEditMilestoneModal}
                source="request__submission__line_items_table"
              />
            </StyledBox>
          )}
        </Stack>
      </Stack>
      <Stack
        direction="row"
        spacing={2}
        sx={{
          position: 'fixed',
          bottom: 0,
          right: 0,
          p: 3,
          width: '100%',
          backgroundColor: colors.background.white,
          alignItems: 'flex-end',
          justifyContent: 'flex-end',
          zIndex: 1100,
        }}
      >
        <LoadingButton
          variant="text"
          loading={isWaiting}
          onClick={run}
          data-cy="close_edit_request_table_button"
        >
          Close editing
        </LoadingButton>
        <ButtonWithTooltip
          tooltipText={disabled ? BLOCKED_POLICY_REASON[sectionKey] : ''}
          onClick={() => {
            run();
            if (checklistItem) {
              handleDone({
                checklistItemId: checklistItem.id,
                shouldComplete: !validatedSection?.[sectionKey]?.isDone,
              });
            }
            onSectionButtonsClick({ sectionKey, key: 'isDone' });
          }}
          disabled={disabled}
          dataTestName={`request__submission__${sectionKey}__mark_as_done__button`}
        >
          Mark as done
        </ButtonWithTooltip>
      </Stack>
      {lineItemModal?.open && (
        <LineItemEdit
          lineItem={lineItemModal?.lineItem}
          open={lineItemModal?.open}
          setLineItemModal={setLineItemModal}
          type={lineItemModal?.type}
          request={drawRequest}
        />
      )}
    </Stack>
  );
};

export default MilestoneListModal;
