import React, { FC, useCallback, useContext } from 'react';
import { Box, Button, Stack } from '@mui/material';
import { GridColumnHeaderTitle } from '@mui/x-data-grid';
import { ColumnsFilterV3, DataTableV3 } from '@components';
import { AuthContext, PermissionsContext } from '@context';
import { useMilestoneList } from './controllerV2';
import { MilestoneListInterface } from './interface';
import { TableKeyEnum } from '@interfaces';
import { GridColDef } from '@mui/x-data-grid-premium';
import { ColumnIcon } from './ColumnIcon';
import { MilestoneListColumnTypeV2 } from './columnsV2/common';
import { checkIsAdmin, getTeamRole } from '@utils';
import { MilestroneListDrawer } from './drawer';

const MilestoneList: FC<MilestoneListInterface> = ({
  tableKey,
  milestones,
  initColumns,
  totals,
  withColumnIndication,
  headerLeftPart,
  headerRightPart,
  patchMilestone,
  refetch,
  lockedColumns,
  deleteMilestone,
  openEditMilestoneModal,
  contingencyMode,
  source,
  updateData,
  withCredit,
  groupByFilter,
  onCreditChangeCompleted,
  commentIconComponent,
}) => {
  const {
    sortedMilestones,
    hiddenColumns,
    isColumnFilterUpdating,
    columns,
    changeFieldVisibility,
    milestoneColumns,
    showCreditButton,
    apiUpdateV2,
    totalCalculation,
    drawRequestErrors,
    isColumnsReady,
  } = useMilestoneList({
    tableKey,
    milestones,
    initColumns,
    patchMilestone,
    withColumnIndication,
    contingencyMode,
    source,
    updateData,
    withCredit,
    groupByFilter,
    totals,
  });
  const { user } = useContext(AuthContext);
  const teamRole = getTeamRole(user);
  const { permissions } = useContext(PermissionsContext);

  const isColumnEditableWithPermission = useCallback(
    (column: MilestoneListColumnTypeV2) => {
      if ('editable' in column) {
        return { editable: column.editable };
      }

      if ('editableByPermissions' in column) {
        if (checkIsAdmin(teamRole)) return { editable: true };
        return { editable: column.editableByPermissions(permissions) };
      }

      return { editable: true };
    },
    [permissions, teamRole],
  );

  const serColumns = React.useMemo<GridColDef[]>(
    () =>
      (columns as unknown as MilestoneListColumnTypeV2[]).map((item) => ({
        ...item,
        ...isColumnEditableWithPermission(item),
        renderHeader: (params) => (
          <Stack alignItems="center" direction="row">
            <ColumnIcon columns={milestoneColumns} name={item.field} source={source} />
            <GridColumnHeaderTitle label={params.colDef?.headerName || ''} columnWidth={0} />
          </Stack>
        ),
      })),
    [columns, isColumnEditableWithPermission],
  );

  return (
    <MilestroneListDrawer
      refetch={refetch}
      deleteMilestone={deleteMilestone}
      openEditMilestoneModal={openEditMilestoneModal}
      commentsPreviewQueryEnabled={!!sortedMilestones.length}
      tableKey={tableKey}
      source={source}
      onCreditChangeCompleted={onCreditChangeCompleted}
    >
      <Stack direction="row" justifyContent="space-between" sx={{ p: 2, ml: '100px' }}>
        {Boolean(headerLeftPart?.length) && (
          <Stack direction="row" alignItems="center">
            {headerLeftPart?.map(({ Component }, index) => (
              <Box key={index.toString()}>{Component}</Box>
            ))}
          </Stack>
        )}
        <Stack direction="row" alignItems="center" sx={{ ml: 'auto' }}>
          {headerRightPart?.map(({ Component }, index) => (
            <Box key={index.toString()}>{Component}</Box>
          ))}
          {showCreditButton && (
            <Button
              size="small"
              onClick={() => {
                /**  updateRightDrawer({
                  milestoneName: 'Credit review',
                  tab: RequestTableTabsEnum.CREDIT_APPROVE,
                })() */
              }}
              data-cy={`${source}__review_credit__button`}
              sx={{ ml: 1 }}
            >
              Review credits
            </Button>
          )}
          {commentIconComponent}
          {!lockedColumns && (
            <ColumnsFilterV3
              hiddenColumns={hiddenColumns}
              columns={columns}
              milestoneColumns={milestoneColumns}
              changeFieldVisibility={changeFieldVisibility}
              isUpdating={isColumnFilterUpdating}
              source={source.toString()}
            />
          )}
          {/**   <MilestoneListOptionsMenu
            menuItems={menuItems}
            totals={totals}
            columns={columns}
            sortedMilestones={sortedMilestones}
            exportToCSV={exportToCSV}
          />*/}
        </Stack>
      </Stack>
      {isColumnsReady ? (
        <DataTableV3
          rowUpdateApi={apiUpdateV2}
          tableKey={TableKeyEnum.DRAW_REQUEST}
          rows={sortedMilestones}
          columns={serColumns}
          totals={totalCalculation}
          errors={drawRequestErrors}
        />
      ) : null}
    </MilestroneListDrawer>
  );
};

export default MilestoneList;
