import { percentFormatter } from '@utils';
import { MilestoneListColumnTypeV2 } from './common';

const previousInspectorAllowanceRate: MilestoneListColumnTypeV2 = {
  field: 'previous_inspector_allowance_rate',
  valueFormatter: (value) => percentFormatter({ value }),
  minWidth: 130,
  maxWidth: 200,
  headerAlign: 'right',
  headerClassName: 'number',
  cellClassName: 'number',
  headerName: 'Previous inspection allowance (%)',
  editable: false,
};

export default previousInspectorAllowanceRate;
