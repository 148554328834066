import { useEffect, useMemo } from 'react';
import { useLineItemsFilter } from '@hooks';
import { LineItemFilterValues } from '@constants';
import { IFilterOption, QueryNamesEnums, TableKeyEnum } from '@interfaces';
import {
  checkIfHorizontalMsExcluded,
  checkMissingValueInOptions,
  getGroupByFilterIds,
  getPHBFilterOptions,
  getPHBGroupByOptions,
} from '@utils/index';
import { useQueries } from 'react-query';
import { getProjectMilestoneTags, getProjectModels } from '@globalService';
import { useParams } from 'react-router-dom';
import find from 'lodash/find';

interface IPHBFilters {
  groupIds: string;
  filterValue: string;
  handleFilterClick: (value: string) => void;
  groupByValue: string;
  handleGroupByClick: (value: string) => void;
  filterOptions: IFilterOption[];
  groupByOptions: { ids: string; filterValue: string }[];
  filterTotalKey: string;
  filterKey: string;
}

const usePHBFilters = ({
  tableKey,
  isNewView,
}: {
  tableKey: TableKeyEnum;
  isNewView?: boolean;
}): IPHBFilters => {
  const { projectId } = useParams();
  const isRequestTable = useMemo(
    () => tableKey === TableKeyEnum.PHB_REQUEST_LINE_ITEMS,
    [tableKey],
  );

  const requestedDataQueries = useQueries([
    {
      queryKey: [QueryNamesEnums.GET_PROJECT_MILESTONE_TAGS, { projectId }],
      queryFn: getProjectMilestoneTags.bind(this, projectId),
    },
    {
      queryKey: [QueryNamesEnums.GET_PROJECT_BUILDING_MODELS, { projectId }],
      queryFn: getProjectModels.bind(this, projectId),
    },
  ]);
  const projectMilestoneTags = requestedDataQueries[0].data?.results;
  const projectModelsData = requestedDataQueries[1].data;
  const isCreatingByModels = useMemo(() => Boolean(projectModelsData?.count), [projectModelsData]);

  const groupByOptions = useMemo(
    () =>
      getPHBGroupByOptions({
        milestoneTags: projectMilestoneTags,
        isCreatingByModels,
      }),
    [isCreatingByModels, projectMilestoneTags],
  );

  const { filterValue, handleFilterClick, setFilterValue } = useLineItemsFilter({
    defaultState: LineItemFilterValues.ALL.filterValue,
    tableKey: TableKeyEnum.PHB_LINE_ITEMS,
  });
  const {
    filterValue: groupByValue,
    handleFilterClick: handleGroupByClick,
    setFilterValue: setGroupByValue,
  } = useLineItemsFilter({
    defaultState: groupByOptions[0]?.filterValue,
    tableKey,
    isGrouping: true,
  });

  const isGroupingExcludeHorizontal = useMemo(
    () => checkIfHorizontalMsExcluded(groupByOptions, groupByValue),
    [groupByOptions, groupByValue],
  );
  const filterOptions = useMemo(
    () => getPHBFilterOptions(isGroupingExcludeHorizontal, isRequestTable, isNewView),
    [isGroupingExcludeHorizontal, isRequestTable],
  );

  useEffect(() => {
    if (
      projectMilestoneTags &&
      projectModelsData &&
      (checkMissingValueInOptions(groupByValue, groupByOptions) || !groupByValue)
    ) {
      setGroupByValue(groupByOptions[0]?.filterValue);
    }
  }, [groupByOptions, groupByValue, projectMilestoneTags, projectModelsData]);

  useEffect(() => {
    if (projectModelsData && checkMissingValueInOptions(filterValue, filterOptions)) {
      setFilterValue(filterOptions[0]?.filterValue);
    }
  }, [filterOptions, filterValue, projectModelsData]);

  useEffect(() => {
    if (isGroupingExcludeHorizontal) {
      setFilterValue(LineItemFilterValues.VERTICAL_COST.filterValue);
    }
  }, [isGroupingExcludeHorizontal]);

  const groupIds = useMemo(
    () => getGroupByFilterIds(groupByOptions, groupByValue),
    [groupByOptions, groupByValue],
  );

  const filterTotalKey = useMemo(
    () => find(filterOptions, { filterValue })?.totalKey || 'all',
    [filterOptions, filterValue],
  );

  const filterKey = useMemo(
    () => find(filterOptions, { filterValue })?.filterKey || 'all',
    [filterOptions, filterValue],
  );

  return {
    groupIds,
    filterValue,
    handleFilterClick,
    groupByValue,
    handleGroupByClick,
    filterOptions,
    groupByOptions,
    filterTotalKey,
    filterKey,
  };
};

export default usePHBFilters;
