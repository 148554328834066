import React, { FC } from 'react';
import { Button, Grid } from '@mui/material';
import * as FiltersItems from './filters';
import FilterButtonV2 from '../FilterButtonV2';
import { ComponentProps } from './interface';
import { useFiltersV2 } from './controller';
import { VerticalMenu } from '@components';
import { PlusIcon } from '@svgAsComponents';

const FiltersV2: FC<ComponentProps> = ({
  filters,
  LeftComponent,
  setFilterStringQuery,
  source,
  padding = 2,
  width = '100%',
  tableKey,
  onPageChange,
  skipToggle,
  onReady,
}) => {
  const {
    handleFiltersChange,
    resetFiltersToDefault,
    hiddenFilters,
    filtersData,
    availableFilters,
    availableFiltersMenuItems,
    needToCollapse,
  } = useFiltersV2({
    filters,
    setFilterStringQuery,
    source,
    tableKey,
    onPageChange,
    onReady,
  });
  return (
    <Grid container justifyContent="space-between" wrap="nowrap" p={padding} sx={{ width }}>
      <Grid item sx={{ flexGrow: 1, alignItems: 'center' }}>
        <Grid container wrap="wrap" spacing={1}>
          {LeftComponent ? (
            <LeftComponent filtersData={filtersData} handleFiltersChange={handleFiltersChange} />
          ) : null}
          {availableFilters.map((item) => {
            if (hiddenFilters.includes(item) && !skipToggle) return null;
            const filterConfig = FiltersItems[item];

            return (
              <Grid key={filterConfig.filterKey} item>
                <FilterButtonV2
                  {...filterConfig}
                  handleFiltersChange={handleFiltersChange}
                  cypressSelector={`${source}__${filterConfig.cypressSelector}`}
                  initValues={filtersData?.[filterConfig.filterKey] || []}
                />
              </Grid>
            );
          })}
          {needToCollapse && !skipToggle && (
            <Grid item>
              <VerticalMenu
                menuItems={availableFiltersMenuItems}
                closeOnItemClick={false}
                anchorOriginHorizontal="left"
              >
                <Button
                  endIcon={<PlusIcon size={14} color="white" />}
                  data-cy={`${source}__filters__more__button`}
                  size="small"
                >
                  More
                </Button>
              </VerticalMenu>
            </Grid>
          )}
          <Grid item>
            <Button
              size="small"
              variant="text"
              onClick={resetFiltersToDefault}
              data-cy={`${source}__filters__reset_filters__button`}
            >
              Reset filters
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FiltersV2;
