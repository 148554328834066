import { MilestoneListColumnTypeV2 } from './common';
import { percentFormatter } from '@utils';

const previousLenderAllowanceRate: MilestoneListColumnTypeV2 = {
  field: 'previous_lender_allowance_rate',
  headerAlign: 'right',
  headerClassName: 'number',
  cellClassName: 'number',
  headerName: 'Approved to date (%)',
  valueFormatter: (value) => percentFormatter({ value }),
  minWidth: 120,
  editable: false,
};

export default previousLenderAllowanceRate;
