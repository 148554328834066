import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import isEmpty from 'lodash/isEmpty';

import { ProjectStatusEnum } from '@interfaces';
import { Popup } from '@components';
import { isActiveProject, isCreatedProject } from '@utils';
import snakeCase from 'lodash/snakeCase';

export interface ComponentProps {
  open: boolean;
  title?: string;
  text?: string;
  options: {
    [key: string]: string;
  };
  initialValue: ProjectStatusEnum;
  confirmCallback: (value) => void;
  closeCallback: () => void;
  inviteBorrowers: boolean;
  setInviteBorrowers: Dispatch<SetStateAction<boolean>>;
  icon?: React.ComponentType<{ size: number; color: string }>;
  source: string;
  confirmButtonLabel?: string;
}
const ModalWithRadioChoice: FC<ComponentProps> = ({
  open,
  title,
  text,
  options,
  initialValue,
  confirmCallback,
  closeCallback,
  inviteBorrowers,
  setInviteBorrowers,
  icon,
  source,
  confirmButtonLabel = 'Confirm',
}) => {
  const [value, setValue] = useState(initialValue);
  const handleChoice = () => {
    confirmCallback(value);
  };

  useEffect(() => {
    // to clear value on modal close
    if (open) setValue(initialValue);
  }, [open]);

  return (
    <Popup open={open} title={title} text={text} maxWidth="xs" icon={icon}>
      <Stack sx={{ width: '100%' }}>
        <Stack justifyContent="flex-start">
          <RadioGroup
            aria-label="inputType"
            name="inputType"
            value={value}
            onChange={(e) => setValue(e.target.value as ProjectStatusEnum)}
            data-cy={`${source}__radio__group`}
          >
            {!isEmpty(options) &&
              Object?.entries(options).map(([value, label]) => (
                <FormControlLabel
                  key={value}
                  value={value}
                  control={<Radio data-cy={`${source}__${snakeCase(value)}__option`} />}
                  label={<Typography variant="body2">{label}</Typography>}
                  sx={{ m: 0 }}
                />
              ))}
          </RadioGroup>
        </Stack>

        {isActiveProject(value) && isCreatedProject(initialValue) && (
          <FormControlLabel
            sx={{ mt: 2 }}
            control={
              <Checkbox
                sx={{ ml: '-2px' }}
                checked={inviteBorrowers}
                onChange={(_e, value) => setInviteBorrowers(value)}
              />
            }
            label={
              <Typography variant="body2">
                Invite not active borrower to use the platform.
              </Typography>
            }
          />
        )}
        <Box sx={{ mt: 7 }} />
        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          <Button data-cy={`${source}__cancel__button`} variant="text" onClick={closeCallback}>
            Cancel
          </Button>
          <Button
            data-cy={`${source}__confirm__button`}
            disabled={value === initialValue}
            onClick={handleChoice}
          >
            {confirmButtonLabel}
          </Button>
        </Stack>
      </Stack>
    </Popup>
  );
};

export default ModalWithRadioChoice;
