import { useCallback, useRef, useState } from 'react';
import { HookInterface } from './interface';
import { useIsMutating, useMutation, useQueryClient } from 'react-query';

import {
  DeleteDrawRequestListItem,
  ErrorDual,
  IMilestone,
  MutationKeyEnum,
  PatchDrawRequestListItemParam,
  PatchInspectionListItemParam,
  PatchListItem,
  QueryNamesEnums,
} from '@interfaces';
import {
  deleteRequestMilestoneItem,
  patchDrawRequestListItem,
  patchInspectionMilestoneToProject,
} from '@globalService';

import { getDrawCellKey, parsePathErrorDual, replaceMilestoneData } from '@utils';
import { useSafeSnackbar } from '@hooks';
import { useParams } from 'react-router-dom';
import { useDebouncedEffect } from '@models';
import { excludeCommentsQueryFields } from '@constants';

export const useMilestoneListPatch = ({ requestId, refetch }: HookInterface) => {
  const pathNeedToUpdates = useRef<Record<string, string>>({});
  const [isRequireUpdate, setIsRequireUpdate] = useState<boolean>(false);
  const { enqueueSnackbar } = useSafeSnackbar();
  const { projectId, inspectionId } = useParams();
  const queryClient = useQueryClient();
  const isMutating = useIsMutating();

  const deleteMilestoneMutation = useMutation<Response, ErrorDual, DeleteDrawRequestListItem>(
    deleteRequestMilestoneItem,
    {
      onSuccess: () => refetch({ isMilestonesUpdate: true, withRequest: true }),
      onError: (error) => {
        enqueueSnackbar(parsePathErrorDual(error), { variant: 'error' });
      },
    },
  );

  const deleteMilestone = useCallback(
    (milestone: string) =>
      deleteMilestoneMutation.mutate({
        project: projectId,
        drawRequest: requestId,
        milestone,
      }),
    [projectId],
  );

  const patchMilestoneRequestMutation = useMutation<
    IMilestone,
    ErrorDual,
    PatchDrawRequestListItemParam
  >(patchDrawRequestListItem, {
    mutationKey: MutationKeyEnum.MILESTONE_PATCH,
    onError: (error, vars) => {
      vars?.onError?.(parsePathErrorDual(error));
    },
    onMutate: (variables) => {
      pathNeedToUpdates.current = {
        ...pathNeedToUpdates.current,
        [getDrawCellKey(variables)]: variables?.timeStmap,
      };
    },
    onSuccess: (data, variables) => {
      if (pathNeedToUpdates.current?.[getDrawCellKey(variables)] === variables?.timeStmap) {
        setIsRequireUpdate(true);
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_DRAW_REQUEST_PHOTOS,
          { projectId, drawRequestId: requestId },
        ]);
        onMilestoneUpdate(data);
      }
    },
  });

  const onMilestoneUpdate = useCallback(
    (data: IMilestone) => {
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES_COLUMNS,
        { projectId, requestId },
      ]);
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_DRAW_REQUEST,
        { projectId, drawRequestId: requestId },
      ]);
      queryClient.setQueriesData<{ results: IMilestone[] }>(
        {
          queryKey: [
            QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES,
            { projectId, drawRequestId: requestId },
          ],
          exact: false,
        },
        (old) =>
          replaceMilestoneData({
            milestones: old,
            milestoneId: data.id,
            json: data,
          }),
      );
    },
    [queryClient, requestId, projectId],
  );

  const patchDrawRequestMilestone = useCallback(
    async (
      milestone: string,
      json: Partial<IMilestone>,
      onError?: (error: string) => void,
      timeStmap?: string,
    ) =>
      await patchMilestoneRequestMutation.mutateAsync({
        project: projectId,
        drawRequest: requestId,
        milestone,
        json,
        onError,
        timeStmap,
      }),
    [requestId, projectId],
  );

  const patchInspectionMilestone = useCallback(
    (milestone: string, json: Partial<IMilestone>) => {
      return patchMilestoneInspectionMutation.mutate({
        projectId,
        inspectionId,
        milestoneId: milestone,
        json,
      });
    },
    [inspectionId, projectId],
  );

  const patchMilestoneInspectionMutation = useMutation<
    Response,
    ErrorDual,
    PatchInspectionListItemParam
  >(patchInspectionMilestoneToProject, {
    mutationKey: MutationKeyEnum.MILESTONE_PATCH,
    onSuccess: async () => {
      setIsRequireUpdate(true);
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_DRAW_REQUEST,
        { projectId, drawRequestId: requestId },
      ]);
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_PROJECT_MILESTONES,
        { projectId, query: excludeCommentsQueryFields },
      ]);
    },
    onError: (error) => {
      enqueueSnackbar(parsePathErrorDual(error), { variant: 'error' });
    },
  });

  const updateListData = useCallback(
    (updater: any) =>
      queryClient.setQueriesData<{ results: IMilestone[] }>(
        {
          queryKey: [
            QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES,
            { projectId, drawRequestId: requestId },
          ],
          exact: false,
        },
        updater,
      ),
    [queryClient, projectId, requestId],
  );

  useDebouncedEffect(
    () => {
      if (isRequireUpdate && !isMutating) {
        refetch?.();
        setIsRequireUpdate(false);
      }
    },
    [isRequireUpdate, isMutating],
    10,
  );

  return {
    updateListData,
    deleteMilestone,
    onMilestoneUpdate,
    resetMutation: (variables: PatchListItem) => {
      pathNeedToUpdates.current = {
        ...pathNeedToUpdates.current,
        [getDrawCellKey(variables)]: '',
      };
    },
    patchMilestoneDefault: (args: PatchListItem) => {
      if (inspectionId) return patchInspectionMilestone(args.milestone, args.json);
      return patchDrawRequestMilestone(
        args.milestone,
        args.json,
        args.onError,
        Date.now().toString(),
      );
    },
  };
};
