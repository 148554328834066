import { SettingsContext, useGetData, useGetListData } from '@context';
import {
  ErrorDual,
  IMilestone,
  MutationKeyEnum,
  PatchDrawRequestListItemParam,
  PatchDrawRequestProdBuildGroupParam,
  QueryNamesEnums,
  TableKeyEnum,
} from '@interfaces';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useIsMutating, useMutation } from 'react-query';
import { patchDrawRequestListItem, patchDrawRequestProdBuildGroup } from '@globalService';
import _ from 'lodash';
import { getMilestoneGroupsTagsIds, usePHBFilters } from '@utils';

export const useApproveCredit = ({ requestId, milestoneId, setOpen, onCreditChangeCompleted }) => {
  const { isPHBProject } = useContext(SettingsContext);
  const { groupIds } = usePHBFilters({
    tableKey: TableKeyEnum.PHB_REQUEST_LINE_ITEMS,
  });

  const [updateData, setUpdateData] = useState<Record<string, number>>({});
  const { projectId } = useParams();
  const project = useGetData({
    type: QueryNamesEnums.GET_PROJECT,
    keys: ['name', 'id'],
    args: { projectId },
  });

  const milestone = useGetData({
    type: isPHBProject
      ? QueryNamesEnums.GET_DRAW_REQUEST_MILESTONE_GROUP
      : QueryNamesEnums.GET_DRAW_REQUEST_MILESTONE,
    keys: [
      'credit_reason',
      'id',
      'name',
      'approved_credit_amount',
      'requested_credit_amount',
      'tags',
    ],
    args: { projectId, drawRequestId: requestId, milestoneId, group_by: groupIds },
    options: {
      skip: true,
    },
  });

  const milestonesForCredit = useGetListData({
    type: QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES,
    keys: [
      'requested_credit_amount',
      'credit_reason',
      'id',
      'name',
      'approved_credit_amount',
      'index',
      'tags',
    ],
    args: { projectId, drawRequestId: requestId, groupBy: groupIds },
    options: {
      skip: milestoneId,
      strictSerialize: (data) =>
        _.sortBy(
          data.filter((item) => item.requested_credit_amount || item.approved_credit_amount),
          ['index'],
        ),
    },
  });

  const milestones = useMemo(
    () => (milestoneId ? (milestone.data ? [milestone.data] : []) : milestonesForCredit.serialized),
    [milestone, milestoneId, milestonesForCredit],
  );

  const updateMilestone = (data: IMilestone) => {
    milestone.manualUpdate({
      approved_credit_amount: data.approved_credit_amount,
      name: data.name,
      id: data.id,
    });
    onCreditChangeCompleted && onCreditChangeCompleted(data);
  };

  const patchMilestoneRequestMutation = useMutation<
    IMilestone,
    ErrorDual,
    PatchDrawRequestListItemParam
  >(patchDrawRequestListItem, {
    mutationKey: MutationKeyEnum.MILESTONE_PATCH,
    onSuccess: (data) => {
      updateMilestone(data);
    },
  });

  const patchDrawRequestProdBuildGroupMutation = useMutation<
    IMilestone,
    ErrorDual,
    PatchDrawRequestProdBuildGroupParam
  >(patchDrawRequestProdBuildGroup, {
    mutationKey: MutationKeyEnum.MILESTONE_PATCH,
    onSuccess: (data) => {
      updateMilestone(data);
    },
  });

  const getMilestoneTagsById = (id) => milestones.find((milestone) => milestone.id === id)?.tags;

  const submit = useCallback(async () => {
    if (Object.keys(updateData).length === 0) return;
    try {
      await Promise.all(
        Object.keys(updateData).map((key) => {
          if (key) {
            if (isPHBProject)
              patchDrawRequestProdBuildGroupMutation.mutate({
                project: projectId,
                drawRequest: requestId,
                group_by: groupIds,
                tags: getMilestoneGroupsTagsIds(milestone.data?.tags || getMilestoneTagsById(key)),
                json: {
                  approved_credit_amount: +updateData[key],
                },
              });
            else
              patchMilestoneRequestMutation.mutate({
                project: projectId,
                drawRequest: requestId,
                milestone: key,
                json: {
                  approved_credit_amount: +updateData[key],
                },
              });
          }
        }),
      );
    } catch (e) {
      console.log(e);
    }
    setOpen(false);
  }, [requestId, projectId, milestoneId, updateData, groupIds, milestone.data]);

  const isMutating = useIsMutating();

  useEffect(() => {
    if (milestoneId) milestone.refetch();
  }, [milestoneId]);

  return {
    name: project.data.name,
    submit,
    isSubmiting: false,
    isLoading: milestonesForCredit.isLoading,
    milestones,
    setUpdateData,
    isMutating,
  };
};
