import React, { FC } from 'react';
import { Stack } from '@mui/material';
import { colors } from '@theme';
import { Filter, MilestoneListWithPatch, ToggleButtonGroup } from '@components';
import { useBudgetTable } from './controller';
import { DeleteBudgetModal } from '../Modals/DeleteBudgetModal/';
import { TableKeyEnum } from '@interfaces';
import UnitsFilter from '../UnitsFilter';

const BudgetTableV2: FC = () => {
  const {
    initColumns,
    onExpandClick,
    filterOptions,
    units,
    filterValue,
    handleFilterClick,
    isLoading,
    menuItems,
    isDeleteBudgetPopupOpen,
    setIsDeleteBudgetPopupOpen,
    deleteBudget,
    isDeleting,
    totals,
    patchMsGroup,
    isLineItemsView,
    filteredMilestones,
    activeView,
    setActiveView,
    viewTypes,
    patchMilestone,
    handleUnitsFilterClick,
    unitsFilterValues,
  } = useBudgetTable();

  return (
    <Stack
      sx={{ backgroundColor: colors.background.white, pt: 1, borderRadius: '4px', flexGrow: 1 }}
    >
      <MilestoneListWithPatch
        withColumnIndication
        initColumns={initColumns}
        milestones={isLineItemsView ? filteredMilestones : units}
        tableKey={TableKeyEnum.PHB_LINE_ITEMS}
        key="listWithEditModels"
        onExpandTable={onExpandClick}
        menuItems={menuItems}
        totals={isLineItemsView ? undefined : totals}
        headerLeftPart={[
          {
            Component: (
              <Stack sx={{ mr: 2 }}>
                <ToggleButtonGroup
                  value={activeView}
                  typesList={viewTypes}
                  handleChange={(_e, value) => value && setActiveView(value)}
                  size="small"
                  source="budget_tab___line_items_table"
                />
              </Stack>
            ),
          },
          ...(isLineItemsView
            ? [
                {
                  Component: (
                    <UnitsFilter
                      unitsFilterValues={unitsFilterValues}
                      handleUnitsFilterClick={handleUnitsFilterClick}
                    />
                  ),
                },
              ]
            : [
                {
                  Component: (
                    <Filter
                      filterLabel="Show"
                      onChangeCallback={handleFilterClick}
                      options={filterOptions}
                      filterValue={filterValue}
                      source="budget_tab___line_items_table__filter"
                    />
                  ),
                },
              ]),
        ]}
        source="project__budget__line_items_table"
        isLoading={isLoading}
        lockSecondColumn
        patchMilestone={isLineItemsView ? patchMilestone : patchMsGroup}
      />
      {isDeleteBudgetPopupOpen && (
        <DeleteBudgetModal
          onClose={() => setIsDeleteBudgetPopupOpen(false)}
          deleteSections={deleteBudget}
          isLoading={isDeleting}
        />
      )}
    </Stack>
  );
};
export default BudgetTableV2;
