import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation, useQueries, useQueryClient } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
  DateValidationEnum,
  ServiceOrderStatusEnum,
  QueryNamesEnums,
  IServiceOrder,
  IServiceOrderPayload,
  PermissionNamesEnums,
  CommonServiceOrderPayload,
} from '@interfaces';
import { useDateFieldModel } from '@models';
import {
  getServiceOrderById,
  patchServiceOrderToProject,
  deleteServiceOrder,
} from '@globalService';
import {
  getDateValidationRule,
  getHookState,
  isServiceProviderAutomatedByCS,
  isRestricted,
  isServiceOrderEditableByPermission,
  getServiceTypeDisplayName,
} from '@utils';
import {
  useCommentsPreview,
  useImagePicker,
  useRightMenu,
  useSafeSnackbar,
  useConfirmationModal,
} from '@hooks';
import { SettingsContext, PermissionsContext, useGetData } from '@context';
import { statusMap } from '@constants';
import { ControllerInterface } from './interface';

export const useServiceOrderEnterResult = (): ControllerInterface => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { serviceOrderId, projectId } = useParams();
  const { settings, isCurrentProjectArchived } = useContext(SettingsContext);
  const { permissions } = useContext(PermissionsContext);
  const imagePicker = useImagePicker();
  const { enqueueSnackbar } = useSafeSnackbar();
  const queryClient = useQueryClient();
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [isApprovalPopupOpen, setIsApprovalPopupOpen] = useState<boolean>(false);
  const imageContainer = useRef();
  const deleteServiceOrderModal = useConfirmationModal();
  const [isUpdatedStatusModalOpen, setIsUpdatedStatusModalOpen] = useState<boolean>(false);

  const serviceCompletedAt = useDateFieldModel({
    initValue: null,
    validationRule: (value) =>
      getDateValidationRule({
        value,
        rule: DateValidationEnum.LESS_OR_EQUAL,
        maxDate: new Date(),
      }),
  });

  const { updateCommentsPreviewInfo } = useCommentsPreview({
    projectId,
    serviceOrderId,
  });
  const { handleRightDrawerOpenerClick, ...rightMenu } = useRightMenu({
    onClose: updateCommentsPreviewInfo,
  });
  const rightDrawerParams = useMemo(
    () => ({
      projectId,
      serviceOrderId,
    }),
    [projectId, serviceOrderId],
  );

  const updateRightDrawer = () => () => {
    handleRightDrawerOpenerClick({ title: 'Comments' });
  };

  const requestedDataQueries = useQueries([
    {
      queryKey: [QueryNamesEnums.GET_PROJECT_SERVICE_ORDER_BY_ID, { projectId, serviceOrderId }],
      queryFn: getServiceOrderById.bind(this, { projectId, serviceOrderId }),
    },
  ]);

  const serviceOrder = useMemo(() => requestedDataQueries[0].data, [requestedDataQueries[0].data]);

  const serviceTypeDisplayName = useMemo(() => {
    if (!serviceOrder) return '';
    return getServiceTypeDisplayName({
      serviceTypesMap: settings?.display?.service_types,
      serviceType: serviceOrder.service_type,
    });
  }, [settings?.display?.service_types, serviceOrder]);

  const project = useGetData({
    type: QueryNamesEnums.GET_PROJECT,
    keys: ['id', 'name', 'comments_preview', 'status', 'status_change_reason'],
    args: { projectId },
  });

  useEffect(() => {
    if (serviceOrder?.completed_at) {
      serviceCompletedAt.setValue(new Date(serviceOrder?.completed_at));
    }
  }, [serviceOrder?.completed_at]);

  const updateQueries = () => {
    const params = { projectId, drawRequestId: serviceOrder?.draw_request?.id };
    queryClient.invalidateQueries(QueryNamesEnums.GET_SERVICE_ORDERS_LIST);
    queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_SERVICE_ORDERS, { projectId }]);
    queryClient.invalidateQueries([QueryNamesEnums.GET_DRAW_REQUEST_SERVICE_ORDERS, params]);
    queryClient.invalidateQueries([
      QueryNamesEnums.GET_PROJECT_SERVICE_ORDER_BY_ID,
      { projectId, serviceOrderId },
    ]);
  };

  const completeServiceOrder = useMutation<
    IServiceOrder,
    Error,
    { projectId: string; serviceOrderId: string; json: IServiceOrderPayload }
  >(patchServiceOrderToProject, {
    onSuccess: () => {
      updateQueries();
      setIsApprovalPopupOpen(true);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const confirmCallBack = useCallback(async () => {
    if (serviceCompletedAt.validate())
      await completeServiceOrder.mutateAsync({
        projectId,
        serviceOrderId,
        json: {
          status: ServiceOrderStatusEnum.COMPLETED,
          completed_at: serviceCompletedAt.value,
        },
      });
  }, [projectId, serviceOrderId, serviceCompletedAt]);

  const updateServiceOrderStatus = useMutation<
    IServiceOrder,
    Error,
    { projectId: string; serviceOrderId: string; json: IServiceOrderPayload }
  >(patchServiceOrderToProject, {
    onSuccess: () => {
      setIsUpdatedStatusModalOpen(false);
      updateQueries();
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });
  const handleUpdateStatus = useCallback(
    async (status) => {
      await updateServiceOrderStatus.mutateAsync({
        projectId,
        serviceOrderId,
        json: {
          status,
          ...(serviceCompletedAt.value && serviceCompletedAt.isValid
            ? { completed_at: serviceCompletedAt.value }
            : {}),
        },
      });
    },
    [projectId, serviceOrderId, serviceCompletedAt],
  );

  const openFile = useCallback(() => {
    if (serviceOrder?.result_documents?.length) {
      imagePicker.open([serviceOrder.result_documents?.[0]]);
    }
  }, [serviceOrder?.result_documents]);

  // update report after uploading
  useEffect(() => {
    imagePicker.close();
    openFile();
  }, [serviceOrder?.result_documents]);

  const isEditable = useMemo(
    () => isServiceOrderEditableByPermission(serviceOrder, permissions),
    [permissions, serviceOrder],
  );

  const isStatusEditable = useMemo(
    () => !isRestricted(PermissionNamesEnums.CUSTOMER_SUCCESS_ACCESS, permissions),
    [permissions],
  );

  const isServiceOrderLinkedToDR = useMemo(
    () => Boolean(serviceOrder?.draw_request?.id),
    [serviceOrder?.draw_request?.id],
  );

  const goBack = () => navigate(state || `/projects/${projectId}/services/all/`);

  const serviceStatusValue = statusMap(serviceOrder?.status, settings.display, 'inspection');
  const statusChipProps = {
    color: serviceStatusValue.color,
    backgroundColor: serviceStatusValue.backgroundColor,
    label: serviceStatusValue.text || serviceOrder?.status,
    dataTestName: 'data-cy="service_results__status_chip"',
  };

  const shouldShowDeleteButton = useMemo(
    () =>
      !isCurrentProjectArchived &&
      ((!isServiceProviderAutomatedByCS(serviceOrder?.service_agency?.service_provider) &&
        !isRestricted(PermissionNamesEnums.INSPECTIONS_EDIT, permissions)) ||
        !isRestricted(PermissionNamesEnums.CUSTOMER_SUCCESS_ACCESS, permissions)),
    [serviceOrder, permissions, project, isCurrentProjectArchived],
  );

  const deleteServiceOrderMutation = useMutation<Response, Error, CommonServiceOrderPayload>(
    deleteServiceOrder,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryNamesEnums.GET_SERVICE_ORDERS_LIST);
        queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_SERVICE_ORDERS, { projectId }]);
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_DRAW_REQUEST_SERVICE_ORDERS,
          { projectId, drawRequestId: serviceOrder?.draw_request?.id },
        ]);
        goBack();
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const handleDeleteServiceOrder = useCallback(async () => {
    await deleteServiceOrderMutation.mutateAsync({
      projectId,
      serviceOrderId,
    });
  }, [projectId, serviceOrderId]);

  return {
    state: getHookState(requestedDataQueries),
    serviceOrder,
    updateQueries,
    project: project?.data,
    showPreview,
    setShowPreview,
    confirmCallBack,
    imagePicker,
    imageContainer,
    openFile,
    isEditable,
    goBack,
    drawRequestNumber: serviceOrder?.draw_request?.number,
    rightDrawerParams,
    rightMenu,
    updateRightDrawer,
    isServiceOrderLinkedToDR,
    activeDocumentId: imagePicker.pdf?.[0]?.id || imagePicker.gallery?.[0]?.id,
    isApprovalPopupOpen,
    setIsApprovalPopupOpen,
    serviceCompletedAt,
    statusChipProps,
    shouldShowDeleteButton,
    deleteServiceOrderModal,
    isCurrentProjectArchived,
    handleDeleteServiceOrder,
    isUpdatedStatusModalOpen,
    setIsUpdatedStatusModalOpen,
    handleUpdateStatus,
    isStatusEditable,
    serviceTypeDisplayName,
  };
};
