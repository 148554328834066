import { MilestoneListColumnTypeV2 } from './common';
import { percentFormatter } from '@utils';

const previousChangesRate: MilestoneListColumnTypeV2 = {
  field: 'previous_changes_rate',
  valueFormatter: (value) => percentFormatter({ value }),
  minWidth: 130,
  maxWidth: 200,
  headerAlign: 'right',
  headerClassName: 'number',
  cellClassName: 'number',
  headerName: 'Total approved changes (%)',
  description: '% of cumulative changes to original scheduled value',
  editable: false,
};

export default previousChangesRate;
