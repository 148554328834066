import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { useMemo, useContext } from 'react';

import { HookState, IProject, QueryNamesEnums, PermissionNamesEnums } from '@interfaces';
import { getProject } from '@globalService';
import { getHookState, isRestricted } from '@utils';
import { SettingsContext, PermissionsContext } from '@context';

interface ControllerInterface {
  state: HookState;
  project: IProject;
  isAllProjectDetailsDisabled: boolean;
  isLimitedProjectDetailsDisabled: boolean;
  hasInspectionsViewPermission: boolean;
  hasInspectionsEditPermission: boolean;
}

export const useProjectSettings = (): ControllerInterface => {
  const { projectId } = useParams();
  const { permissions } = useContext(PermissionsContext);
  const { isCurrentProjectArchived } = useContext(SettingsContext);
  const projectQuery = useQuery<IProject, Error>(
    [QueryNamesEnums.GET_PROJECT, { projectId }],
    getProject.bind(this, projectId),
    { enabled: Boolean(projectId) },
  );

  const hasInspectionsViewPermission = useMemo(
    () => !isRestricted(PermissionNamesEnums.INSPECTIONS_VIEW, permissions),
    [permissions],
  );

  const hasInspectionsEditPermission = useMemo(
    () => !isRestricted(PermissionNamesEnums.INSPECTIONS_EDIT, permissions),
    [permissions],
  );

  // permission projects_details_edit (mostly for lenders)
  const isAllProjectDetailsDisabled = useMemo(
    () =>
      isCurrentProjectArchived ||
      isRestricted(PermissionNamesEnums.PROJECTS_DETAILS_EDIT, permissions),
    [permissions, isCurrentProjectArchived],
  );
  // permission projects_edit (mostly for borrowers)
  const isLimitedProjectDetailsDisabled = useMemo(
    () => isCurrentProjectArchived || isRestricted(PermissionNamesEnums.PROJECTS_EDIT, permissions),
    [permissions, isCurrentProjectArchived],
  );

  return {
    state: getHookState(projectQuery),
    project: projectQuery.data,
    isAllProjectDetailsDisabled,
    isLimitedProjectDetailsDisabled,
    hasInspectionsViewPermission,
    hasInspectionsEditPermission,
  };
};
