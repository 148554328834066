import { useEffect, useContext } from 'react';
import { useLogin } from '@hooks';
import { ITokens } from '@interfaces';

import { OktaContext, SSOContext } from '@context';

export interface LoginPayload {
  email?: string;
  password?: string;
  mfa_code?: string;
  remember_this_device_for_mfa?: boolean;
}
export interface ControllerInterface {
  handleLogin: (params: LoginPayload) => Promise<ITokens>;
  requestMfa: (params: LoginPayload) => Promise<{ is_mfa_enabled: boolean }>;
  isRequestingMfa?: boolean;
}

export const useSimpleLogin = (): ControllerInterface => {
  const { postLoginData, checkMfaStatusData } = useLogin({});
  const authConnector =
    JSON.parse(sessionStorage.getItem('ssoConfig'))?.state ||
    JSON.parse(sessionStorage.getItem('oktaData'))?.state;
  const { clearOktaData } = useContext(OktaContext);
  const { clearSSOData } = useContext(SSOContext);

  useEffect(() => {
    if (authConnector) {
      // to clean up connectors data
      localStorage.clear();
      sessionStorage.clear();
      if (clearOktaData) clearOktaData();
      if (clearSSOData) clearSSOData();
    }
  }, []);

  const handleLogin = async (params) => postLoginData.mutateAsync(params);

  const requestMfa = async (params) => checkMfaStatusData.mutateAsync(params);

  return {
    handleLogin,
    requestMfa,
    isRequestingMfa: checkMfaStatusData.isLoading,
  };
};
