import React, { FC, memo, useContext } from 'react';
import { IconButton } from '@mui/material';

import { CommonRowType, MilestoneListColumnTypeV2 } from './common';

import { FundingIconWithIndicator } from '@svgAsComponents';
import { RequestTableTabsEnum } from '@interfaces';
import { creditAvailableForMilestone } from '@utils';
import { colors } from '@theme';
import { DrawerContext } from '../drawer';

const SubmitCredit: FC<{ row: CommonRowType }> = ({ row }) => {
  const { updateRightDrawer, source } = useContext(DrawerContext);
  const creditAvalible = creditAvailableForMilestone(row);
  return creditAvalible ? (
    <IconButton
      onClick={
        updateRightDrawer
          ? updateRightDrawer({
              milestoneId: row?.id,
              milestoneName: row?.name,
              tab: RequestTableTabsEnum.CREDIT_SUBMIT,
            })
          : row?.updateRightDrawer?.()
      }
      data-cy={`${source}__body__credit__icon__index_${row.project_milestone?.index}`}
    >
      <FundingIconWithIndicator color={colors.icons.green} />
    </IconButton>
  ) : null;
};

const Memoized = memo(SubmitCredit);

const credit: MilestoneListColumnTypeV2 = {
  field: 'credit_reason',
  headerAlign: 'right',
  headerClassName: 'icon',
  cellClassName: 'icon',
  renderHeader: () => null,
  editable: false,
  pinnable: false,
  disableColumnMenu: true,
  sortable: false,
  filterable: false,
  groupable: false,
  aggregable: false,
  hideable: false,
  resizable: false,
  renderCell: (params) => <Memoized row={params.row} />,
  width: 50,
};

export default credit;
