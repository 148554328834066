// maps are used to get a readable values

import {
  DrawRequestStatus,
  LineItemFilterEnum,
  LineItemHighlightVariantEnum,
  PoliciesTypeEnum,
  PolicyItemTypesEnum,
  ProjectStatusEnum,
  ScoreColorEnum,
  ServiceOrderStatusEnum,
  ServiceTypeEnum,
  TableKeyEnum,
  ToolTipLineVariantEnum,
  TransactionTypeEnum,
} from '@interfaces';
import { colors, sequentialMultiHuePalette } from '@theme';

export const STALE_TIME_THRESHOLD = 30 * 60 * 1000;

export const rolesMap = {
  PAYMENT_MANAGER: 'Risk & Compliance',
  REVIEWER: 'Peer Reviewer',
  SUPERUSER: 'Super Admin',
  ADMIN: 'Admin',
  CUSTOMER_SUCCESS: 'Customer Success',
  GENERAL_CONTRACTOR: 'General Contractor',
  OWNER: 'Borrower',
  LENDER: 'Lender',
  SUBCONTRACTOR: 'Subcontractor',
  INSPECTOR: 'Inspector',
  INVESTOR: 'Investor',
};

export const teamRolesMap = {
  GENERAL_CONTRACTOR: 'General Contractor',
  OWNER: 'Borrower',
  LENDER: 'Lender',
  SUBCONTRACTOR: 'Subcontractor',
  ubo: 'UBO',
  foreman: 'Foreman',
  loan_officer: 'Loan Officer',
  viewer: 'Viewer',
  REVIEWER: 'Peer Reviewer',
  PAYMENT_MANAGER: 'Payment Manager',
  CUSTOMER_SUCCESS: 'Customer Success',
  INSPECTOR: 'Inspector',
  INVESTOR: 'Investor',
};

// constants to use via packages
export const USER_ROLES = {
  Regular: 'REGULAR',
  PaymentManager: 'PAYMENT_MANAGER',
  Reviewer: 'REVIEWER',
  CustomerSuccess: 'CUSTOMER_SUCCESS',
  Superuser: 'SUPERUSER',
  Admin: 'ADMIN',
};

export const TEAM_ROLES = {
  Lender: 'LENDER',
  GeneralContractor: 'GENERAL_CONTRACTOR',
  Subcontractor: 'SUBCONTRACTOR',
  Owner: 'OWNER',
  PaymentManager: 'PAYMENT_MANAGER',
  Reviewer: 'REVIEWER',
  CustomerSuccess: 'CUSTOMER_SUCCESS',
  Superuser: 'SUPERUSER',
  Admin: 'ADMIN',
  Inspector: 'INSPECTOR',
  Investor: 'INVESTOR',
};

export const backgroundColorsMap = {
  [LineItemHighlightVariantEnum.ACTIVE]: colors.status.information.lightest,
  [LineItemHighlightVariantEnum.CURRENT]: colors.status.information.lightest,
  [LineItemHighlightVariantEnum.COMPLETED]: colors.neutral.lighter,
  [LineItemHighlightVariantEnum.ERROR]: colors.status.error.lighter,
  [ToolTipLineVariantEnum.INFORMATION]: colors.status.information.lighter,
  [ToolTipLineVariantEnum.WARNING]: colors.status.warning.lighter,
  [ToolTipLineVariantEnum.NOTIFICATION]: colors.status.orange.lighter,
  [ScoreColorEnum.RED]: colors.status.error.lighter,
  [ScoreColorEnum.GREEN]: colors.status.success.lighter,
  [ScoreColorEnum.YELLOW]: colors.status.warning.lighter,
};

export const scoreColorMap = {
  [ScoreColorEnum.RED]: colors.status.error.medium,
  [ScoreColorEnum.GREEN]: colors.status.success.medium,
  [ScoreColorEnum.YELLOW]: colors.status.warning.medium,
  [ScoreColorEnum.NOT_APPLICABLE]: colors.neutral.medium,
};

export const scoreLabelsMap = {
  [ScoreColorEnum.RED]: 'Red',
  [ScoreColorEnum.GREEN]: 'Green',
  [ScoreColorEnum.YELLOW]: 'Yellow',
  [ScoreColorEnum.NOT_APPLICABLE]: 'Not applicable',
};

export const ProjectStatusColorMap = {
  [ProjectStatusEnum.CREATED]: sequentialMultiHuePalette[2],
  [ProjectStatusEnum.ACTIVE]: sequentialMultiHuePalette[0],
  [ProjectStatusEnum.INACTIVE]: sequentialMultiHuePalette[5],
  [ProjectStatusEnum.PAUSED]: sequentialMultiHuePalette[6],
};

export const ProjectStatusMap = {
  [ProjectStatusEnum.CREATED]: 'Inactive',
  [ProjectStatusEnum.ACTIVE]: 'Active',
  [ProjectStatusEnum.INACTIVE_COMPLETE]: 'Archived as complete',
  [ProjectStatusEnum.INACTIVE_INCOMPLETE]: 'Archived as incomplete',
  [ProjectStatusEnum.PAUSED]: 'Paused',
  [ProjectStatusEnum.DISCARDED]: 'Archived as declined',
};

export const ProjectStatsStatusMap = {
  [ProjectStatusEnum.CREATED]: 'Inactive',
  [ProjectStatusEnum.ACTIVE]: 'Active',
  [ProjectStatusEnum.INACTIVE]: 'Archived',
  [ProjectStatusEnum.PAUSED]: 'Paused',
};

export const DrawRequestListStatusMap = {
  [DrawRequestStatus.DRAFT]: 'Draft',
  [DrawRequestStatus.IN_REVIEW]: 'In review',
  [DrawRequestStatus.APPROVED]: 'Approved',
  [DrawRequestStatus.COMPLETED]: 'Completed',
};

export const ProjectScoresDisplayValues = [
  { value: ScoreColorEnum.RED, name: 'Red' },
  { value: ScoreColorEnum.YELLOW, name: 'Yellow' },
  { value: ScoreColorEnum.GREEN, name: 'Green' },
  { value: ScoreColorEnum.NOT_APPLICABLE, name: 'Not applicable' },
];

export const costTypeMap = {
  HARD: 'Hard cost',
  SOFT: 'Soft cost',
  CONTINGENCY: 'Contingency',
};

export const documentsTypeMap = {
  REPORT: 'Inspection report',
};

export const serviceOrderStatusMap = {
  [ServiceOrderStatusEnum.CREATED]: 'Not ordered',
  [ServiceOrderStatusEnum.ORDERED]: 'Ordered',
  [ServiceOrderStatusEnum.COMPLETED]: 'Completed',
  [ServiceOrderStatusEnum.PENDING]: 'Pending',
  [ServiceOrderStatusEnum.ERROR]: 'Request failed',
  [ServiceOrderStatusEnum.CANCELLED]: 'Canceled',
  [ServiceOrderStatusEnum.CANCEL_REQUESTED]: 'Cancellation requested',
};

export const serviceTypeMap = {
  [ServiceTypeEnum.INSPECTION]: 'Inspection',
  [ServiceTypeEnum.TITLE]: 'Title search',
  [ServiceTypeEnum.FEASIBILITY]: 'Feasibility study',
  [ServiceTypeEnum.BUDGET_REVIEW]: 'Budget review',
  [ServiceTypeEnum.BUILDER_INFORMATION_REPORT]: 'Builder verification',
  [ServiceTypeEnum.PERMIT_VERIFICATION_REPORT]: 'Permit validation',
};

export const userFieldsToStore = [
  'id',
  'email_is_verified',
  'team_roles',
  'active_team',
  'status',
  'agreed_to_terms',
  'email',
  'first_name',
  'last_name',
  'last_login',
  'active_team_role',
];

export const TOOLTIP_TEXTS = {
  isCreditIssue: `The total approved draw must exceed the credit amount. Please increase the approved draw request`,
  isCurrentProjectArchived: 'This feature is not available for inactive projects.',
  nonActiveProject: 'This feature is not available for inactive projects.',
  activeRequest: 'You already have the request in progress.',
  inspectionOrdered: 'Inspection is already ordered.',
  inspectionCancelRequested: 'Inspection is requested to cancel. Please wait for the result.',
  invalidRequest: 'Your request is invalid.',
  noSubmitAmounts: 'Please fill at least one field before proceeding.',
  isAllNamesNotFill: 'Please, fill the name or delete the row.',
  noDocumentsSubmission: 'Please upload docs or check the box.',
  paymentErrors: 'Please fix payments issues first',
  tableHasInputError: 'There are line items with incorrect data',
  isRetainageApprove: 'There are line items with incorrect retainage',
  isApproveAlreadyDone:
    'As the most recent approver, you cannot approve this request again at this time.',
  isFundUnavailable:
    'As the most recent approver, you cannot initiate funding for this request. You may need to wait for remaining approvers before proceeding with funding.',
  [TransactionTypeEnum.PLATFORM]: 'In system payment',
  [TransactionTypeEnum.MANUAL]: 'Offline payment (Manual)',
  [TransactionTypeEnum.WEBHOOK]: 'Offline payment (Automatic API)',
  isRequestOnHold: 'Request is on hold',
  zeroApprovedAmount: 'Please enter approval amounts in the Requests tab.',
  zeroApprovedReallocation: 'Please enter reallocation amounts in the Requests tab.',
  noBudget: 'This feature is not available for projects without a budget.',
};

const formatError = (error: string) => {
  return `<span style='color: ${colors.status.information.medium}'><b>${error}</b></span>`;
};

export const REQUEST_SUMMARY_ERROR_TEXTS = {
  APPROVED: `Please continue reallocating in the ${formatError(
    'approved +/-change',
  )} column to avoid changing the construction holdback.`,
  GENERAL: `Totals for this draw is in incorrect. Please check items`,
  CREDIT: `The total ${formatError(
    'approved draw',
  )} must exceed the credit amount. Please increase the approved draw request`,
  REQUESTED: `Please continue reallocating in the ${formatError(
    'requested +/-change',
  )} column to avoid changing the construction holdback.`,
};

// means what key we use from filter value object to send as query params to BE
export const filterKeys = {
  [TableKeyEnum.RISK_RADAR]: {
    status: 'status',
    state: 'state',
    property_existing_type: 'type',
    property_proposed_type: 'type',
    project_type: 'type',
    borrower: 'id',
    lender: 'id',
    customer: 'id',
    investor: 'id',
    overall_score: 'value',
    budget_score: 'value',
    policy_score: 'value',
    schedule_score: 'value',
  },
  [TableKeyEnum.INSPECTION_LIST]: {
    status: 'status',
    inspection_agency: 'display_name',
  },
  [TableKeyEnum.REQUESTS]: {
    project: 'id',
    project_status: 'status',
    status: 'status',
    borrower: 'id',
    lender: 'id',
    coordinator: 'id',
    inspection_status: 'status',
    overall_score: 'value',
    investor: 'id',
    customer: 'id',
    inspection_agency: 'display_name',
    current_reviewer_team: 'id',
    is_on_hold: 'value',
  },
};

export const ACTIVE_COMMENTS_TYPES = [
  'checklistitem',
  'drawrequest',
  'project',
  'changerequest',
  'milestonesubmit',
  'projectmilestone',
  'milestoneinspection',
  'inspection',
  'document',
  'milestonegroup',
  'serviceorder',
];

export const PoliciesTypeMap = {
  [PoliciesTypeEnum.FUTURE_DRAWS]: 'DRAW_REQUEST',
  [PoliciesTypeEnum.FUTURE_CHANGES]: 'CHANGE_REQUEST',
  [PoliciesTypeEnum.FIRST_DRAW]: 'FIRST_DRAW_REQUEST',
  [PoliciesTypeEnum.PROJECT]: 'PROJECT',
};

export const PoliciesTypeLabel = {
  DRAW_REQUEST: 'Future draws',
  CHANGE_REQUEST: 'Future changes',
  FIRST_DRAW_REQUEST: 'First draw',
  PROJECT: 'Project activation',
};

export const AuditLogTags = {
  CHANGE_REQUEST: 'Change',
  DRAW_REQUEST: 'Draw',
  PROJECT: 'Project',
  INSPECTION: 'Inspection',
};

export const REQUEST_EDITABLE_KEYS = ['requested_adjustments', 'requested_amount'];

export const APPROVAL_EDITABLE_KEYS = [
  'approved_adjustments',
  'approved_amount',
  'approved_amount_cumulative',
  'lender_allowance_rate',
];

export const SubmissionSectionKeyMap = {
  [PolicyItemTypesEnum.DRAW_REQUEST_FORM]: 'line_items_table',
  [PolicyItemTypesEnum.INSPECTION_ORDERING]: 'inspection',
  [PolicyItemTypesEnum.COMPLETION_DATE_RENEWAL]: 'completion',
};
export const SubmissionSectionTitle = {
  [PolicyItemTypesEnum.DRAW_REQUEST_FORM]: 'Complete request form',
  [PolicyItemTypesEnum.INSPECTION_ORDERING]: 'Provide information for inspection availability',
  [PolicyItemTypesEnum.COMPLETION_DATE_RENEWAL]: 'Update project completion date',
};

export const LineItemFilterValues = {
  [LineItemFilterEnum.ALL]: {
    filterValue: 'All',
    totalKey: 'all',
    filterKey: 'all',
  },
  [LineItemFilterEnum.ACTIVE]: {
    filterValue: 'Active',
    totalKey: 'active',
    filterKey: 'is_active',
  },
  [LineItemFilterEnum.CURRENT_REQUEST_ONLY]: {
    filterValue: 'Current request only',
    totalKey: 'current',
    filterKey: 'is_in_current_draw',
  },
  [LineItemFilterEnum.NON_ZERO_BUDGET]: {
    filterValue: 'Non-zero budget',
    totalKey: 'budgeted',
    filterKey: 'is_budgeted',
  },
  // for now it's syntetic only FE filter and it's used only on photo tab
  // Later, when we'll implement 'with comments', 'with documents' so on for all places, it should be moved to BE
  [LineItemFilterEnum.HAS_PHOTOS]: {
    filterValue: 'With photos',
    totalKey: 'with_photos',
    filterKey: 'has_photos',
  },
  [LineItemFilterEnum.HORIZONTAL_SOFT_COST]: {
    filterValue: 'Horizontal & Soft costs',
    totalKey: 'is_horizontal',
    filterKey: 'is_horizontal',
  },
  [LineItemFilterEnum.VERTICAL_COST]: {
    filterValue: 'Vertical costs',
    totalKey: 'is_vertical',
    filterKey: 'is_vertical',
  },
};

export const LineItemsTypeFilterValues = {
  [LineItemFilterEnum.HORIZONTAL_SOFT_COST]: {
    filterValue: 'Horizontal & Soft costs',
    totalKey: 'is_horizontal',
    filterKey: 'is_horizontal',
  },
  [LineItemFilterEnum.VERTICAL_COST]: {
    filterValue: 'Vertical costs',
    totalKey: 'is_vertical',
    filterKey: 'is_vertical',
  },
};

export const MS_FILTER_OPTIONS = [
  LineItemFilterValues.ALL,
  LineItemFilterValues.ACTIVE,
  LineItemFilterValues.CURRENT_REQUEST_ONLY,
  LineItemFilterValues.NON_ZERO_BUDGET,
];

export const CSVSampleLinksNames = {
  PRODUCTION__VERTICAL_BUDGET:
    'Vertical budget upload sample - Production build vertical budget .csv',
  PRODUCTION__HORIZONTAL_BUDGET:
    'Horizontal budget upload sample - Production build horizontal budget .csv',
  BUDGET_UPLOAD: '[Master Template] TrustPoint Upload - Budget Upload .csv',
};

export const CSVSampleLinks = {
  BUDGET_UPLOAD: 'https://drive.google.com/uc?export=download&id=1Eow3RB_K4LVrk3M972Pb1TkLe8fQG4lI',
};

export const PolicyItemTypesList = [
  { value: PolicyItemTypesEnum.REQUIRED_DOCUMENT_UPLOAD, name: 'Document upload' },
  { value: PolicyItemTypesEnum.COMMENT, name: 'Text' },
  { value: PolicyItemTypesEnum.ATTESTATION, name: 'Attestation' },
];

export const BLOCKED_POLICY_REASON = {
  [PolicyItemTypesEnum.DRAW_REQUEST_FORM]:
    'Please address the issues in the table before proceeding.',
  [PolicyItemTypesEnum.COMMENT]:
    'Please provide the necessary information or press “Skip” to move forward.',
  [PolicyItemTypesEnum.REQUIRED_DOCUMENT_UPLOAD]:
    'Please upload documents or press “Skip” to move forward.',
  [PolicyItemTypesEnum.INSPECTION_ORDERING]: 'Please enter valid values to move forward.',
  [PolicyItemTypesEnum.COMPLETION_DATE_RENEWAL]:
    'Check to confirm if project completion date is unchanged, or enter revised date',
};

export const REQUEST_TYPE_LABEL = {
  CHANGE_REQUEST: 'Change',
  DRAW_REQUEST: 'Draw',
  HISTORICAL_DRAW_REQUEST: 'Historical draw',
};

export const ENABLED_MIME_TYPES = ['application/pdf', 'image/jpeg', 'image/png'];

export const REFRESH_TOKEN_LIFETIME = 6 * 60 * 60 * 1000;

export const MAX_TEXT_LENGTH = 40;

export const defaultRowsPerPageOptions = {
  documets: [10, 20, 50],
  photos: [12, 24, 60],
};

export { default as statusMap } from './statusMap';
export { fileTypesMap } from './fileTypesMap';
export { COMPANY_DATA } from './companyData';
export { defaultPersonalSetting } from './defaultPersonalSetting';
export * from './tourGuideSteps';
export * from './hints';
export { states } from './states';
export {
  excludeRequestColumns,
  lineItemColumnsMap,
  lineItemColumnsToCSV,
  milestoneTotalsMap,
  requestColumnToCSVMap,
  PHBColumnsForCSV,
} from './columnsToCSV';
export * from './queryFields';
export { REGEX } from './regex';
