import React from 'react';
import { Stack } from '@mui/material';

import { LoadingSkeleton, ServiceMessage, StyledBox, TabSwitcher } from '@components';
import * as Controller from './controller';
import { HookState, PolicyListSourceEnums, PolicyTitleEnum } from '@interfaces';
import PoliciesBox from './PoliciesBox';
import { PoliciesTypeMap, TEAM_ROLES } from '@constants';
import { useLaunchDarklyFlags } from '@context';

const CompanyPolicies = () => {
  const controller = Controller.useCompanyPolicies();
  const {
    switcherTabs,
    activeTab,
    handleTabChange,
    customerPolicyTemplates,
    borrowerPolicyTemplates,
    state,
    templatesList,
    isProjectPolicies,
    editMode,
    handleCloseEdit,
    handleEditMode,
    getLeavePageConfirmModal,
    isLoading,
  } = controller;
  const flags = useLaunchDarklyFlags();

  switch (state) {
    case HookState.FETCHING: {
      return (
        <>
          <StyledBox sx={{ mb: 2 }}>
            <LoadingSkeleton />
          </StyledBox>
          <LoadingSkeleton type="twoBlocks" />
        </>
      );
    }

    case HookState.ERROR: {
      return <ServiceMessage text="policies" />;
    }

    case HookState.EMPTY:
    case HookState.SUCCESS: {
      return (
        <>
          <StyledBox sx={{ mb: 2, p: 0 }}>
            <TabSwitcher
              tabs={switcherTabs}
              onTabChange={handleTabChange}
              activeTab={activeTab}
              source="company__tab_switcher"
            />
          </StyledBox>
          <Stack direction={{ lg: 'row', md: 'column' }} spacing={2} flex={1}>
            {(!isProjectPolicies ||
              flags?.['ENG_8535_project_activation_checklist_for_borrower']) && (
              <PoliciesBox
                policyLists={borrowerPolicyTemplates}
                title={isProjectPolicies ? PolicyTitleEnum.BORROWER : PolicyTitleEnum.SUBMISSION}
                templatesList={templatesList}
                assigneeRole={TEAM_ROLES.Owner}
                type={PoliciesTypeMap[activeTab]}
                source={PolicyListSourceEnums.SUBMISSION}
                editMode={editMode}
                handleCloseEdit={handleCloseEdit}
                handleEditMode={handleEditMode}
                isLoading={isLoading}
              />
            )}
            <PoliciesBox
              policyLists={customerPolicyTemplates}
              title={isProjectPolicies ? PolicyTitleEnum.LENDER : PolicyTitleEnum.APPROVAL}
              templatesList={templatesList}
              assigneeRole={TEAM_ROLES.Lender}
              type={PoliciesTypeMap[activeTab]}
              source={PolicyListSourceEnums.APPROVAL}
              editMode={editMode}
              handleCloseEdit={handleCloseEdit}
              handleEditMode={handleEditMode}
              isLoading={isLoading}
            />
          </Stack>
          {getLeavePageConfirmModal()}
        </>
      );
    }

    default:
      return null;
  }
};

export default CompanyPolicies;
