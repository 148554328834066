import React, { FC } from 'react';
import ReactDOM from 'react-dom';
import { useParams } from 'react-router-dom';
import { Box, Button, Grid, IconButton, Stack, TextField, Typography } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers';

import { colors } from '@theme';
import {
  ButtonWithTooltipOnClick,
  ConfirmationModal,
  InspectionInputsBlock,
  LabelAndValue,
  StyledBox,
  ToolTipLine,
  WysiwygEditor,
  InspectionRelatedDocuments,
  AppraisalDocuments,
} from '@components';
import * as Controller from './controller';
import { getOptionLabel } from '@utils';
import { IInspectionAgency, IInspectionClosePopupEnum, PopupTypeEnum } from '@interfaces';
import { CloseIcon } from '@svgAsComponents';
import snakeCase from 'lodash/snakeCase';

const InspectionDialog: FC<{
  drawRequestId?: string;
  onClose: (showPopup?: IInspectionClosePopupEnum) => void;
}> = ({ drawRequestId, onClose }) => {
  const { projectId } = useParams();
  const {
    inspectionComment,
    handleInspectionRequest,
    cancelInputsValues,
    drNumber,
    project,
    isSubmitting,
    inspectionAgencySelected,
    setInspectionAgencySelected,
    inspectionAgenciesList,
    manualInspectionAgenciesList,
    otherInspectionName,
    setOtherInspection,
    isOtherInspectionSelected,
    isAutomatedInspectionSelected,
    isSubmitDisabled,
    orderInspectionWithoutAppraisalModal,
    handleCheckAppraisalBeforeOrderingInspection,
    borrowerComment,
    inspectionFields,
    tooltipText,
    submitDisabledTooltipText,
    createdInspection,
    isPseudoAutomatedInspectionSelected,
  } = Controller.useInspectionDialog(projectId, onClose, drawRequestId);
  const filter = createFilterOptions<IInspectionAgency>();

  const body = () => (
    <Stack
      sx={{
        backgroundColor: colors.background.gray,
        position: 'fixed',
        top: 0,
        height: '100vh',
        width: '100%',
        left: 0,
        zIndex: 1200,
        overflow: 'scroll',
      }}
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ backgroundColor: colors.background.white, padding: '1.5rem' }}
      >
        <Typography variant="h2">Order inspection</Typography>
        <IconButton
          onClick={() => {
            cancelInputsValues();
            onClose();
          }}
          data-cy="order_inspection_dialog__close__icon"
        >
          <CloseIcon />
        </IconButton>
      </Grid>
      <Stack
        spacing={2}
        sx={{
          flexGrow: 1,
          margin: 'auto',
          width: '50%',
          minWidth: '350px',
          p: 3,
        }}
      >
        <StyledBox>
          <Stack spacing={3}>
            <Typography variant="h3">Onsite contact information</Typography>
            <Stack spacing={1}>
              {drNumber && <LabelAndValue label="Draw request" text={`#${drNumber}`} />}
              <LabelAndValue label="Address" text={project?.address?.address_1 || '-'} />
            </Stack>
          </Stack>
        </StyledBox>

        <StyledBox>
          <Typography variant="h3">Inspection company</Typography>
          {inspectionAgencySelected?.display_name ? (
            <Stack
              direction="row"
              sx={{ mt: '24px' }}
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack sx={{ width: isOtherInspectionSelected ? '48%' : '100%' }}>
                <Autocomplete
                  disableClearable
                  disablePortal
                  options={inspectionAgenciesList}
                  value={inspectionAgencySelected}
                  getOptionLabel={getOptionLabel((option) => `${option.display_name}`)}
                  onChange={(e, value) => {
                    setInspectionAgencySelected(value as IInspectionAgency);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Inspection company"
                      InputProps={{ ...params.InputProps }}
                      inputProps={{
                        ...params.inputProps,
                        'data-cy': 'order_inspection_dialog__inspection_agency_type__input',
                      }}
                    />
                  )}
                />
              </Stack>
              {isOtherInspectionSelected && (
                <Stack sx={{ width: '48%' }}>
                  <Autocomplete
                    disablePortal
                    value={otherInspectionName}
                    onChange={(event, newValue) => {
                      if (typeof newValue === 'string') {
                        setOtherInspection({ display_name: newValue });
                      } else if (newValue?.inputValue) {
                        // Create a new value from the user input
                        setOtherInspection({ display_name: newValue.inputValue });
                      } else {
                        setOtherInspection(newValue);
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);

                      const { inputValue } = params;
                      // Suggest the creation of a new value
                      const isExisting = options.some(
                        (option) => inputValue === option.display_name,
                      );
                      if (inputValue !== '' && !isExisting) {
                        filtered.push({
                          inputValue,
                          display_name: `Add "${inputValue}"`,
                        });
                      }

                      return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Agency name"
                        InputProps={{ ...params.InputProps }}
                        inputProps={{
                          ...params.inputProps,
                          'data-cy':
                            'order_inspection_dialog__manual_inspection_agency_name__input',
                        }}
                      />
                    )}
                    options={manualInspectionAgenciesList}
                    freeSolo
                    renderOption={(props, option) => {
                      const optionLabel = typeof option === 'string' ? option : option.display_name;
                      return (
                        <li {...props} data-cy={`dropdown_option_${snakeCase(optionLabel)}`}>
                          {optionLabel}
                        </li>
                      );
                    }}
                    getOptionLabel={(option) => {
                      // Value selected with enter, right from the input
                      if (typeof option === 'string') {
                        return option;
                      }
                      return option.display_name;
                    }}
                  />
                </Stack>
              )}
            </Stack>
          ) : (
            <LabelAndValue label="Agency" text={inspectionAgencySelected?.display_name || '-'} />
          )}
        </StyledBox>

        <StyledBox>
          {tooltipText && (
            <Box ml={-2}>
              <ToolTipLine typographyVariant="body2" text={tooltipText} />
            </Box>
          )}

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box sx={{ mt: 2 }}>
              <InspectionInputsBlock
                source="order_inspection_dialog"
                inputWidth={6}
                showRequestedDate
                {...inspectionFields}
              />
            </Box>

            <Stack sx={{ mt: 2 }}>
              <Typography variant="label">Comment from borrower (Internal)</Typography>
              <Typography
                variant="body2"
                dangerouslySetInnerHTML={{ __html: borrowerComment || '-' }}
              />
            </Stack>

            <Stack>
              <Typography variant="label">
                Comment (Feel free to provide additional info, i.e. inspect ADU, etc.)
              </Typography>

              <WysiwygEditor
                editField={inspectionComment}
                source="order_inspection_dialog__comment"
              />
            </Stack>
          </LocalizationProvider>
        </StyledBox>

        {(isAutomatedInspectionSelected || isPseudoAutomatedInspectionSelected) && (
          <AppraisalDocuments serviceOrderId={drawRequestId} />
        )}
        {(isAutomatedInspectionSelected || isPseudoAutomatedInspectionSelected) && (
          <InspectionRelatedDocuments
            drawRequestId={drawRequestId}
            inspectionId={createdInspection?.id}
            sx={{ width: '100%' }}
          />
        )}

        {(isAutomatedInspectionSelected || isPseudoAutomatedInspectionSelected) && (
          <StyledBox>
            <Typography variant="body1">
              <span style={{ color: colors.status.information.medium, fontWeight: 700 }}>
                Disclaimer.{' '}
              </span>
              By clicking “Submit” I understand and agree that I am ordering a product or service
              from a third-party provider, not TrustPoint. This order is subject to the provider’s,
              and not TrustPoint’s, terms and conditions. I understand that I can review the
              provider’s terms and conditions on the provider’s website, and I agree to abide by and
              be bound by the provider’s terms and conditions.
            </Typography>
          </StyledBox>
        )}
      </Stack>
      <Grid
        container
        justifyContent="flex-end"
        alignItems="center"
        sx={{ backgroundColor: colors.background.white, padding: '1.5rem' }}
      >
        <Button
          variant="new"
          sx={{ mr: '0.5rem' }}
          onClick={() => {
            cancelInputsValues();
            onClose();
          }}
          data-cy="order_inspection_dialog__cancel__button"
        >
          Cancel
        </Button>
        <ButtonWithTooltipOnClick
          onClick={handleCheckAppraisalBeforeOrderingInspection}
          loading={isSubmitting}
          disabled={isSubmitting}
          conditionallyDisabled={isSubmitDisabled}
          tooltipText={submitDisabledTooltipText}
          dataTestName="order_inspection_dialog__submit__button"
        >
          {isSubmitting ? 'Submitting...' : 'Submit'}
        </ButtonWithTooltipOnClick>
      </Grid>

      <ConfirmationModal
        open={orderInspectionWithoutAppraisalModal.isConfirmModalOpened}
        title="Warning"
        text={
          'Proceeding without attached appraisal may extend the inspection resolution time. \n\n Continue anyway?'
        }
        onClose={orderInspectionWithoutAppraisalModal.closeConfirmModal}
        confirmCallback={handleInspectionRequest}
        isLoading={isSubmitting}
        type={PopupTypeEnum.ERROR}
      />
    </Stack>
  );
  return <>{ReactDOM.createPortal(body(), document.body)}</>;
};

export default InspectionDialog;
