import React from 'react';
import { calculateRequestedAdjustment, currencyFormatter, isRestricted } from '@utils';
import {
  ActionButton,
  CellRenderWithError,
  CommonRowType,
  gridCellSet,
  MilestoneListColumnTypeV2,
} from './common';
import { PermissionNamesEnums } from '@interfaces';
import { CurrencyInput } from '@components';
import { GridEditInputCell } from '@mui/x-data-grid-premium';

const editableByMilestone = (row: CommonRowType) => row.localIsUserCreator && row.isEditableV2;
const actionButtons: ActionButton[] = [
  {
    variant: 'text',
    size: 'small',
    onClickCustom: async (api, row) => {
      const requested_revised_estimate = row.revised_estimate.toString();
      gridCellSet(api, row, 'requested_revised_estimate', requested_revised_estimate);
    },
    label: 'Clear input',
  },
  {
    color: 'secondary',
    variant: 'new',
    size: 'small',
    onClickCustom(api, row) {
      const requested_adjustments = calculateRequestedAdjustment(row, row.requested_amount);
      gridCellSet(api, row, 'requested_adjustments', requested_adjustments);
    },
    label: 'Increase change',
  },
  {
    color: 'secondary',
    variant: 'new',
    size: 'small',
    onClickCustom(api, row) {
      const requested_amount = row.balance_to_finish;
      gridCellSet(api, row, 'requested_amount', requested_amount);
    },
    label: 'Adjust draw',
  },
];

const requestedRevisedEstimate: MilestoneListColumnTypeV2 = {
  field: 'requested_revised_estimate',
  headerAlign: 'right',
  headerClassName: 'number',
  cellClassName: 'number',
  headerName: 'Requested scheduled value ($)',
  description: 'Proposed total value for a line item',
  renderCell: (params) => <CellRenderWithError params={params} actionButtons={actionButtons} />,
  renderEditCell: (props) => <GridEditInputCell {...props} inputComponent={CurrencyInput} />,
  editableByMilestone,
  valueFormatter: (value) => currencyFormatter(value, '-'),
  editableByPermissions: (permissions) =>
    !isRestricted(PermissionNamesEnums.DRAWREQUESTS_CREATE, permissions) ||
    !isRestricted(PermissionNamesEnums.DRAWREQUESTS_MANUAL_CREATE, permissions),
  minWidth: 120,
};

export default requestedRevisedEstimate;
