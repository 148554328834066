import { FilterObject } from '../interface';
import { ProjectScoresDisplayValues } from '@constants';
import { PermissionNamesEnums, ScoreValuesEnum } from '@interfaces';

export const borrowerScoreFilter: FilterObject = {
  title: 'Borrower Risk',
  filterKey: ScoreValuesEnum.BORROWER_SCORE,
  permissionKey: PermissionNamesEnums.SCORES_VIEW,
  getStaticValues: () =>
    ProjectScoresDisplayValues.map((item) => ({
      label: item.name,
      value: item.value,
    })),
  cypressSelector: 'filters__borrower_score',
  needToUpdateUrl: true,
  needToUpdateSetting: true,
};
