import { SettingsContext, useGetData } from '@context';
import { patchDrawRequestListItem, patchDrawRequestProdBuildGroup } from '@globalService';
import {
  CreditReasonEnum,
  ErrorDual,
  IMilestone,
  MutationKeyEnum,
  PatchDrawRequestListItemParam,
  PatchDrawRequestProdBuildGroupParam,
  QueryNamesEnums,
  TableKeyEnum,
} from '@interfaces';
import { useStringFieldModel } from '@models';
import { emptyHTML, getMilestoneGroupsTagsIds, usePHBFilters } from '@utils';
import { useCallback, useContext, useState } from 'react';
import { useIsMutating, useMutation } from 'react-query';
import { useParams } from 'react-router';

export const useSubmitCredit = ({ requestId, milestoneId, setOpen, onCreditChangeCompleted }) => {
  const { projectId } = useParams();
  const { isPHBProject } = useContext(SettingsContext);
  const { groupIds } = usePHBFilters({
    tableKey: TableKeyEnum.PHB_REQUEST_LINE_ITEMS,
  });
  const [reason, setReason] = useState<string[]>([CreditReasonEnum.REBATE_OR_CREDIT]);

  const commentField = useStringFieldModel({
    initValue: '',
    validationRule: (value) => Boolean(value.trim()) && !emptyHTML(value),
  });
  const project = useGetData({
    type: QueryNamesEnums.GET_PROJECT,
    keys: ['name'],
    args: { projectId },
  });

  const milestone = useGetData({
    type: isPHBProject
      ? QueryNamesEnums.GET_DRAW_REQUEST_MILESTONE_GROUP
      : QueryNamesEnums.GET_DRAW_REQUEST_MILESTONE,
    keys: isPHBProject ? ['requested_credit_amount', 'tags'] : ['requested_credit_amount'],
    args: { projectId, drawRequestId: requestId, milestoneId, group_by: groupIds },
  });

  const updateQueries = (data: IMilestone) => {
    milestone.manualUpdate({ requested_credit_amount: data.requested_credit_amount });
    onCreditChangeCompleted && onCreditChangeCompleted(data);
    setOpen(false);
  };

  const patchMilestoneRequestMutation = useMutation<
    IMilestone,
    ErrorDual,
    PatchDrawRequestListItemParam
  >(patchDrawRequestListItem, {
    mutationKey: MutationKeyEnum.MILESTONE_PATCH,
    onSuccess: (data) => {
      updateQueries(data);
    },
  });

  const patchDrawRequestProdBuildGroupMutation = useMutation<
    IMilestone,
    ErrorDual,
    PatchDrawRequestProdBuildGroupParam
  >(patchDrawRequestProdBuildGroup, {
    mutationKey: MutationKeyEnum.MILESTONE_PATCH,
    onSuccess: (data) => {
      updateQueries(data);
    },
  });

  const requested_credit_amount = useStringFieldModel({
    initValue: milestone.data?.requested_credit_amount?.toString?.(),
    withProgressCheck: true,
  });

  const resetState = useCallback(() => {
    commentField.setValue('');
    requested_credit_amount.setAsFloat(+milestone.data?.requested_credit_amount);
  }, [milestone]);

  const submit = useCallback(async () => {
    if (isPHBProject) {
      return patchDrawRequestProdBuildGroupMutation.mutateAsync({
        project: projectId,
        drawRequest: requestId,
        group_by: groupIds,
        tags: getMilestoneGroupsTagsIds(milestone.data?.tags),
        json: {
          requested_credit_amount: +requested_credit_amount.value,
          credit_reason: reason.includes(CreditReasonEnum.OTHER) ? commentField.value : reason[0],
        },
      });
    } else
      return patchMilestoneRequestMutation.mutateAsync({
        project: projectId,
        drawRequest: requestId,
        milestone: milestoneId,
        json: {
          requested_credit_amount: +requested_credit_amount.value,
          credit_reason: reason.includes(CreditReasonEnum.OTHER) ? commentField.value : reason[0],
        },
      });
  }, [requestId, projectId, milestoneId, requested_credit_amount, reason, commentField, groupIds]);

  const isMutating = useIsMutating();
  return {
    name: project.data.name,
    reason,
    commentField,
    setReason,
    requested_credit_amount,
    resetState,
    submit,
    isSubmiting: isMutating,
  };
};
