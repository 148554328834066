import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { format } from 'date-fns';

import { ButtonHandlerProps, ConfirmationModalTypeEnums, IButtonController } from '../interfaces';
import { IChecklistMetadata, PolicyItemTypesEnum, QueryNamesEnums } from '@interfaces';
import { SubmissionSectionKeyMap } from '@constants';
import {
  checkIsResubmit,
  getDefaultChecklistItem,
  hasConfirmedCompletionDate,
  isValidDate,
} from '@utils';
import { useLaunchDarklyFlags } from '@context';

export const useButtonHandler = ({
  completionDate,
  completionDateComment,
  completionDateReasons,
  isCompletionDateConfirmed,
  inspectionComment,
  validatedSection,
  checklistItems,
  handleDone,
  goBack,
  inspectionFields,
  isAnyItemChanged,
  setCancelModalShow,
}: ButtonHandlerProps): IButtonController => {
  const { projectId, requestId, action } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [isConfirmationStep, setConfirmationStep] = useState(false);
  const isAdditionalRequirementResubmit = useMemo(() => checkIsResubmit(action), [action]);
  const flags = useLaunchDarklyFlags();

  const saveDraftValues = () => {
    if (
      completionDate.value &&
      (hasConfirmedCompletionDate(isCompletionDateConfirmed) || completionDate.validate())
    ) {
      sectionDone(
        PolicyItemTypesEnum.COMPLETION_DATE_RENEWAL,
        {
          date: format(completionDate.value, 'yyyy-MM-dd'),
          comment: completionDateComment.value,
          reasons: completionDateReasons,
          date_confirmed: isCompletionDateConfirmed,
        },
        validatedSection?.[SubmissionSectionKeyMap[PolicyItemTypesEnum.COMPLETION_DATE_RENEWAL]]
          ?.isDone,
      );
    }

    if (
      additionalContactPhone.validate() &&
      (!inspectionRequestedAt.value || isValidDate(inspectionRequestedAt.value)?.value)
    ) {
      sectionDone(
        PolicyItemTypesEnum.INSPECTION_ORDERING,
        getInspectionMetaData(),
        validatedSection?.[SubmissionSectionKeyMap[PolicyItemTypesEnum.INSPECTION_ORDERING]]
          ?.isDone,
      );
    }
  };

  const onStepClick = () => {
    setConfirmationStep((isConfirmationStep) => !isConfirmationStep);
    saveDraftValues();
  };

  const onResubmit = () => {
    queryClient.invalidateQueries([
      QueryNamesEnums.GET_DRAW_REQUEST,
      { projectId, drawRequestId: requestId },
    ]);
    navigate(-1);
  };

  const sectionDone = useCallback(
    (sectionKey: PolicyItemTypesEnum, metadata: IChecklistMetadata, shouldComplete: boolean) => {
      const checklistItem = getDefaultChecklistItem(checklistItems, sectionKey);

      if (!checklistItem) return;
      handleDone({
        checklistItemId: checklistItem.id,
        shouldComplete,
        metadata,
      });
    },
    [checklistItems, handleDone],
  );

  const completionDateDone = (shouldComplete?: boolean) => {
    if (
      completionDate.value &&
      (hasConfirmedCompletionDate(isCompletionDateConfirmed) || completionDate.validate())
    ) {
      const isDone =
        shouldComplete ||
        !validatedSection?.[SubmissionSectionKeyMap[PolicyItemTypesEnum.COMPLETION_DATE_RENEWAL]]
          ?.isDone;
      sectionDone(
        PolicyItemTypesEnum.COMPLETION_DATE_RENEWAL,
        {
          date: format(completionDate.value, 'yyyy-MM-dd'),
          comment: completionDateComment.value,
          reasons: completionDateReasons,
          date_confirmed: isCompletionDateConfirmed,
        },
        isDone,
      );
    }
  };

  const {
    additionalContactName,
    additionalContactPhone,
    accessCode,
    primaryContactUserNotRequired,
    inspectionRequestedAt,
  } = inspectionFields;

  const getInspectionMetaData = () => ({
    comment: inspectionComment.value,
    ...(inspectionRequestedAt.value &&
      inspectionRequestedAt.validate() && {
        date: format(inspectionRequestedAt.value, 'yyyy-MM-dd'),
      }),
    inspection_additional_contact_name: additionalContactName.value,
    ...(additionalContactPhone?.value &&
      additionalContactPhone?.validate() && {
        inspection_additional_contact_phone: additionalContactPhone?.valueToSave,
      }),
    inspection_entry_access_code: accessCode.value,
    inspection_primary_contact_user_id: primaryContactUserNotRequired.value?.id,
  });

  const inspectionDone = () => {
    sectionDone(
      PolicyItemTypesEnum.INSPECTION_ORDERING,
      getInspectionMetaData(),
      !validatedSection?.[SubmissionSectionKeyMap[PolicyItemTypesEnum.INSPECTION_ORDERING]]?.isDone,
    );
  };

  const onSaveAsDraft = () => {
    if (!isAnyItemChanged && flags?.['eng-8589-auto-delete-empty-request']) {
      setCancelModalShow(ConfirmationModalTypeEnums.AUTO_DELETE);
      return;
    }
    if (!isAdditionalRequirementResubmit) {
      saveDraftValues();
    }
    goBack();
  };

  return {
    onStepClick,
    onResubmit,
    isConfirmationStep: isConfirmationStep,
    showSubmit: isConfirmationStep,
    completionDateDone,
    inspectionDone,
    onSaveAsDraft,
  };
};
