import { useContext, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { PermissionsContext } from '@context';
import { rolesMap } from '@constants';
import { HookState, IProject, ITeam, PermissionNamesEnums, QueryNamesEnums } from '@interfaces';
import { getHookState, isCreatedProject, isRestricted } from '@utils';
import { getProject, getProjectCompanies } from '@globalService';
import { find } from 'lodash';

const requiredKeys = ['name', 'coordinator', 'status'] as const;
type ProjectData = Pick<IProject, (typeof requiredKeys)[number]>;
export interface ControllerInterface {
  state: HookState;
  projectQueryState: HookState;
  companies: ITeam[];
  goBack: () => void;
  goToAddBorrower: () => void;
  project: ProjectData;
  columns: string[];
  borrowerCompany: ITeam;
  isProjectStatusCreated: boolean;
  isAddBorrowerCompanyModalOpened: boolean;
  setAddBorrowerCompanyModalOpened: (value: boolean) => void;
}

export const useProjectPeople = (): ControllerInterface => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const { permissions } = useContext(PermissionsContext);
  const [isAddBorrowerCompanyModalOpened, setAddBorrowerCompanyModalOpened] = useState(false);

  const projectQuery = useQuery<ProjectData, Error>(
    [QueryNamesEnums.GET_PROJECT, { projectId }],
    getProject.bind(this, projectId),
  );

  const projectCompaniesData = useQuery<ITeam[], Error>(
    [QueryNamesEnums.GET_PROJECT_COMPANIES, { projectId }],
    getProjectCompanies.bind(this, projectId),
  );

  const columns = useMemo(
    () => [
      'name',
      'email',
      'phone',
      'teams',
      'lastOnline',
      ...(!isRestricted(PermissionNamesEnums.DRAWREQUESTS_COORDINATOR_EDIT, permissions)
        ? ['coordinator']
        : []),
      'status',
      ...(!isRestricted(PermissionNamesEnums.PROJECT_ONBOARDING, permissions)
        ? ['optionsMenu']
        : []),
    ],
    [permissions],
  );

  const borrowerCompany = useMemo(() => {
    if (projectCompaniesData.data?.length)
      return find(projectCompaniesData.data, { role: rolesMap.OWNER });
    return null;
  }, [projectCompaniesData.data]);

  const isProjectStatusCreated = useMemo(
    () => isCreatedProject(projectQuery.data?.status),
    [projectQuery.data?.status],
  );

  return {
    state: getHookState(projectCompaniesData),
    projectQueryState: getHookState(projectQuery),
    companies: projectCompaniesData.data || [],
    goBack: () => navigate(`/projects/${projectId}/overview/`),
    goToAddBorrower: () => navigate(`/projects/${projectId}/add-borrower/`),
    project: projectQuery.data,
    columns,
    borrowerCompany,
    isProjectStatusCreated,
    isAddBorrowerCompanyModalOpened,
    setAddBorrowerCompanyModalOpened,
  };
};
