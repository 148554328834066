import { percentFormatter } from '@utils';
import { MilestoneListColumnTypeV2 } from './common';

const balanceToFinishRate: MilestoneListColumnTypeV2 = {
  field: 'balance_to_finish_rate',
  headerAlign: 'right',
  headerClassName: 'number',
  cellClassName: 'number',
  headerName: 'Balance to finish (%)',
  description: '% remaining of the revised scheduled valuee',
  editable: false,
  valueFormatter: (value) => percentFormatter({ value }),
  minWidth: 120,
};

export default balanceToFinishRate;
