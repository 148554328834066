import { currencyFormatter } from '@utils';
import { MilestoneListColumnTypeV2 } from './common';

const varianceToLenderAllowance: MilestoneListColumnTypeV2 = {
  field: 'variance_to_lender_allowance',
  headerAlign: 'right',
  headerClassName: 'number',
  cellClassName: 'number',
  headerName: 'Variance to lender allowance ($)',
  description: 'Variance between inspection allowance and lender allowance',
  valueFormatter: (value) => currencyFormatter(value, '-'),
  minWidth: 120,
  editable: false,
};

export default varianceToLenderAllowance;
