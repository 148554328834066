import React, { FC } from 'react';
import { Box, Stack, Typography } from '@mui/material';

import { HookState, TableKeyEnum } from '@interfaces';
import {
  FiltersV2,
  ReactTable,
  ServiceMessage,
  StyledBox,
  TablePagination,
  ToolTipLine,
  StyledLink,
} from '@components';
import { NoDataImage } from '@svgAsComponents';
import { useLaunchDarklyFlags } from '@context';
import { colors } from '@theme';
import * as Controller from './controller';

const InspectionTab = () => {
  const controller = Controller.useInspectionsList();
  const { state } = controller;

  switch (state) {
    case HookState.ERROR: {
      return (
        <StyledBox>
          <ServiceMessage text="inspections" />
        </StyledBox>
      );
    }

    case HookState.SUCCESS:
    case HookState.FETCHING: {
      return <InspectionsTable controller={controller} />;
    }

    default:
      return null;
  }
};

export default InspectionTab;

const InspectionsTable: FC<{
  controller: Controller.ControllerInterface;
}> = ({ controller }) => {
  const {
    rows,
    columns,
    page,
    rowsPerPage,
    rowsPerPageOptions,
    onPageChange,
    onRowsPerPageChange,
    itemsCount,
    handleSortClick,
    sortValue,
    isFetching,
    hiddenColumns,
    changeFieldVisibility,
    isColumnFilterUpdating,
    handleRowClick,
    setFilterStringQuery,
  } = controller;
  const flags = useLaunchDarklyFlags();
  return (
    <StyledBox sx={{ p: 0, flexGrow: 1 }}>
      {flags?.['ENG_5946_services'] && (
        <Box mb={2}>
          <ToolTipLine typographyVariant="body1" fullWidth>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              sx={{ minHeight: '56px', color: colors.status.information.medium }}
            >
              <Typography variant="body2" sx={{ color: colors.status.information.medium }} mr={0.5}>
                <b>Attention: Inspections</b> are now part of the
              </Typography>

              <StyledLink
                data-cy="inspection_deprecation_warning__link_to_all_services"
                to="/services"
              >
                Services
              </StyledLink>
              <Typography variant="body2" sx={{ color: colors.status.information.medium }}>
                . This tab will be deprecated soon.
              </Typography>
            </Stack>
          </ToolTipLine>
        </Box>
      )}
      <Box sx={{ flexGrow: 1 }}>
        <FiltersV2
          tableKey={TableKeyEnum.INSPECTION_LIST}
          source="inspections"
          setFilterStringQuery={setFilterStringQuery}
          filters={['inspectionsListStatusFilter', 'customerFilter', 'inspectionsListAgencyFilter']}
          onPageChange={onPageChange}
        />
        {(controller.state === HookState.FETCHING ||
          (controller.state === HookState.SUCCESS && !!rows?.length)) && (
          <Stack style={{ opacity: isFetching ? 0.3 : 1 }}>
            <ReactTable
              data={rows}
              columns={columns}
              footer={false}
              initialSortBy={sortValue}
              handleSortClick={handleSortClick}
              manualSortBy
              hiddenColumns={hiddenColumns}
              isColumnFilterUpdating={isColumnFilterUpdating}
              changeFieldVisibility={changeFieldVisibility}
              onRowClick={handleRowClick}
              source="inspections__list"
              tableKey={TableKeyEnum.INSPECTION_LIST}
              showContextMenu
            />
            <Stack direction="row" justifyContent="flex-end" alignItems="center" sx={{ mt: 1 }}>
              <TablePagination
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={rowsPerPageOptions}
                itemsCount={itemsCount}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
              />
            </Stack>
          </Stack>
        )}
        {controller.state === HookState.SUCCESS && rows?.length === 0 && (
          <Stack justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
            <NoDataImage size={200} />
            <Typography sx={{ mt: 4, textAlign: 'center' }} variant="body2SemiBold">
              We know you’re curious to see how the projects are going. <br />
              You’ll see inspection information here after an inspection is ordered.
            </Typography>
          </Stack>
        )}
      </Box>
    </StyledBox>
  );
};
