import React from 'react';
import { CellRenderWithError, MilestoneListColumnTypeV2 } from './common';
import { currencyFormatter } from '@utils';
import { CurrencyInput } from '@components';
import { GridEditInputCell } from '@mui/x-data-grid';

const retainageReleaseRequested: MilestoneListColumnTypeV2 = {
  field: 'retainage_release_requested',
  headerAlign: 'right',
  headerClassName: 'number',
  cellClassName: 'number',
  headerName: 'Requested retainage release ($)',
  description: 'Requested retainage release ($)',
  valueFormatter: (value) => currencyFormatter(value, '-'),
  renderCell: (params) => <CellRenderWithError params={params} />,
  renderEditCell: (props) => <GridEditInputCell {...props} inputComponent={CurrencyInput} />,
  minWidth: 120,
  editableByMilestone: (row) =>
    row.activeToEdit &&
    row.retainage_rate &&
    row.localIsUserCreator &&
    row.is_retainage_release_requested_editable,
};

export default retainageReleaseRequested;
