import { fonts, sizes, colors } from '@theme';

export default function Button() {
  return {
    MuiButton: {
      variants: [
        {
          props: { size: 'small' },
          style: {
            height: '32px',
            fontSize: sizes.fontSizes.xSmall,
          },
        },
        {
          props: { variant: 'new', color: 'secondary' },
          style: {
            backgroundColor: colors.main.primary.lightest,
            color: colors.main.primary.main,
            '&:hover': {
              backgroundColor: colors.main.primary.lighter,
              color: colors.main.primary.main,
            },
          },
        },
        {
          props: { variant: 'active', color: 'secondary' },
          style: {
            backgroundColor: colors.main.primary.lighter,
            color: colors.text.link,
            '&:hover': {
              backgroundColor: colors.main.primary.light,
              color: colors.main.primary.main,
            },
          },
        },
        {
          props: { variant: 'new', color: 'error' },
          style: {
            backgroundColor: colors.status.error.lighter,
            color: colors.status.error.medium,
            padding: 10,
          },
        },
        {
          props: { variant: 'text', color: 'error' },
          style: {
            backgroundColor: 'transparent',
            color: colors.status.error.medium,
            padding: 10,
          },
        },
      ],
      styleOverrides: {
        root: {
          fontFamily: fonts.primary,
          fontWeight: 600,
          fontSize: sizes.fontSizes.medium,
          lineHeight: '24px',
          textTransform: 'none',
          '&.Mui-disabled': {
            color: colors.neutral.medium,
            backgroundColor: colors.neutral.lightest,
          },
          borderRadius: 2,
          padding: '10px 16px',
          boxShadow: 'none',
          height: '40px',
          letterSpacing: 'normal',
        },
        text: {
          color: colors.main.primary.main,
          '&.Mui-disabled': {
            backgroundColor: colors.background.white,
          },
        },
        containedPrimary: {
          backgroundColor: colors.main.primary.main,
          color: colors.neutral.white,
        },
        containedInfo: {
          backgroundColor: colors.status.information.lighter,
          color: colors.status.information.medium,
          '&:hover': {
            backgroundColor: colors.status.information.lightest,
            color: colors.status.information.medium,
          },
        },
        containedSecondary: {
          backgroundColor: colors.background.lightest,
          color: colors.main.primary.main,
        },
        containedError: {
          backgroundColor: colors.status.error.lighter,
          color: colors.status.error.medium,
          '&.MuiButtonBase-root': {
            backgroundColor: colors.status.error.lighter,
          },
        },
        outlined: {
          boxSizing: 'content-box',
          '&.Mui-disabled': {
            color: 'rgba(0, 0, 0, 0.26)',
          },
        },
        newSecondary: {
          '&.Mui-disabled': {
            color: colors.neutral.medium,
            backgroundColor: colors.neutral.lightest,
          },
        },
        outlinedPrimary: {
          color: colors.secondary,
        },
      },
      defaultProps: {
        variant: 'contained',
        color: 'primary',
      },
    },
  };
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    new: true;
    active: true;
    secondaryNew: true;
  }
}
