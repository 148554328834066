import { MilestoneListColumnTypeV2 } from './common';
import { percentFormatter } from '@utils';

const requestedAmountRelative: MilestoneListColumnTypeV2 = {
  field: 'requested_amount_relative',
  headerAlign: 'right',
  headerClassName: 'number',
  cellClassName: 'number',
  headerName: 'Requested draw (%)',
  description: '% of scheduled value requested by borrower for this draw',
  editable: false,
  editableByMilestone: (row) => row.localIsUserCreator && row.activeToEdit,
  valueFormatter: (value) => percentFormatter({ value }),
  minWidth: 120,
};

export default requestedAmountRelative;
