import { SettingsContext, useGetData } from '@context';
import { QueryNamesEnums, TableKeyEnum } from '@interfaces';
import { useParams } from 'react-router';
import { useContext } from 'react';
import { usePHBFilters } from '@utils';

export const useInfoCredit = ({ requestId, milestoneId }) => {
  const { projectId } = useParams();
  const { isPHBProject } = useContext(SettingsContext);
  const { groupIds } = usePHBFilters({
    tableKey: TableKeyEnum.PHB_REQUEST_LINE_ITEMS,
  });

  const project = useGetData({
    type: QueryNamesEnums.GET_PROJECT,
    keys: ['name'],
    args: { projectId },
  });

  const milestone = useGetData({
    type: isPHBProject
      ? QueryNamesEnums.GET_DRAW_REQUEST_MILESTONE_GROUP
      : QueryNamesEnums.GET_DRAW_REQUEST_MILESTONE,
    keys: ['requested_credit_amount', 'approved_credit_amount', 'credit_reason'],
    args: { projectId, drawRequestId: requestId, milestoneId, group_by: groupIds },
  });

  return {
    name: project.data.name,
    milestone,
  };
};
