import { useCallback, useContext, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import isEmpty from 'lodash/isEmpty';
import { AuthContext, TransloaditContext } from '@context';
import {
  ITransloaditRestrictions,
  ITransloaditSignature,
  QueryNamesEnums,
  TransloaditTemplateEnum,
} from '@interfaces';
import { getTransloaditSiganture } from '@globalService';
import { refetchPooling } from '@utils';

const allowedImagesTypes = ['.png', '.heic', '.jpg', '.jpeg', '.svg', '.svgz'];
const allowedFileTypes = [
  ...allowedImagesTypes,
  '.pdf',
  '.docx',
  '.doc',
  '.xlsx',
  '.xls',
  '.msg',
  '.eml',
];
const maxFileSize = 200000000; //200MB in bytes

export interface IFileUploader {
  isFilesUploaderOpened: boolean;
  isConfirmModalOpened: boolean;
  restrictions: ITransloaditRestrictions;
  confirmUpload: () => void;
  closeConfirmModal: () => void;
  transloaditSignature: ITransloaditSignature;
  filesUploaderClose: () => void;
  uploadMedia: ({ fields, templateType }) => void;
  refetchCallback: (refetch) => void;
}

export const useFilesUploader = (): IFileUploader => {
  const [opened, setOpened] = useState(false);
  const { transloaditTemplates } = useContext(TransloaditContext);
  const { user } = useContext(AuthContext);

  const restrictionsList = useMemo(
    () => ({
      [TransloaditTemplateEnum.PROOFPOINTS]: { allowedFileTypes: allowedImagesTypes, maxFileSize },
      [TransloaditTemplateEnum.INSPECTION_DOCUMENTS]: {
        allowedFileTypes,
        maxFileSize,
      },
      [TransloaditTemplateEnum.DOCUMENTS]: { allowedFileTypes, maxFileSize },
    }),
    [],
  );

  const [isConfirmModalOpened, setConfirmModalOpened] = useState(false);
  const [restrictions, setRestrictions] = useState({});

  const [fields, setFields] = useState({});
  const [templateId, setTemplateId] = useState(null);

  const queryParams = useMemo(() => {
    return new URLSearchParams({ ...fields, user_id: user.id }).toString();
  }, [fields]);

  const transloaditSignatureQuery = useQuery<ITransloaditSignature, Error>(
    [QueryNamesEnums.GET_TRANSLOADIT_SIGNATURE, { templateId, queryParams }],
    getTransloaditSiganture.bind(this, templateId, queryParams),
    { enabled: Boolean(templateId) },
  );

  const handleUploader = ({ fields, templateType, immediately = false }) => {
    if (!transloaditTemplates[templateType] || isEmpty(fields)) return;
    if (immediately) {
      setOpened(true);
    } else {
      setConfirmModalOpened(true);
    }
    setFields(fields);
    setRestrictions(restrictionsList[templateType]);
    setTemplateId(transloaditTemplates[templateType]?.template_id);
  };

  const uploadMedia = ({ fields, templateType }) =>
    handleUploader({ fields, templateType, immediately: true });

  const refetchData = (refetch) => {
    refetchPooling({ refetch });
  };

  return {
    isFilesUploaderOpened: opened,
    isConfirmModalOpened,
    restrictions,
    confirmUpload: useCallback(() => {
      setConfirmModalOpened(false);
      setOpened(true);
    }, []),
    closeConfirmModal: useCallback(() => setConfirmModalOpened(false), []),
    transloaditSignature: transloaditSignatureQuery.data,
    filesUploaderClose: useCallback(() => {
      setTemplateId(null);
      setOpened(false);
      setFields({});
    }, [opened]),
    uploadMedia: useCallback(
      (args) => {
        uploadMedia(args);
      },
      [transloaditTemplates, transloaditSignatureQuery.data],
    ),
    refetchCallback: useCallback((refetch) => refetchData(refetch), []),
  };
};
