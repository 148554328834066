import React from 'react';
import { MilestoneListColumnTypeV2 } from './common';
import { currencyFormatter } from '@utils';
import { CurrencyInput } from '@components';
import { GridEditInputCell } from '@mui/x-data-grid';

const retainageReleaseApproved: MilestoneListColumnTypeV2 = {
  field: 'retainage_release_approved',
  headerAlign: 'right',
  headerClassName: 'number',
  cellClassName: 'number',
  headerName: 'Approved retainage release ($)',
  description: 'Approved retainage release ($)',
  valueFormatter: (value) => currencyFormatter(value, '-'),
  renderEditCell: (props) => <GridEditInputCell {...props} inputComponent={CurrencyInput} />,
  minWidth: 120,
  editableByMilestone: (row) =>
    row.activeToEdit && row.retainage_rate && row.is_retainage_release_approved_editable,
};

export default retainageReleaseApproved;
