import React, { FC } from 'react';
import { Stack, Typography, Box } from '@mui/material';
import { useParams, useLocation, matchPath } from 'react-router-dom';
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridSlots,
} from '@mui/x-data-grid';

import { HookState, MessagePanelTabsEnum, TableKeyEnum } from '@interfaces';
import {
  Gallery,
  ServiceMessage,
  StyledBox,
  PDFViewerNew,
  TableItemRightDrawer,
  RightDrawer,
  ToolTipLine,
  FiltersV2,
  TablePaginationNew,
} from '@components';
import { NoDataImage } from '@svgAsComponents';
import * as Controller from './controller';
import { ControllerInterface } from './interface';

const ServicesTab = () => {
  const { projectId } = useParams();
  const { pathname } = useLocation();
  const match = matchPath('/projects/:projectId/:tab/draw-requests/:drawRequestId', pathname);
  const controller = Controller.useServices(projectId, match?.params?.drawRequestId);

  switch (controller.state) {
    case HookState.FETCHING: {
      return <Table controller={controller} />;
    }
    case HookState.ERROR: {
      return <ServiceMessage text="services" />;
    }

    case HookState.SUCCESS: {
      return <Table controller={controller} />;
    }

    default:
      return null;
  }
};

export default ServicesTab;

const Table: FC<{
  controller: ControllerInterface;
}> = ({ controller }) => {
  const {
    rows,
    columns,
    pdf,
    gallery,
    close,
    rightDrawerParams,
    rightMenu,
    inspectionCadence,
    hiddenColumns,
    setColumnVisibilityModel,
    setFilterStringQuery,
    paginationProps,
    selectedRowId,
    noFiltersApplied,
    handleRowClick,
  } = controller;

  return (
    <>
      {Boolean(inspectionCadence) && (
        <Box mb={2}>
          <ToolTipLine
            text={`Progress inspections are scheduled every ${inspectionCadence} days for this project.`}
            typographyVariant="body1"
            fullWidth
          />
        </Box>
      )}

      <StyledBox sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, px: 0 }}>
        {noFiltersApplied && controller.state === HookState.SUCCESS && rows?.length === 0 ? (
          <Stack justifyContent="center" alignItems="center" sx={{ flexGrow: 1 }}>
            <NoDataImage size={200} />
            <Typography sx={{ mt: 4, textAlign: 'center' }} variant="body2SemiBold">
              We know you’re curious to see how the project is going. <br />
              You’ll see services information here after an some service is ordered.
            </Typography>
          </Stack>
        ) : (
          <DataGrid
            rows={rows}
            columns={columns}
            onRowClick={handleRowClick}
            loading={controller.state === HookState.FETCHING}
            columnVisibilityModel={hiddenColumns.reduce((acc, key) => {
              acc[key] = false;
              return acc;
            }, {})}
            onColumnVisibilityModelChange={setColumnVisibilityModel}
            slots={{
              toolbar: CustomToolbar as GridSlots['toolbar'],
            }}
            slotProps={{
              toolbar: {
                setFilterStringQuery,
                paginationProps,
              },
            }}
            columnBufferPx={200}
            hideFooter={true}
            sx={{
              flexGrow: 1,
              '& .MuiDataGrid-virtualScroller': {
                overflow: 'auto',
              },
              '& .MuiDataGrid-row': {
                cursor: 'pointer',
              },
            }}
            getRowClassName={(params) => (params.id === selectedRowId ? 'selected-table-row' : '')}
          />
        )}
        {gallery && <Gallery startIndex={0} close={close} files={gallery} />}
        {pdf && <PDFViewerNew pdfFile={pdf[0]} close={close} />}
        <RightDrawer {...rightMenu}>
          <TableItemRightDrawer
            {...rightDrawerParams}
            requestDocuments={false}
            messagePanelTab={MessagePanelTabsEnum.SERVICES}
            showMessagePanelTabs={false}
            source="project__services_tab__list__right_drawer"
          />
        </RightDrawer>
      </StyledBox>
    </>
  );
};

const CustomToolbar = ({ setFilterStringQuery, paginationProps }) => {
  const { page, rowsPerPage, onPageChange, onRowsPerPageChange, rowsPerPageOptions, itemsCount } =
    paginationProps;
  return (
    <Stack
      direction="row"
      spacing={1}
      justifyContent="flex-start"
      alignItems="center"
      pb={2}
      px={2}
    >
      <FiltersV2
        skipToggle
        source="project__services_tab__list"
        setFilterStringQuery={setFilterStringQuery}
        filters={['servicesTypeFilter', 'servicesListStatusFilter']}
        padding={0}
        width="unset"
        tableKey={TableKeyEnum.PROJECT_SERVICES}
        onPageChange={onPageChange}
      />

      <TablePaginationNew
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
        itemsCount={itemsCount}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        source="project__services_tab__list"
      />
      <GridToolbarDensitySelector
        slotProps={{
          button: { color: 'secondary', variant: 'new' },
        }}
      />
      <GridToolbarColumnsButton
        slotProps={{
          button: { color: 'secondary', variant: 'new' },
        }}
      />
    </Stack>
  );
};
