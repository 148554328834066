import { Box, Typography } from '@mui/material';
import React, { FC, useMemo } from 'react';
import { tableHeaders as headers } from '@utils';
import { ControllerInterface } from '../controller';
import { ReactTable } from '@components';

export const Documents: FC<{ controller: ControllerInterface }> = ({ controller }) => {
  const { documents } = controller;

  const columns = useMemo(
    () => [
      headers.simpleText({ header: 'Document name', accessor: 'name' }),
      headers.documentType({
        isLoading: false,
        typoprops: {
          variant: 'printBody1',
        },
      }),
      headers.date({
        isLoading: false,
        accessor: 'created_at',
        header: 'Date uploaded',
        typoprops: {
          variant: 'printBody1',
        },
      }),
      headers.simpleText({
        header: 'Uploaded by',
        accessor: 'created_by.full_name',
      }),
    ],
    [],
  );

  if (!documents?.results?.length) return null;

  return (
    <Box sx={{ breakInside: 'avoid' }}>
      <Typography variant="printH2" pr="15px" pl="15px" data-cy="report_documents_section_title">
        Documents
      </Typography>
      {documents.results.length < documents.count && (
        <Typography variant="printBody1">{`${documents.results.length} from ${documents.count} are shown in the table.`}</Typography>
      )}
      <ReactTable
        data={documents.results}
        columns={columns}
        footer={false}
        source="report__documents"
      />
    </Box>
  );
};
