import { useQuery } from 'react-query';
import {
  HookState,
  IDocument,
  IDrawRequest,
  IProofpoint,
  ITablePagination,
  QueryNamesEnums,
} from '@interfaces';
import {
  getDrawRequest,
  getMilestoneDocuments,
  getProjectMilestoneDocuments,
} from '@globalService';
import { getFilteredByRepresentationPhotos, getHookState, isRequestDraftOrInReview } from '@utils';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { onlyProgressPhotos, defaultRowsPerPageOptions } from '@constants';
import { useTablePagination } from '@hooks';

interface ComponentProps {
  state: HookState;
  photos: (IDocument | IProofpoint)[];
  canUploadPhoto: boolean;
  tablePagination: ITablePagination;
  documentsCount: number;
}
export const usePhotoPanel = ({ drawRequestId, milestoneId }): ComponentProps => {
  const { projectId } = useParams();
  const isProjectLevel = useMemo(() => !drawRequestId, [drawRequestId]);
  const tablePagination = useTablePagination({
    initialRowsPerPage: defaultRowsPerPageOptions.documets[0],
    rowsPerPageOptions: defaultRowsPerPageOptions.documets,
  });

  const stringQueryParams = useMemo(() => {
    return `&offset=${
      tablePagination.page * tablePagination.rowsPerPage
    }&limit=${tablePagination.rowsPerPage}`;
  }, [tablePagination.page, tablePagination.rowsPerPage]);

  const drawRequestMilestonePhotosQuery = useQuery<{ results: IDocument[]; count: number }, Error>(
    [
      QueryNamesEnums.GET_DRAW_REQUEST_MILESTONE_DOCS,
      { projectId, drawRequestId, milestoneId, query: onlyProgressPhotos, stringQueryParams },
    ],
    () =>
      getMilestoneDocuments({
        projectId,
        drawRequestId,
        milestoneId,
        query: onlyProgressPhotos,
        stringQueryParams,
      }),
    { enabled: Boolean(projectId && drawRequestId && milestoneId) },
  );

  const projectMilestonePhotosQuery = useQuery<{ results: IDocument[]; count: number }, Error>(
    [
      QueryNamesEnums.GET_PROJECT_MILESTONE_DOCS,
      { projectId, milestoneId, query: onlyProgressPhotos, stringQueryParams },
    ],
    getProjectMilestoneDocuments.bind(this, {
      projectId,
      milestoneId,
      query: onlyProgressPhotos,
      stringQueryParams,
    }),
    { enabled: Boolean(!drawRequestId && projectId && milestoneId) },
  );

  const drawRequestQuery = useQuery<IDrawRequest, Error>(
    [QueryNamesEnums.GET_DRAW_REQUEST, { projectId, drawRequestId }],
    getDrawRequest.bind(this, { projectId, drawRequestId }),
    { enabled: Boolean(drawRequestId) },
  );

  const canUploadPhoto = useMemo(
    () => isRequestDraftOrInReview(drawRequestQuery?.data?.status) || isProjectLevel,
    [drawRequestQuery?.data?.status, isProjectLevel],
  );

  const photos = useMemo(
    () =>
      getFilteredByRepresentationPhotos(
        isProjectLevel
          ? projectMilestonePhotosQuery.data?.results
          : drawRequestMilestonePhotosQuery.data?.results,
      ),
    [isProjectLevel, drawRequestMilestonePhotosQuery.data, projectMilestonePhotosQuery.data],
  );

  const documentsCount = useMemo(
    () =>
      isProjectLevel
        ? projectMilestonePhotosQuery.data?.count
        : drawRequestMilestonePhotosQuery.data?.count,
    [projectMilestonePhotosQuery.data?.count, drawRequestMilestonePhotosQuery.data?.count],
  );

  return {
    state: getHookState(
      isProjectLevel ? [projectMilestonePhotosQuery] : [drawRequestMilestonePhotosQuery],
    ),
    photos,
    canUploadPhoto,
    tablePagination,
    documentsCount,
  };
};
