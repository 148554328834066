import { useQueries } from 'react-query';
import { HookState, IDrawRequest, PermissionNamesEnums, QueryNamesEnums } from '@interfaces';
import { getDrawRequest } from '@globalService';
import { useParams } from 'react-router-dom';
import {
  currencyFormatter,
  dateFormatter,
  getHookState,
  getRequestRetainageRate,
  isRequestActive,
  isRequestApproved,
  isRequestCompleted,
  isRequestInReview,
  isRestricted,
  percentFormatter,
} from '@utils';
import { Dispatch, useContext, useMemo, useState } from 'react';
import { PermissionsContext } from '@context';

export interface ControllerInterface {
  state: HookState;
  borrowerEquity: number;
  constructionHoldback: number;
  feesAmount: number;
  requestProportionText: string;
  drawRequest: IDrawRequest;
  openEditFeesModal: boolean;
  setOpenEditFeesModal: Dispatch<React.SetStateAction<boolean>>;
  openEditProportionModal: boolean;
  setOpenEditProportionModal: Dispatch<React.SetStateAction<boolean>>;
  showDisbursement: boolean;
  setShowDisbursement: Dispatch<React.SetStateAction<boolean>>;
  retainageRate: number;
  progressValues: {
    prevRetainageBalance: string;
    retainageBalance: string;
    requestedAmount: string;
    approvedAmount: string;
  };
  isInReview: boolean;
  transactionDate: string;
  transactionId: string;
  canEditFees: boolean;
  canEditProportion: boolean;
  isRequestApprovedOrCompleted: boolean;
}

export const useRequestPaymentSummary = (): ControllerInterface => {
  const { projectId, drawRequestId } = useParams();
  const [openEditFeesModal, setOpenEditFeesModal] = useState(false);
  const [openEditProportionModal, setOpenEditProportionModal] = useState(false);
  const [showDisbursement, setShowDisbursement] = useState(false);
  const { permissions } = useContext(PermissionsContext);

  const requestedProjectQueries = useQueries([
    {
      queryKey: [QueryNamesEnums.GET_DRAW_REQUEST, { projectId, drawRequestId }],
      queryFn: getDrawRequest.bind(this, { projectId, drawRequestId }),
      enabled: Boolean(drawRequestId),
    },
  ]);

  const drawRequest = useMemo(
    () => requestedProjectQueries[0]?.data,
    [requestedProjectQueries[0]?.data],
  );

  const borrowerEquity = useMemo(
    () => drawRequest?.borrower_equity,
    [drawRequest?.borrower_equity],
  );

  const constructionHoldback = useMemo(
    () => drawRequest?.construction_holdback,
    [drawRequest?.construction_holdback],
  );

  const feesAmount = useMemo(() => drawRequest?.fees_amount || 0, [drawRequest]);

  const requestProportionText = useMemo(() => {
    return `${percentFormatter({ value: drawRequest?.construction_holdback_rate })}/${percentFormatter({ value: drawRequest?.borrower_equity_rate })} Loan to equity`;
  }, [drawRequest]);

  const progressValues = useMemo(
    () => ({
      prevRetainageBalance: currencyFormatter(
        drawRequest?.totals?.all?.previous_retainage_balance_to_date || 0,
      ),
      retainageBalance: currencyFormatter(drawRequest?.totals?.all?.retainage_balance_to_date || 0),
      requestedAmount: currencyFormatter(drawRequest?.requested_amount || 0),
      approvedAmount: currencyFormatter(drawRequest?.approved_amount || 0),
    }),
    [drawRequest],
  );

  const retainageRate = useMemo(() => getRequestRetainageRate(drawRequest), [drawRequest]);

  const isInReview = useMemo(() => isRequestInReview(drawRequest?.status), [drawRequest]);

  const isActiveDrawRequest = useMemo(
    () => isRequestActive(drawRequest?.status),
    [drawRequest?.status],
  );

  const transactionDate = useMemo(
    () => dateFormatter({ date: drawRequest?.disbursed_at }),
    [drawRequest],
  );

  const transactionId = useMemo(() => drawRequest?.external_transaction_id, [drawRequest]);

  const canEditFees = useMemo(
    () =>
      isActiveDrawRequest && !isRestricted(PermissionNamesEnums.PAYMENTS_MARK_AS_PAID, permissions),
    [isActiveDrawRequest, permissions],
  );

  const canEditProportion = useMemo(
    () =>
      isActiveDrawRequest && !isRestricted(PermissionNamesEnums.PAYMENTS_PARTIES_EDIT, permissions),
    [isActiveDrawRequest, permissions],
  );

  const isRequestApprovedOrCompleted = useMemo(
    () => isRequestApproved(drawRequest?.status) || isRequestCompleted(drawRequest?.status),
    [drawRequest?.status],
  );

  return {
    state: getHookState(requestedProjectQueries),
    borrowerEquity,
    constructionHoldback,
    feesAmount,
    requestProportionText,
    drawRequest,
    openEditFeesModal,
    setOpenEditFeesModal,
    retainageRate,
    progressValues,
    isInReview,
    transactionDate,
    transactionId,
    canEditFees,
    openEditProportionModal,
    setOpenEditProportionModal,
    canEditProportion,
    showDisbursement,
    setShowDisbursement,
    isRequestApprovedOrCompleted,
  };
};
