import { styled } from '@mui/system';
import { Box } from '@mui/material';
import { gridClasses } from '@mui/x-data-grid-premium';
import { colors } from '@theme';

interface TableStyleProps {
  isTablePinned: boolean;
  requiredToPin: boolean;
}

export const TRIGGERHEIGHT = 60;

export const TableStyle = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isTablePinned' && prop !== 'requiredToPin',
})<TableStyleProps>(({ isTablePinned }) => ({
  height: isTablePinned ? `calc(100vh - 16px)` : 'auto',
  background: 'white',
  [`.${gridClasses.cell}`]: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    flexDirection: 'row',
    lineHeight: '1em',
    justifyContent: 'flex-end',
  },
  [`.${gridClasses.cell}.icon+.icon`]: {
    borderRight: 0,
  },
  [`.${gridClasses.row}.totals`]: {
    fontWeight: 'bold',
  },
  [`.${gridClasses.columnHeader}.number`]: {
    textAlign: 'right',
  },
  [`.${gridClasses.columnHeader}.icon+.icon`]: {
    borderRight: 0,
  },
  [`.${gridClasses.cell}:not(.${gridClasses['cell--editable']})`]: {
    color: colors.neutral.dark,
    backgroundColor: 'rgba(247, 247, 247, 0.6)',
  },
  [`.${gridClasses.cell}.text`]: {
    justifyContent: 'flex-start',
  },
  [`.${gridClasses.cell}.number`]: {
    justifyContent: 'flex-end',
  },
}));
