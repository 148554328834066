import {
  useMutation,
  UseMutationResult,
  useQueries,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import {
  BulkDrawRequestListItemUpdateParam,
  ErrorDual,
  IDrawRequest,
  IMilestone,
  IProject,
  LineItemFilterEnum,
  MutationKeyEnum,
  PatchDrawRequestListItemParam,
  PatchDrawRequestProdBuildGroupParam,
  QueryNamesEnums,
  RequestPayload,
} from '@interfaces';
import {
  deleteDrawRequest,
  getDrawRequest,
  getDrawRequestMilestones,
  getProject,
  patchDrawRequestListItem,
  patchDrawRequestProdBuildGroup,
  updateDrawRequestMilestones,
} from '@globalService';
import { useNavigate, useParams } from 'react-router-dom';
import { useRequestTotals, useSafeSnackbar } from '@hooks';
import { useCallback } from 'react';
import { parsePathErrorDual, replaceMilestoneData, usePHBGrouping } from '@utils';
import { excludeCommentsQueryFields, LineItemFilterValues } from '@constants';

interface UseGetQueriesInterface {
  requestedDataQueries: [UseQueryResult<IProject>, UseQueryResult<IDrawRequest>];
  deleteDrawRequestMutation: UseMutationResult<Response, Error, RequestPayload>;
  requestMilestonesQuery: UseQueryResult<{ results: IMilestone[] }, Error>;
  filteredMilestonesQuery: UseQueryResult<{ results: IMilestone[] }, Error>;
  bulkMilestoneMutation: UseMutationResult<Response, ErrorDual, BulkDrawRequestListItemUpdateParam>;
  onMilestoneUpdate: (data: IMilestone, isLineItemPatch?: boolean) => void;
  patchDrawRequestProdBuildGroupMutation: UseMutationResult<
    IMilestone,
    ErrorDual,
    PatchDrawRequestProdBuildGroupParam
  >;
  patchDrawRequestProdBuildMilestoneMutation: UseMutationResult<
    IMilestone,
    ErrorDual,
    PatchDrawRequestListItemParam
  >;
}

export const useGetQueries = ({
  filterTotalKey,
  filterKey,
  updateListItemsWithMsList,
  updateListItemsWithParentGroup,
  isSubmissionProcess,
  typeFilterValue,
  unitsFilterValue,
  setListItems,
}): UseGetQueriesInterface => {
  const { projectId, requestId: drawRequestId } = useParams();
  const { enqueueSnackbar } = useSafeSnackbar();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const query = excludeCommentsQueryFields;
  const { unitLineItemGrouping } = usePHBGrouping();

  const requestedDataQueries = useQueries([
    {
      queryKey: [QueryNamesEnums.GET_PROJECT, { projectId }],
      queryFn: getProject.bind(this, projectId),
    },
    {
      queryKey: [
        QueryNamesEnums.GET_DRAW_REQUEST,
        {
          projectId,
          drawRequestId,
          query: `{-comments_preview,totals{${LineItemFilterValues[LineItemFilterEnum.VERTICAL_COST].totalKey}}}`,
        },
      ],
      queryFn: getDrawRequest.bind(this, {
        projectId,
        drawRequestId,
        query: `{-comments_preview,totals{${LineItemFilterValues[LineItemFilterEnum.VERTICAL_COST].totalKey}}}`,
      }),
      enabled: Boolean(drawRequestId),
    },
  ]);

  const deleteDrawRequestMutation = useMutation<Response, Error, RequestPayload>(
    deleteDrawRequest,
    {
      mutationKey: MutationKeyEnum.MILESTONE_DELETE,
      onSuccess: async () => {
        enqueueSnackbar('Request deleted', { variant: 'success' });
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_DRAW_REQUEST_FOR_APPROVAL,
          { projectId },
        ]);
        queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_PROGRESS, { projectId }]);
        queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_PHOTOS, { projectId }]);
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_PROJECT_DRAW_REQUEST_LIST,
          { projectId },
        ]);
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_PROJECT_MILESTONES,
          { projectId, query },
        ]);
        navigate(`/projects/${projectId}/overview`);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const { refetchAndReplaceTotals } = useRequestTotals({
    projectId,
    drawRequestId,
    totalKey: filterTotalKey,
    filterKey,
    groupByKeys: unitLineItemGrouping,
  });

  const requestMilestonesQuery = useQuery<{ results: IMilestone[] }, Error>(
    [
      QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES,
      {
        projectId,
        drawRequestId,
        groupBy: unitLineItemGrouping,
        filterKey,
      },
    ],
    getDrawRequestMilestones.bind(this, {
      projectId,
      drawRequestId,
      groupBy: unitLineItemGrouping,
      filterKey,
    }),
    { enabled: Boolean(drawRequestId && projectId && unitLineItemGrouping) },
  );

  const filteredMilestonesQuery = useQuery<{ results: IMilestone[] }, Error>(
    [
      QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES,
      {
        projectId,
        drawRequestId,
        filterKey: typeFilterValue,
        milestoneTags: unitsFilterValue,
      },
    ],
    getDrawRequestMilestones.bind(this, {
      projectId,
      drawRequestId,
      filterKey: typeFilterValue,
      milestoneTags: unitsFilterValue,
    }),
    {
      enabled: Boolean(drawRequestId && projectId),
    },
  );

  // bulk milestones update on autofill
  const bulkMilestoneMutation = useMutation<
    Response,
    ErrorDual,
    BulkDrawRequestListItemUpdateParam
  >(updateDrawRequestMilestones, {
    mutationKey: MutationKeyEnum.MILESTONE_PATCH_BULK,
    onSuccess: () => {
      requestMilestonesQuery.refetch().then((res) => {
        updateListItemsWithMsList(res.data?.results);
      });
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES,
        { projectId, drawRequestId },
      ]);
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_DRAW_REQUEST,
        { projectId, drawRequestId },
      ]);
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES_COLUMNS,
        { projectId, requestId: drawRequestId },
      ]);
      queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_PROGRESS, { projectId }]);
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_DRAW_REQUEST_MILESTONE,
        { projectId, drawRequestId },
      ]);
      queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_MILESTONE, { projectId }]);
    },
    onError: (error) => {
      enqueueSnackbar(parsePathErrorDual(error), { variant: 'error' });
    },
  });

  const patchDrawRequestProdBuildGroupMutation = useMutation<
    IMilestone,
    ErrorDual,
    PatchDrawRequestProdBuildGroupParam
  >(patchDrawRequestProdBuildGroup, {
    mutationKey: MutationKeyEnum.MILESTONE_PATCH,
    onSuccess: (data) => {
      onMilestoneUpdate(data);
    },
  });

  const patchDrawRequestProdBuildMilestoneMutation = useMutation<
    IMilestone,
    ErrorDual,
    PatchDrawRequestListItemParam
  >(patchDrawRequestListItem, {
    mutationKey: MutationKeyEnum.MILESTONE_PATCH,
    onSuccess: (data) => {
      onMilestoneUpdate(data, true);
    },
  });

  const invalidateAfterLineItemPatch = async (data) => {
    queryClient.setQueriesData<{ results: IMilestone[] }>(
      {
        queryKey: [
          QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES,
          {
            projectId,
            drawRequestId,
            filterKey: typeFilterValue,
            milestoneTags: unitsFilterValue,
          },
        ],
      },
      (old) =>
        replaceMilestoneData({
          milestones: old,
          milestoneId: data.id,
          json: data,
        }),
    );
    await queryClient.invalidateQueries([
      QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES,
      { projectId, drawRequestId, groupBy: unitLineItemGrouping, filterKey },
    ]);
  };

  const invalidateAfterMSGroupPatch = (data) => {
    queryClient.setQueriesData<{ results: IMilestone[] }>(
      {
        queryKey: [
          QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES,
          {
            projectId,
            drawRequestId,
            groupBy: unitLineItemGrouping,
            filterKey,
          },
        ],
      },
      (old) =>
        replaceMilestoneData({
          milestones: old,
          milestoneId: data.id,
          json: data,
        }),
    );
    queryClient.invalidateQueries([
      QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES,
      { projectId, drawRequestId, filterKey: typeFilterValue, milestoneTags: unitsFilterValue },
    ]);
  };

  const onMilestoneUpdate = useCallback(
    async (data, isLineItemPatch = false) => {
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES_COLUMNS,
        { projectId, requestId: drawRequestId },
      ]);
      if (isLineItemPatch) {
        await invalidateAfterLineItemPatch(data);
        setListItems(null);
      } else {
        invalidateAfterMSGroupPatch(data);
        updateListItemsWithParentGroup(data);
      }

      refetchAndReplaceTotals({ isMilestonesUpdate: false });
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_DRAW_REQUEST,
        { projectId, drawRequestId },
      ]);
      if (isSubmissionProcess) {
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES,
          {
            projectId,
            drawRequestId,
            filterKey: LineItemFilterValues.CURRENT_REQUEST_ONLY.filterKey,
            restQlParams: '{}',
            limit: '1',
          },
        ]);
      }
    },
    [projectId, drawRequestId, unitLineItemGrouping, query, filterKey, refetchAndReplaceTotals],
  );

  return {
    requestedDataQueries,
    deleteDrawRequestMutation,
    requestMilestonesQuery,
    filteredMilestonesQuery,
    bulkMilestoneMutation,
    onMilestoneUpdate,
    patchDrawRequestProdBuildGroupMutation,
    patchDrawRequestProdBuildMilestoneMutation,
  };
};
