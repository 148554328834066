import { max } from 'date-fns';
import { ENABLED_MIME_TYPES, LineItemFilterValues, REGEX, userFieldsToStore } from '@constants';
import {
  ChecklistItemLocal,
  ContingencyModeEnum,
  DocumentTypeEnum,
  DrawRequestTypeEnum,
  ErrorDual,
  IDataFieldModel,
  IDocument,
  IDrawRequest,
  IInspection,
  IItem,
  IMilestone,
  IMilestoneColumn,
  IMilestoneTotal,
  InitPatchField,
  IPermission,
  IProject,
  IProjectDocument,
  IProjectMilestone,
  IProofpoint,
  IReplaceItemInListParams,
  IServiceOrder,
  IUser,
  IWorkflowReason,
  LineItemFilterEnum,
  MilestoneTag,
  MilestoneTagsTypesEnums,
  PermissionNamesEnums,
  PolicyItemTypesEnum,
  TableKeyEnum,
  TTeamRole,
  WorkflowType,
} from '@interfaces';
import cloneDeep from 'lodash/cloneDeep';
import pick from 'lodash/pick';
import { userIsAllowedToLogin } from './decorators';
import { NumberFormatValues } from 'react-number-format';
import {
  checkIsAdmin,
  checkIsInvestor,
  checkIsLender,
  checkIsOwner,
  checkIsPaymentManager,
  isDrawRequest,
  isInspectionService,
  isMilestoneIncomplete,
  isOrderedService,
  isRequestInReview,
  isRestricted,
  parsePathErrorDual,
} from './index';
import { compact, flatMap, sortBy } from 'lodash';
import { colors } from '@theme';
import { CommonRowType } from '../components/MilestoneList/columns';

export const checkIsReallocateComplete = (request?: IDrawRequest): boolean =>
  request && !request.totals?.all?.requested_budget_change;

export const checkIsReallocateCompleteByLender = (request?: IDrawRequest): boolean =>
  request && !request.totals?.all?.approved_budget_change;

export const getFromListById = (requests: IDrawRequest[], id: string): IDrawRequest =>
  requests?.find((item) => item.id === id);

export const checkIsAllNamesFillInProject = (milestones?: IProjectMilestone[]): boolean =>
  milestones && (!milestones.length || milestones.every((item) => item.name));

export const checkRequestedRetainageCorrect = (request?: IDrawRequest): boolean =>
  !request?.errors?.retainage_release_requested;

export const checkApprovedRetainageCorrect = (request?: IDrawRequest): boolean =>
  !request?.errors?.retainage_release_approved;

export const checkIsAllNamesFill = (request?: IDrawRequest): boolean => !request?.errors?.name;

export const checkIsCreator = (request?: IDrawRequest, role?: TTeamRole) =>
  role === request?.team?.team_role;

export const getUserForStorage = (user: IUser) => {
  const userToStore = pick(user, userFieldsToStore);
  user.isAllowedToLogin = userIsAllowedToLogin({ user });
  localStorage.setItem('user@store', JSON.stringify(userToStore));
  return user;
};

export const getColumnByName = (columns: IMilestoneColumn[], name: string) =>
  columns.find((column) => column.name === name);

export const getPreviousRequestsByID = (requests?: IDrawRequest[], currentId?: string) => {
  if (!requests || !currentId) return [];
  const index = requests.findIndex(({ id }) => id === currentId);
  return index >= 0 ? requests.slice(index) : requests;
};

export const getPoliciesListTitle = (teamName: string, checklistItems: ChecklistItemLocal[]) => {
  const totalItems = checklistItems?.length;
  const checkedItems = checklistItems?.filter((i) => i.checked).length;
  return totalItems ? `${teamName} (${checkedItems}/${totalItems})` : teamName;
};

export const isCostTypeContingency = (cost_type: InitPatchField) =>
  typeof cost_type !== 'string' && cost_type?.key === 'CONTINGENCY';

// The updated function with type annotations
export const replaceItemInList = ({ items, updatedItem }: IReplaceItemInListParams) => {
  if (!items?.length) return [];
  const newItems = cloneDeep(items);
  const index = newItems?.findIndex((item) => item?.id === updatedItem?.id);

  if (index !== -1) {
    // Merge the updatedItem into the existing item
    newItems[index] = { ...newItems[index], ...updatedItem };
  }
  return newItems;
};

export const replaceItemInPaginatedResponse = ({
  old,
  updatedItem,
}: {
  old: {
    results: IItem[];
  };
  updatedItem: IItem;
}) => ({
  ...old,
  results: replaceItemInList({ items: old?.results, updatedItem }),
});

// Helper function to enhance items list with comments
export const enhanceItemsWithCommentsPreview = (items, comments) => {
  const commentsById = comments.reduce((acc, curr) => {
    acc[curr.id] = curr.comments_preview;
    return acc;
  }, {});

  return items.map((item) => ({
    ...item,
    ...(commentsById[item.id] && {
      comments_preview: commentsById[item.id],
    }),
  }));
};

export const replaceMilestoneData = ({
  milestones,
  milestoneId,
  json,
}: {
  milestones: { results: IMilestone[] };
  milestoneId: string;
  json: Partial<IMilestone>;
}) => {
  const data = cloneDeep(milestones?.results);
  const mlIndex = data?.findIndex((ml) => ml.id === milestoneId);
  if (mlIndex >= 0) {
    data[mlIndex] = {
      ...data[mlIndex],
      ...json,
    };
  }
  return { results: data, count: data?.length };
};

export const replaceRequestTotals = (request: IDrawRequest, json: Partial<IMilestoneTotal>) => {
  if (!request?.totals?.all) return request;
  const data = cloneDeep(request);
  const allTotals = { ...data.totals.all, ...json };
  return { ...data, totals: { ...data.totals, all: allTotals } };
};

export const findLastCompletedInspectionDate = (inspectionList: IInspection[]): Date => {
  if (!inspectionList) return null;
  return max(
    inspectionList
      .filter(({ completed_at }) => completed_at)
      ?.map(({ completed_at }) => new Date(completed_at)),
  );
};

export const canDeleteRequest = (drawRequest: IDrawRequest) => drawRequest?.can_be_deleted;

export const isMilestoneFieldsContainErrors = (
  listOfKeysForValidation: string[],
  request: IDrawRequest,
) => listOfKeysForValidation?.some((key) => Object.keys(request?.errors || {}).includes(key));

export const notAllowNegative = (values: NumberFormatValues) =>
  values.floatValue >= 0 || values.value === '';

export const isPercentAllowed = (values: NumberFormatValues) =>
  (values.floatValue <= 100 && values.floatValue >= 0) || values.value === '';

export const replaceObjectProperties = ({ data, newData }) => {
  if (!data) return null;
  if (!newData) return data;
  const dataClone = cloneDeep(data);
  return { ...dataClone, ...newData };
};

export const canBeEdited = ({
  row,
  contingencyMode,
  adjustments_field,
  error,
}: {
  row: CommonRowType;
  contingencyMode: ContingencyModeEnum;
  adjustments_field: string;
  error: string;
}) =>
  row.activeToEdit &&
  (isMilestoneIncomplete(row, adjustments_field) || error) &&
  (!isCostTypeContingency(row.cost_type) ||
    contingencyMode === ContingencyModeEnum.NORMAL ||
    row.isRequestHistorical);

export const canBeEditedWithoutMilestoneCheck = ({
  row,
  contingencyMode,
}: {
  row: CommonRowType;
  contingencyMode: ContingencyModeEnum;
}) =>
  row.activeToEdit &&
  (!isCostTypeContingency(row.cost_type) ||
    contingencyMode === ContingencyModeEnum.NORMAL ||
    row.isRequestHistorical);

export const getReasonsList = (reasons: IWorkflowReason[], workflowType: WorkflowType) => {
  if (!reasons) return [];
  return (
    reasons
      ?.filter((reason) => reason.key === workflowType)
      .map((reason) => reason.name)
      // move 'Other' to the end of the list
      .sort((a, b) => (a === 'Other' ? 1 : b === 'Other' ? -1 : 0))
  );
};

export const getSortedByIndexMilestones = (milestones: Partial<IMilestone | IProjectMilestone>[]) =>
  sortBy(milestones, [(ml) => ml.project_milestone?.index]);

export const getUnitsFromMilestones = (milestones: IMilestone[]) =>
  compact(milestones?.flatMap((item) => item.milestone_groups));

export const getGroupFromMilestones = (milestones: IMilestone[]) =>
  flatMap(milestones, (item: IMilestone) => {
    const { milestone_groups, ...rest } = item;
    if (milestone_groups) {
      return [rest, ...milestone_groups];
    }
    return [rest];
  });

export const getRequestRetainageRate = (request: IDrawRequest) =>
  isDrawRequest(request) ? request?.project?.retainage_rate : 0;

export const validationShareRule = (value) =>
  !!(!isNaN(value) && parseInt(value) >= 0 && parseInt(value) <= 100);

export const validationAmountRule = (value) => !!(!isNaN(value) && parseInt(value) >= 0);

export const getDefaultChecklistItem = (
  checklistItems: ChecklistItemLocal[],
  itemType: PolicyItemTypesEnum,
) => checklistItems.find((item) => item.type === itemType);

export const hasApprovedAmount = (drawRequest: IDrawRequest) =>
  Boolean(drawRequest?.totals?.current?.approved_amount);

export const hasApprovedReallocation = (drawRequest: IDrawRequest) =>
  Boolean(drawRequest?.approved_reallocation);

export const hasRequestedReallocation = (drawRequest: IDrawRequest) =>
  Boolean(drawRequest?.requested_reallocation);

export const hasApprovedRetainage = (drawRequest: IDrawRequest) =>
  Boolean(drawRequest?.totals?.current?.retainage_release_approved);

export const getItemIndexFromList = (id, listItems) =>
  listItems?.findIndex((item) => item.id === id);

export const getErrorText = (error: ErrorDual) =>
  error?.message || parsePathErrorDual(error as ErrorDual);

export const checkIsFieldsValid = (fields: IDataFieldModel) => {
  let allValid = true;
  Object.values(fields).forEach((o) => {
    if (!o.validate()) allValid = false;
  });
  return allValid;
};

//show to CS if DR is in review status, show to lender/investor if waits_current_user_approval=true
export const autofillCanBeShown = (drawRequest: IDrawRequest, teamRole: string) =>
  (isRequestInReview(drawRequest?.status) && checkIsAdmin(teamRole)) ||
  (drawRequest?.waits_current_user_approval &&
    (checkIsLender(teamRole) || checkIsInvestor(teamRole)));

export const checkIsApprovedReallocationOutOfEstimate = (request: IDrawRequest) =>
  request?.approved_reallocation > request?.totals?.all?.revised_estimate;

export const checkIsRequestedReallocationOutOfEstimate = (request: IDrawRequest) =>
  request?.requested_reallocation > request?.totals?.all?.requested_revised_estimate;

export const getGroupByFilterIds = (
  groupByOptions: { filterValue: string; ids: string }[],
  groupByValue: string,
) => groupByOptions?.find(({ filterValue }) => filterValue === groupByValue)?.ids;

export const getLink = ({
  row,
  tableKey,
  teamRole,
}: {
  row: IProject | IDrawRequest | IInspection | IServiceOrder;
  tableKey: TableKeyEnum;
  teamRole?: TTeamRole;
}) => {
  if (!row?.id) return '';
  switch (tableKey) {
    case TableKeyEnum.RISK_RADAR:
      return `/projects/${(row as IProject).id}/overview`;
    case TableKeyEnum.REQUESTS: {
      if (!(row as IDrawRequest)?.project?.id) return '';
      if (checkIsOwner(teamRole)) {
        return `/projects/${(row as IDrawRequest).project.id}/overview`;
      }
      if (checkIsPaymentManager(teamRole)) {
        return `/projects/${(row as IDrawRequest).project.id}/payments/draw-requests/${row.id}`;
      }
      return `/projects/${(row as IDrawRequest).project.id}/draws/draw-requests/${row.id}`;
    }
    case TableKeyEnum.INSPECTION_LIST: {
      if (!(row as IInspection)?.project?.id) return '';
      const basicPath = `/projects/${(row as IInspection).project.id}/inspection/${row.id}`;
      return isOrderedService((row as IInspection)?.status)
        ? basicPath + '/edit'
        : basicPath + '/view';
    }
    case TableKeyEnum.SERVICES_LIST: {
      if (!(row as IInspection | IServiceOrder)?.project?.id) return '';

      return isInspectionService((row as IInspection | IServiceOrder)?.service_type)
        ? `/projects/${(row as IInspection).project.id}/inspection/${row.id}/${isOrderedService((row as IInspection)?.status) ? 'edit' : 'view'}`
        : `/projects/${(row as IServiceOrder).project.id}/service/${row.id}`;
    }
    default:
      return '';
  }
};

export const getFilteredByRepresentationPhotos = (photos: (IProofpoint | IDocument)[]) =>
  photos?.filter((photo) => photo?.file_representations);

const InitColumns = {
  [DrawRequestTypeEnum.CHANGE_REQUEST]: [
    'previous_changes',
    'previous_changes_rate',
    'requested_adjustments',
    'requested_adjustments_rate',
    'approved_adjustments',
    'approved_adjustments_rate',
    'adjustments_rate',
    'requested_revised_estimate',
  ],
  [DrawRequestTypeEnum.DRAW_REQUEST]: [
    'requested_amount',
    'requested_amount_relative',
    'inspector_allowance',
    'inspector_allowance_rate',
    'inspector_allowance_incremental',
    'inspector_allowance_rate_incremental',
    'approved_amount_cumulative',
    'lender_allowance_rate',
    'approved_amount',
    'approved_amount_relative',
    'previous_approved_amount_cumulative',
    'previous_lender_allowance_rate',
    'variance_to_lender_allowance',
    'variance_to_lender_allowance_rate',
  ],
  [DrawRequestTypeEnum.RETAINAGE_REQUEST]: [
    'retainage_release_requested',
    'retainage_release_approved',
    'retainage_balance_to_date',
    'disbursement_amount_requested',
    'retainage_approved_amount_holdback',
  ],
};

const hiddenColumnsByCategory = {
  all: ['cost_type', 'prefunding_cost', 'original_estimate', 'balance_to_finish_rate'],
  [DrawRequestTypeEnum.DRAW_REQUEST]: [
    'requested_amount_relative',
    'inspector_allowance',
    'inspector_allowance_rate',
    'inspector_allowance_incremental',
    'inspector_allowance_rate_incremental',
    'approved_amount_cumulative',
    'lender_allowance_rate',
    'approved_amount',
    'approved_amount_relative',
    'previous_lender_allowance_rate',
    'variance_to_lender_allowance',
    'variance_to_lender_allowance_rate',
  ],
  [DrawRequestTypeEnum.CHANGE_REQUEST]: [
    'previous_changes',
    'previous_changes_rate',
    'requested_adjustments_rate',
    'approved_adjustments_rate',
    'adjustments_rate',
  ],
  [DrawRequestTypeEnum.RETAINAGE_REQUEST]: [
    'retainage_release_approved',
    'disbursement_amount_requested',
    'retainage_approved_amount_holdback',
  ],
};

export const columnInitScheme = [
  {
    color: colors.neutral.lighter,
    columns: [],
  },
  {
    name: 'Change request',
    color: colors.status.orange.medium,
    description: 'Adjust line item budgets and propose equity changes',
    initColumns: InitColumns[DrawRequestTypeEnum.CHANGE_REQUEST],
    columns: [],
  },
  {
    name: 'Draw request',
    color: colors.status.success.medium,
    description: 'Review draw request and recommendations',
    initColumns: InitColumns[DrawRequestTypeEnum.DRAW_REQUEST],
    columns: [],
  },
  {
    name: 'Retainage',
    description: 'Manage retainage holdback, balance, and release',
    color: colors.status.information.medium,
    initColumns: InitColumns[DrawRequestTypeEnum.RETAINAGE_REQUEST],
    columns: [],
  },
];

export const getHiddenColumnsForDR = (type: DrawRequestTypeEnum[]) => {
  const result = [...hiddenColumnsByCategory.all];

  if (type.includes(DrawRequestTypeEnum.DRAW_REQUEST)) {
    result.push(...hiddenColumnsByCategory[DrawRequestTypeEnum.DRAW_REQUEST]);
    if (!type.includes(DrawRequestTypeEnum.CHANGE_REQUEST)) {
      result.push(...InitColumns[DrawRequestTypeEnum.CHANGE_REQUEST]);
    }

    if (!type.includes(DrawRequestTypeEnum.RETAINAGE_REQUEST))
      result.push(...InitColumns[DrawRequestTypeEnum.RETAINAGE_REQUEST]);
  }
  if (type.includes(DrawRequestTypeEnum.CHANGE_REQUEST)) {
    result.push(...hiddenColumnsByCategory[DrawRequestTypeEnum.CHANGE_REQUEST]);
    if (!type.includes(DrawRequestTypeEnum.DRAW_REQUEST))
      result.push(...InitColumns[DrawRequestTypeEnum.DRAW_REQUEST]);

    if (!type.includes(DrawRequestTypeEnum.RETAINAGE_REQUEST))
      result.push(...InitColumns[DrawRequestTypeEnum.RETAINAGE_REQUEST]);
  }

  if (type.includes(DrawRequestTypeEnum.RETAINAGE_REQUEST)) {
    result.push(...hiddenColumnsByCategory[DrawRequestTypeEnum.RETAINAGE_REQUEST]);
    if (!type.includes(DrawRequestTypeEnum.DRAW_REQUEST))
      result.push(...InitColumns[DrawRequestTypeEnum.DRAW_REQUEST]);

    if (!type.includes(DrawRequestTypeEnum.CHANGE_REQUEST))
      result.push(...InitColumns[DrawRequestTypeEnum.CHANGE_REQUEST]);
  }

  return result;
};

export const emptyHTML = (value: string) => value === '<p><br></p>';

export const lenderCreditAction = (
  milestone: Partial<IMilestone>,
): 'required' | 'complete' | 'invalid' | 'not-required' => {
  if (!milestone.requested_amount || milestone.requested_amount > 0) return 'not-required';
  if (!milestone.approved_amount) return 'required';
  return milestone.approved_amount < 0 ? 'complete' : 'invalid';
};

export const documentCanBeProcessed = (document: IProjectDocument | IDocument) =>
  ENABLED_MIME_TYPES.includes(
    document?.mime_type || document?.file_representations?.original?.mime_type,
  );

export const checkHasAmountChanges = (milestones: IProjectMilestone[]) =>
  milestones?.some((ms) => Boolean(ms?.approved_adjustments));

export const creditAvailableForMilestone = (milestone: Partial<IMilestone>) =>
  milestone.previous_approved_amount_cumulative > 0;

export const wasCreditItteraction = (milestone: Partial<IMilestone>) =>
  milestone.approved_credit_amount || milestone.requested_credit_amount;

export const checkIsApprovedNegativeAmount = (request?: IDrawRequest) =>
  request?.totals?.all?.approved_amount < 0;

export const checkIsCreditInDraw = (request: IDrawRequest) => !!request?.credit_amount;

export const calculateRequestedAdjustment = (row: CommonRowType, requested_amount: number) =>
  requested_amount - (+row.balance_to_finish - +row.approved_amount);

export const isFeesPaidByBorrower = (drawRequest: IDrawRequest) =>
  drawRequest?.construction_holdback === 0 && drawRequest?.fees_amount > 0;

export const getDynamicColumnsForBudgetCSV = (permissions: IPermission) => {
  const permissionRelatedColumns = [
    {
      column_name: 'approved_amount',
      type: 'regex',
      validators: { expression: REGEX.positiveCurrency },
      info_hint: 'cumulative approved amount for historical DR',
    },
    {
      column_name: 'adjustments',
      type: 'regex',
      validators: {
        expression: REGEX.positiveAndNegativeCurrency,
      },
      info_hint: 'cumulative adjustments amount for historical DR',
    },
    {
      column_name: 'inspector_allowance_rate',
      type: 'number',
      validators: {
        min_value: 0,
        max_value: 100,
        allow_commas: false,
      },
    },
  ];

  // project__create__button is permission for lender and he has possibility to create historical draws
  // borrower can have only project__budget__edit permission and he can't create historical draws
  if (!isRestricted(PermissionNamesEnums.PROJECT_ONBOARDING, permissions))
    return permissionRelatedColumns;

  return [];
};

export const getMilestoneTagId = (milestoneTags: MilestoneTag[], type: MilestoneTagsTypesEnums) =>
  milestoneTags?.find((tag) => tag.type === type)?.id;

export const getMilestoneTagName = (milestoneTags: MilestoneTag[], type: MilestoneTagsTypesEnums) =>
  milestoneTags?.find((tag) => tag.type === type)?.name;

export const getPHBViewTypes = (unitName: string) => [
  { label: unitName, value: 'units' },
  { label: 'Line items', value: 'line_items' },
];

export const getTypeFilter = (unitsSelected: boolean) =>
  unitsSelected
    ? LineItemFilterValues[LineItemFilterEnum.VERTICAL_COST]
    : LineItemFilterValues[LineItemFilterEnum.HORIZONTAL_SOFT_COST];

export const isAppraisalDocumentType = (typeName: string) =>
  typeName === DocumentTypeEnum.APPRAISAL;

export const serializeFilters = (filters: Record<string, Array<string>>): string => {
  const params = new URLSearchParams();

  for (const key in filters) {
    if (Object.prototype.hasOwnProperty.call(filters, key)) {
      if (filters[key].length) params.set(key, filters[key].join(','));
    }
  }

  return params.toString();
};

export const deserializeFilters = (queryString: string): Record<string, Array<string>> => {
  const params = new URLSearchParams(queryString);
  const filters: Record<string, Array<string>> = {};

  params.forEach((value, key) => {
    filters[key] = value.split(',');
  });

  return filters;
};
