import React, { FC } from 'react';
import { Stack, Typography } from '@mui/material';

import { colors } from '@theme';

import { PersonRoundedIcon } from '@svgAsComponents';
import { IUser, IWatcher } from '@interfaces';

const UserAvatar: FC<{ user: IUser | IWatcher; color?: string; size?: number }> = ({
  user,
  color = colors.main.primary.main,
  size = 32,
}) => {
  const userAbbreviation = user?.first_name?.charAt(0) + user?.last_name?.charAt(0);

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        borderRadius: '50%',
        height: size,
        width: size,
        backgroundColor: color,
      }}
    >
      {userAbbreviation ? (
        <Typography variant="labelSemiBold" sx={{ color: colors.background.white }}>
          {userAbbreviation}
        </Typography>
      ) : (
        <PersonRoundedIcon color={colors.background.white} size={16} />
      )}
    </Stack>
  );
};

export default UserAvatar;
