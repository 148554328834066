import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  ErrorDual,
  IFilterOption,
  IInspection,
  IMilestone,
  IMilestoneTotal,
  IPHBTableItem,
  MutationKeyEnum,
  PatchInspectionListItemParam,
  PatchInspectionProdBuildGroupParam,
  QueryNamesEnums,
  TableKeyEnum,
} from '@interfaces';
import {
  getInspectionMilestones,
  getProjectInspectionById,
  patchInspectionMilestoneToProject,
  patchInspectionPHBGroup,
} from '@globalService';
import { useParams } from 'react-router-dom';
import {
  getItemLocalHighlight,
  getPHBViewTypes,
  getTypeFilter,
  parsePathErrorDual,
  replaceMilestoneData,
  sortByUnitName,
  useExpandCollapseTable,
  useLoadingSkeleton,
  usePHBFilters,
  usePHBGrouping,
  usePHBNaming,
} from '@utils';
import { useSafeSnackbar } from '@hooks';
import cloneDeep from 'lodash/cloneDeep';
import { excludeCommentsQueryFields } from '@constants';

export type ControllerInterface = {
  initColumns: string[];
  milestones: IPHBTableItem[];
  onExpandClick: (id: string, isExpanded: boolean) => void;
  filterOptions: IFilterOption[];
  filterValue: string;
  handleFilterClick: (value: string) => void;
  patchInspectionMSgroup: (params) => void;
  patchInspectionMilestone: (params) => void;
  isLoading: boolean;
  totals: IMilestoneTotal;
  activeView: string;
  setActiveView: Dispatch<SetStateAction<string>>;
  viewTypes: { label: string; value: string }[];
  handleUnitsFilterClick: (value: string[]) => void;
  unitsFilterValues: string[];
  isLineItemsView: boolean;
  filteredMilestones: IPHBTableItem[];
};

export const useInspectionTable = (showRequestedAmount = false): ControllerInterface => {
  const queryClient = useQueryClient();
  const [listItems, setListItems] = useState([]);
  const { showLoadingSkeleton, showTemporaryLoadingSkeleton } = useLoadingSkeleton();
  const { inspectionId, projectId } = useParams();
  const { enqueueSnackbar } = useSafeSnackbar();
  const query = '{*}';
  const { unitName } = usePHBNaming();
  const viewTypes = useMemo(() => getPHBViewTypes(unitName), [unitName]);
  const [activeView, setActiveView] = useState<string>(viewTypes[0].value);
  const [unitsFilterValues, setUnitsFilterValues] = useState<string[]>([]);

  const handleUnitsFilterClick = (value: string[]) => {
    setUnitsFilterValues(value);
  };

  const { unitLineItemGrouping } = usePHBGrouping();
  const { filterValue, handleFilterClick, filterOptions, filterKey } = usePHBFilters({
    tableKey: TableKeyEnum.PHB_LINE_ITEMS,
    isNewView: true,
  });

  const inspectionQuery = useQuery<IInspection, Error>(
    [QueryNamesEnums.GET_PROJECT_INSPECTION_BY_ID, { projectId, inspectionId }],
    getProjectInspectionById.bind(this, { projectId, inspectionId }),
    { enabled: Boolean(inspectionId && projectId && unitLineItemGrouping) },
  );

  const inspectionMilestonesQuery = useQuery<{ results: IMilestone[] }, Error>(
    [
      QueryNamesEnums.GET_INSPECTION_MILESTONES,
      {
        projectId,
        inspectionId,
        query,
        groupBy: unitLineItemGrouping,
        filterKey,
      },
    ],
    getInspectionMilestones.bind(this, {
      projectId,
      inspectionId,
      groupBy: unitLineItemGrouping,
      filterKey,
    }),
    { enabled: Boolean(inspectionId && projectId && unitLineItemGrouping) },
  );
  const inspectionMilestones = inspectionMilestonesQuery.data;

  const typeFilterKey = useMemo(
    () => getTypeFilter(!!unitsFilterValues?.length).filterKey,
    [unitsFilterValues],
  );

  const filteredMilestonesQuery = useQuery<{ results: IMilestone[] }, Error>(
    [
      QueryNamesEnums.GET_INSPECTION_MILESTONES,
      {
        projectId,
        inspectionId,
        filterKey: typeFilterKey,
        milestoneTags: unitsFilterValues.join(),
      },
    ],
    getInspectionMilestones.bind(this, {
      projectId,
      inspectionId,
      filterKey: typeFilterKey,
      milestoneTags: unitsFilterValues.join(),
    }),
    { enabled: Boolean(inspectionId && projectId && unitLineItemGrouping) },
  );

  const patchPHBMilestoneInspectionMutation = useMutation<
    IMilestone,
    ErrorDual,
    PatchInspectionProdBuildGroupParam
  >(patchInspectionPHBGroup, {
    mutationKey: MutationKeyEnum.MILESTONE_PATCH,
    onSuccess: async (data) => {
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_PROJECT_INSPECTION_BY_ID,
        { projectId, inspectionId },
      ]);
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_INSPECTION_MILESTONES,
        {
          projectId,
          inspectionId,
          filterKey: typeFilterKey,
        },
      ]);
      queryClient.setQueriesData<{ results: IMilestone[] }>(
        {
          queryKey: [
            QueryNamesEnums.GET_INSPECTION_MILESTONES,
            {
              projectId,
              inspectionId,
              query,
              filterKey,
              groupBy: unitLineItemGrouping,
            },
          ],
        },
        (old) =>
          replaceMilestoneData({
            milestones: old,
            milestoneId: data.id,
            json: data,
          }),
      );
      updateListItemsWithParentGroup(data);
    },
    onError: (error) => {
      enqueueSnackbar(parsePathErrorDual(error), { variant: 'error' });
    },
  });

  const patchMilestoneInspectionMutation = useMutation<
    Response,
    ErrorDual,
    PatchInspectionListItemParam
  >(patchInspectionMilestoneToProject, {
    mutationKey: MutationKeyEnum.MILESTONE_PATCH,
    onSuccess: async () => {
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_PROJECT_INSPECTION_BY_ID,
        { projectId, inspectionId },
      ]);
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_PROJECT_MILESTONES,
        { projectId, query: excludeCommentsQueryFields },
      ]);
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_INSPECTION_MILESTONES,
        {
          projectId,
          inspectionId,
          filterKey: typeFilterKey,
          milestoneTags: unitsFilterValues.join(),
        },
      ]);
      await queryClient.invalidateQueries([
        QueryNamesEnums.GET_INSPECTION_MILESTONES,
        { projectId, inspectionId, query, groupBy: unitLineItemGrouping, filterKey },
      ]);
      setListItems(null);
    },
    onError: (error) => {
      enqueueSnackbar(parsePathErrorDual(error), { variant: 'error' });
    },
  });

  const patchInspectionMilestone = useCallback(
    (params) => {
      return patchMilestoneInspectionMutation.mutate({
        projectId,
        inspectionId,
        milestoneId: params.milestoneId,
        json: params.json,
      });
    },
    [inspectionId, projectId],
  );

  useEffect(() => {
    setListItems(null);
  }, [filterValue]);

  useEffect(() => {
    if (!inspectionMilestones?.results || listItems?.length) return;

    const clonedMilestones = cloneDeep(inspectionMilestones.results);
    setListItems(clonedMilestones.map((item, index) => createTableObject({ item, index })));
  }, [inspectionMilestones?.results, listItems]);

  const createTableObject = ({
    item,
    isExpanded = false,
    isNested = false,
    index,
  }: {
    item: IMilestone;
    isExpanded?: boolean;
    isNested?: boolean;
    index: number | string;
  }): IPHBTableItem => ({
    ...item,
    activeToEdit: item?.revised_estimate > 0,
    localNew: false,
    localHighlight: getItemLocalHighlight(item),
    canBeExpanded: item?.milestone_groups?.length > 0,
    isExpanded,
    isNested,
    index,
  });

  const { onExpandClick, updateListItemsWithParentGroup } = useExpandCollapseTable({
    setListItems,
    createTableObject,
  });

  const initColumns = useMemo(
    () => [
      'productionBuildExpand',
      'name',
      'inspectorAllowanceRate',
      'revisedEstimate',
      ...(showRequestedAmount ? ['requestedAmount'] : []),
      'comments',
    ],
    [showRequestedAmount],
  );

  const patchInspectionMSgroup = useCallback(
    (params) => {
      return patchPHBMilestoneInspectionMutation.mutate({
        projectId,
        inspectionId,
        group_by: unitLineItemGrouping,
        tags: params.milestoneTags,
        json: params.json,
        filterKey,
      });
    },
    [inspectionId, projectId, unitLineItemGrouping, filterKey],
  );

  const totals = useMemo(() => {
    const inspection = inspectionQuery.data;
    return {
      previous_inspector_allowance_rate: inspection?.totals?.all?.previous_inspector_allowance_rate,
      inspector_allowance_rate: inspection?.totals?.all?.inspector_allowance_rate,
      requested_amount: inspection?.totals?.all?.requested_amount,
      revised_estimate: inspection?.totals?.all?.revised_estimate,
      displayAll: true,
    };
  }, [inspectionQuery.data]);

  const isLineItemsView = useMemo(() => activeView === viewTypes[1].value, [activeView]);

  const filteredMilestones = useMemo(
    () =>
      filteredMilestonesQuery.data?.results
        ?.sort(sortByUnitName)
        ?.map((item, index) =>
          createTableObject({ item: { ...item, milestone_groups: [] }, index }),
        ),
    [filteredMilestonesQuery.data?.results],
  );

  return {
    initColumns,
    milestones: listItems,
    onExpandClick,
    filterOptions,
    filterValue,
    handleFilterClick: (value: string) => {
      handleFilterClick(value);
      showTemporaryLoadingSkeleton();
    },
    isLoading: showLoadingSkeleton || inspectionMilestonesQuery.isLoading,
    patchInspectionMSgroup,
    totals,
    activeView,
    setActiveView,
    viewTypes,
    handleUnitsFilterClick,
    unitsFilterValues,
    isLineItemsView,
    filteredMilestones,
    patchInspectionMilestone,
  };
};
