import {
  EnumTypeForList,
  ICommentsPreview,
  IDocument,
  IMilestone,
  ServiceOrderStatusEnum,
  InspectionTypes,
  IProject,
  ServiceTypeEnum,
} from '@interfaces';
import { DateFieldModel, DropdownFieldModel, PhoneFieldModel, StringFieldModel } from '@models';

export interface IInspectionAgency {
  display_name: string;
  id?: string;
  service?: InspectionTypes;
  company?: string;
  inputValue?: string;
}
export interface IInspection {
  id: string;
  status: ServiceOrderStatusEnum;
  comment: string;
  draw_request: {
    id: string;
    number: string;
    status: string;
  };
  draw_request_totals: {
    requested_amount: number;
    revised_estimate: number;
  };
  inspection_agency: IInspectionAgency;
  order_id: string;
  scheduled_at: string;
  review_value: string;
  gc_requested_at: string;
  completed_at: string;
  ordered_at: string;
  gc_comment: string;
  document: string;
  reports: IDocument[];
  comments_preview: ICommentsPreview;
  milestones: IMilestone[];
  provider_status_display: string;
  provider_order_id: string;
  previous_inspector_allowance_rate: number;
  inspector_allowance: {
    rate: number;
    amount: number;
  };
  is_single_value: boolean;
  totals: {
    all: IInspectionTotal;
    active: IInspectionTotal;
    current: IInspectionTotal;
    budgeted: IInspectionTotal;
  };
  project: IProject;
  service_type: ServiceTypeEnum;
}

export interface IInspectionTotal {
  previous_inspector_allowance_rate: number;
  inspector_allowance_rate: number;
  requested_amount: number;
  revised_estimate: number;
  retainage_rate?: number;
  inspector_allowance?: number;
  approved_credit_amount?: number;
  requested_credit_amount?: number;
}

export interface ScheduleInspection {
  scheduled_at?: Date | string;
  gc_requested_at?: Date | string;
  review_value?: string;
  inspectionId?: string;
  comment?: string;
  gc_comment?: string;
  status?: string;
  projectId?: string;
  draw_request?: string;
}

export type UpdateInspectionPayload = {
  projectId: string;
  inspectionData: ScheduleInspection;
};

export type CommonInspectionPayload = {
  projectId: string;
  inspectionId: string;
};

export type CreateInspectionPayload = {
  projectId: string;
  inspectionData?: CreateInspection;
};

export type CreateAgencyPayload = {
  company: string;
  display_name: string;
  service?: InspectionTypes;
};

export interface CreateInspection {
  scheduled_at?: Date | string;
  gc_requested_at?: Date | string;
  comment?: string;
  gc_comment?: string;
  status?: string;
  projectId?: string;
  drawRequestId?: string;
  draw_request?: string;
  inspection_agency?: string;
}

export interface DeleteInspectionDocument {
  projectId: string;
  documentId: string;
  inspectionId: string;
}

export interface IInspectionFields {
  additionalContactName: StringFieldModel;
  primaryContactPhone: PhoneFieldModel;
  additionalContactPhone: PhoneFieldModel;
  accessCode: StringFieldModel;
  primaryContactUserNotRequired?: DropdownFieldModel;
  primaryContactUserList: EnumTypeForList[];
  isBorrowerUsersLoading: boolean;
  inspectionRequestedAt?: DateFieldModel;
  inspectionCadence?: StringFieldModel;
  isProjectInspectionSettingsUpdated?: boolean;
}

export enum InspectionActionTypeEnum {
  DELETE_INSPECTION_DOC = 'delete_inspection_doc',
  CANCEL_INSPECTION = 'cancel_inspection',
  DELETE_INSPECTION = 'delete_inspection',
}
