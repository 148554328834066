import { useContext, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { matchPath, useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  checkIsAdmin,
  checkIsPaymentManager,
  getActiveDrawRequestFromList,
  getDrawRequestForApproval,
  getFromListById,
  getHookState,
  getTeamRole,
  isAutomatedInspection,
  isChangeRequest,
  isCustomer,
  isDrawRequest,
  isOrderedService,
  isRequestDraft,
  isRequestHistorical,
  isRestricted,
} from '@utils';
import { AuthContext, PermissionsContext, useLaunchDarklyFlags } from '@context';
import {
  DocumentTabEnums,
  IDrawRequest,
  IInspection,
  PermissionNamesEnums,
  PoliciesTypeEnum,
  QueryNamesEnums,
} from '@interfaces';
import {
  getDrawRequest,
  getProjectDrawRequestsList,
  getProjectInspectionsList,
  getWaitingYourApproval,
} from '@globalService';
import { useCommentsPreview } from '@hooks';
import memoize from 'lodash/memoize';
import { ControllerInterface } from './interface';
import { PoliciesTypeLabel } from '@constants';
import snakeCase from 'lodash/snakeCase';

const TABS = {
  OVERVIEW: { label: 'Overview', value: 'overview' },
  BUDGET: { label: 'Budget', value: 'budget' },
  DRAWS: { label: 'Requests', value: 'draws' },
  DOCUMENTS: { label: 'Documents', value: 'documents' },
  INSPECTIONS: { label: 'Inspections', value: 'inspections' },
  PAYMENTS: { label: 'Payments', value: 'payments' },
  PHOTOS: { label: 'Photos', value: 'photos' },
  POLICIES: { label: 'Policies', value: 'policies' },
};

const firstTabValueMap = {
  documents: 'All',
  payments: 'Project',
  inspections: 'All',
  photos: 'All',
  draws: 'Budget',
};

const getFirstTabText = (tab: string) => firstTabValueMap[tab];
const getDrawRequestInProgress = memoize(getActiveDrawRequestFromList);
const getDrawLabel = (draw: IDrawRequest, DRList: IDrawRequest[]) => {
  const label = isDrawRequest(draw) ? 'Draw' : 'Change';
  const prefix =
    getDrawRequestInProgress(DRList)?.id == draw.id
      ? `Active ${label}`
      : isRequestDraft(draw.status)
        ? draw.is_resubmit
          ? `Returned ${label}`
          : `Draft ${label}`
        : label;
  return `${prefix} #${draw.counter_per_request_type}`;
};

const getDataTestNameLabel = (draw: IDrawRequest) => {
  const type = isDrawRequest(draw) ? 'draw' : 'change';
  return `${type}__${draw.counter_per_request_type}`;
};

export const useProject = (projectId: string): ControllerInterface => {
  const { permissions } = useContext(PermissionsContext);
  const flags = useLaunchDarklyFlags();

  const { updateCommentsPreviewInfo } = useCommentsPreview({ projectId });

  const drawRequestsQuery = useQuery<{ results: IDrawRequest[] }, Error>(
    [QueryNamesEnums.GET_PROJECT_DRAW_REQUEST_LIST, { projectId }],
    getProjectDrawRequestsList.bind(this, projectId),
  );

  const drawRequestInProgress = useMemo(
    () =>
      drawRequestsQuery.data?.results?.length === 1
        ? drawRequestsQuery.data?.results[0]
        : getActiveDrawRequestFromList(drawRequestsQuery.data),
    [drawRequestsQuery.data],
  );

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const match = matchPath('/projects/:projectId/:tab/*', pathname);
  const isInspectionsTab = matchPath('/projects/:projectId/inspections/*', pathname);
  const isPaymentTab = matchPath('/projects/:projectId/payments/*', pathname);
  const isPoliciesTab = matchPath('/projects/:projectId/policies/*', pathname);
  const isDocumentsTab = matchPath('/projects/:projectId/documents/*', pathname);
  const isPhotosTab = matchPath('/projects/:projectId/photos/*', pathname);
  const isDrawsTab = matchPath('/projects/:projectId/draws/*', pathname);
  const [activeDrawRequestId, setactiveDrawRequestId] = useState<string | null>(null);
  const [activeDrTab, setActiveDrTab] = useState<string>(null);
  const activeDrawRequestIdMatch = match?.params['*'].split('/')[1];
  useEffect(() => {
    // use the next order to show active tab
    // DR from url -> DR in progress -> if no last completed DR (not DRAFT) -> if no DRAFT DR
    const lastSubmittedRequest = isRequestDraft(drawRequestsQuery.data?.results?.[0]?.status)
      ? drawRequestsQuery.data?.results?.[1]?.id
      : drawRequestsQuery.data?.results?.[0]?.id || null;
    const id = activeDrawRequestIdMatch || drawRequestInProgress?.id || lastSubmittedRequest;

    // check if activeDrawRequestIdMatch is Change and it's payment tab -> go
    setactiveDrawRequestId(id);
    setActiveDrTab(activeDrawRequestIdMatch || match?.params['*'].split('/')[0] || 'all');
  }, [drawRequestsQuery.data, match, activeDrawRequestIdMatch]);

  const tabLinkPath = useMemo(
    () => (tab: string) => {
      const pathBase = `/projects/${projectId}`;
      const tabPathname = match?.params['*'].split('/')[0];
      const activeDrIsChange = isChangeRequest(
        getFromListById(drawRequestsQuery.data?.results, activeDrawRequestId),
      );
      if (activeDrIsChange && [TABS.INSPECTIONS.value, TABS.PAYMENTS.value].includes(tab)) {
        return `${pathBase}/${tab}/all`;
      }

      if (activeDrawRequestIdMatch) {
        return `${pathBase}/${tab}/draw-requests/${activeDrawRequestIdMatch}`;
      }

      if (tabPathname === DocumentTabEnums.ALL && tab !== TABS.DRAWS.value) {
        return `${pathBase}/${tab}/all`;
      }

      if (!drawRequestInProgress?.id && tab === TABS.POLICIES.value) {
        return `${pathBase}/${tab}/${PoliciesTypeEnum.FUTURE_DRAWS}`;
      }

      return !activeDrawRequestId
        ? `${pathBase}/${tab}/all`
        : `${pathBase}/${tab}/draw-requests/${activeDrawRequestId}`;
    },
    [
      activeDrTab,
      activeDrawRequestId,
      drawRequestsQuery.data,
      drawRequestInProgress?.id,
      activeDrawRequestIdMatch,
    ],
  );

  const handleActiveDRTabChange = (value: string) => {
    const isDrTab = ![
      DocumentTabEnums.ALL,
      DocumentTabEnums.PROJECT,
      PoliciesTypeEnum.PROJECT,
      PoliciesTypeEnum.FIRST_DRAW,
      PoliciesTypeEnum.FUTURE_CHANGES,
      PoliciesTypeEnum.FUTURE_DRAWS,
    ].includes(value as DocumentTabEnums | PoliciesTypeEnum);
    const path = isDrTab
      ? `/projects/${match.params.projectId}/${match.params.tab}/draw-requests/${value}`
      : `/projects/${match.params.projectId}/${match.params.tab}/${value}`;
    navigate(path);
  };

  const showPoliciesTab = useMemo(
    () =>
      !isRestricted(PermissionNamesEnums.POLICIES_MY_TEAM_ROLE_VIEW, permissions) ||
      !isRestricted(PermissionNamesEnums.POLICIES_VIEW, permissions),
    [permissions],
  );

  const DRSwitcherTabs = useMemo(() => {
    const DRList = drawRequestsQuery.data?.results || [];
    const firstTabLabel = isDrawsTab ? null : getFirstTabText(match?.params?.tab);
    const tabPathname = match?.params['*'].split('/')[0];
    const showFirstDrawPoliciesTab = !DRList.some((draw) => isDrawRequest(draw));

    return [
      ...(isPoliciesTab
        ? [
            {
              label: PoliciesTypeLabel.CHANGE_REQUEST,
              value: PoliciesTypeEnum.FUTURE_CHANGES,
              isActive: tabPathname === PoliciesTypeEnum.FUTURE_CHANGES,
              dataTestName: snakeCase(PoliciesTypeLabel.CHANGE_REQUEST),
            },
            {
              label: PoliciesTypeLabel.DRAW_REQUEST,
              value: PoliciesTypeEnum.FUTURE_DRAWS,
              isActive: tabPathname === PoliciesTypeEnum.FUTURE_DRAWS,
              dataTestName: snakeCase(PoliciesTypeLabel.DRAW_REQUEST),
            },
          ]
        : []),
      // show the first draw tab only if no draws have been created
      ...(isPoliciesTab && showFirstDrawPoliciesTab
        ? [
            {
              label: PoliciesTypeLabel.FIRST_DRAW_REQUEST,
              value: PoliciesTypeEnum.FIRST_DRAW,
              isActive: tabPathname === PoliciesTypeEnum.FIRST_DRAW,
              dataTestName: snakeCase(PoliciesTypeLabel.FIRST_DRAW_REQUEST),
            },
          ]
        : []),
      ...(firstTabLabel
        ? [
            {
              label: firstTabLabel,
              value: 'all',
              isActive: !activeDrawRequestId,
              dataTestName: `project__tab_switcher__${firstTabLabel.toLowerCase()}`,
            },
          ]
        : []),
      ...(isDocumentsTab || isPhotosTab
        ? [{ label: 'Project', value: DocumentTabEnums.PROJECT, isActive: true }]
        : []),
      ...DRList.filter((draw) =>
        isChangeRequest(draw) ? !isInspectionsTab && !isPaymentTab : true,
      ).map((draw) => ({
        label: getDrawLabel(draw, DRList),
        value: draw.id,
        isActive: draw.id == activeDrawRequestId,
        dataTestName: getDataTestNameLabel(draw),
      })),
      ...(isPoliciesTab &&
      (!isRestricted(PermissionNamesEnums.POLICIES_VIEW, permissions) ||
        (flags?.['ENG_8535_project_activation_checklist_for_borrower'] && showPoliciesTab))
        ? [
            {
              label: PoliciesTypeLabel.PROJECT,
              value: PoliciesTypeEnum.PROJECT,
              isActive: !activeDrawRequestId,
              dataTestName: snakeCase(PoliciesTypeLabel.PROJECT),
            },
          ]
        : []),
    ];
  }, [drawRequestsQuery.data, activeDrawRequestId, pathname, permissions, flags]);

  const showInspectionsTab = useMemo(
    () => !isRestricted(PermissionNamesEnums.INSPECTIONS_VIEW, permissions),
    [permissions],
  );

  const isActiveRequestHistorical = useMemo(
    () =>
      activeDrawRequestIdMatch === activeDrawRequestId &&
      isRequestHistorical(
        getFromListById(drawRequestsQuery.data?.results, activeDrawRequestId)?.source,
      ),
    [drawRequestsQuery.data?.results, activeDrawRequestId, activeDrawRequestIdMatch],
  );

  return {
    TABS,
    state: getHookState(drawRequestsQuery),
    activeDrawRequestId,
    activeDrTab,
    handleActiveDRTabChange,
    DRSwitcherTabs,
    updateCommentsPreviewInfo,
    showInspectionsTab,
    showPoliciesTab,
    tabLinkPath,
    isActiveRequestHistorical,
  };
};

export const useGetActionTabs = () => {
  const { projectId } = useParams();
  const { user } = useContext(AuthContext);
  const teamRole = getTeamRole(user);
  const { permissions } = useContext(PermissionsContext);

  const drawRequestsQuery = useQuery<{ results: IDrawRequest[] }, Error>(
    [QueryNamesEnums.GET_DRAW_REQUEST_FOR_APPROVAL, { projectId }],
    getWaitingYourApproval.bind(this, projectId),
  );
  const drawRequestForApproval = useMemo(
    () => getDrawRequestForApproval(drawRequestsQuery.data?.results),
    [drawRequestsQuery.data],
  );

  const drawRequestData = useQuery<IDrawRequest, Error>(
    [QueryNamesEnums.GET_DRAW_REQUEST, { projectId, drawRequestId: drawRequestForApproval?.id }],
    getDrawRequest.bind(this, { projectId, drawRequestId: drawRequestForApproval?.id }),
    { enabled: Boolean(drawRequestForApproval && projectId) },
  );

  const { data: { results: projectInspections } = {} } = useQuery<
    { results: IInspection[]; count: number },
    Error
  >(
    [QueryNamesEnums.GET_PROJECT_INSPECTIONS, { projectId }],
    getProjectInspectionsList.bind(this, { projectId }),
    { enabled: !isRestricted(PermissionNamesEnums.INSPECTIONS_VIEW, permissions) },
  );

  const activeInspection = useMemo(
    () =>
      projectInspections?.find(
        ({ status, inspection_agency }) =>
          isOrderedService(status) && !isAutomatedInspection(inspection_agency?.service),
      ),
    [projectInspections],
  );

  return {
    // if no approved_amount -> Customer should enter numbers
    Requests:
      drawRequestForApproval &&
      !drawRequestData.data?.totals?.all?.approved_amount &&
      isCustomer(user),
    Inspections: activeInspection && (checkIsAdmin(teamRole) || isCustomer(user)),
    Payments: drawRequestForApproval && checkIsPaymentManager(teamRole),
  };
};
