import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import cloneDeep from 'lodash/cloneDeep';
import {
  IDrawRequest,
  IFilterOption,
  IMilestone,
  IMilestoneTotal,
  IPHBTableItem,
  IRightMenu,
  LineItemFilterEnum,
  TableKeyEnum,
} from '@interfaces';
import { useParams } from 'react-router-dom';
import {
  autofillCanBeShown,
  canDeleteRequest,
  checkIsCreator,
  checkIsCustomerSuccess,
  checkIsOwner,
  checkIsTableEdit,
  getItemLocalHighlight,
  getPHBViewTypes,
  getTeamRole,
  getTypeFilter,
  isReallocationEnabled,
  isRequestInReview,
  sortByUnitName,
  useExpandCollapseTable,
  useLoadingSkeleton,
  usePHBFilters,
  usePHBGrouping,
  usePHBNaming,
} from '@utils';
import { useCommentsPreview, useRightMenu } from '@hooks';
import { AuthContext, useLaunchDarklyFlags } from '@context';
import { getInitColumns } from './getColumns';
import { useGetQueries } from './queries';
import { LineItemFilterValues } from '@constants';

export type RequestTableControllerInterface = {
  initColumns: string[];
  units: IPHBTableItem[];
  onExpandClick: (id: string, isExpanded: boolean) => void;
  filterOptions: IFilterOption[];
  filterValue: string;
  handleShowFilterClick: (value: string) => void;
  patchMsGroup: (params) => void;
  patchLineItem: (params) => void;
  isLoading: boolean;
  canDeleteRequest: boolean;
  showDeleteModal: boolean;
  setShowDeleteModal: Dispatch<SetStateAction<boolean>>;
  deleteRequest: () => Promise<Response>;
  isDeleting: boolean;
  showAutofillButton: boolean;
  drawRequest: IDrawRequest;
  handleAutofillLenderAllowance: (autofillValue: string) => void;
  isAutofillLoading: boolean;
  totals?: IMilestoneTotal;
  groupByKeys: string;
  updateRightDrawer: () => () => void;
  rightMenu: IRightMenu;
  onMilestoneUpdate: (data: IMilestone) => void;
  activeView: string;
  setActiveView: Dispatch<SetStateAction<string>>;
  filteredMilestones: IMilestone[];
  isLineItemsView: boolean;
  viewTypes: { label: string; value: string }[];
  handleUnitsFilterClick: (value: string[]) => void;
  unitsFilterValues: string[];
};

export const useRequestTable = (isSubmissionProcess = false): RequestTableControllerInterface => {
  const { projectId, requestId: drawRequestId, action } = useParams();
  const { user } = useContext(AuthContext);
  const teamRole = getTeamRole(user);
  const isEditTable = useMemo(() => checkIsTableEdit(action), [action]);
  const flags = useLaunchDarklyFlags();

  const [listItems, setListItems] = useState<IPHBTableItem[]>([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { showLoadingSkeleton, showTemporaryLoadingSkeleton } = useLoadingSkeleton();
  const { unitName } = usePHBNaming();

  const viewTypes = useMemo(() => getPHBViewTypes(unitName), [unitName]);
  const [activeView, setActiveView] = useState<string>(viewTypes[0].value);
  const [unitsFilterValues, setUnitsFilterValues] = useState<string[]>([]);

  const handleUnitsFilterClick = (value: string[]) => {
    setUnitsFilterValues(value);
  };

  const { updateCommentsPreviewInfo } = useCommentsPreview({
    projectId,
    drawRequestId,
  });
  const { handleRightDrawerOpenerClick, ...rightMenu } = useRightMenu({
    onClose: updateCommentsPreviewInfo,
  });
  const updateRightDrawer = () => () => {
    handleRightDrawerOpenerClick({ title: 'Comments' });
  };

  const createTableObject = ({
    item,
    isExpanded = false,
    isNested = false,
    index,
  }: {
    item: IMilestone;
    isExpanded?: boolean;
    isNested?: boolean;
    index: number | string;
  }): IPHBTableItem => ({
    ...item,
    activeToEdit,
    localNew: false,
    localHighlight: getItemLocalHighlight(item),
    canBeExpanded: item?.milestone_groups?.length > 0,
    localIsUserCreator: checkIsCreator(drawRequest, teamRole),
    isExpanded,
    isNested,
    index,
    project_milestone: { ...item?.project_milestone, index },
  });

  const { onExpandClick, updateListItemsWithParentGroup, updateListItemsWithMsList } =
    useExpandCollapseTable({
      setListItems,
      createTableObject,
    });

  const { filterValue, handleFilterClick, filterOptions, filterTotalKey, filterKey } =
    usePHBFilters({
      tableKey: TableKeyEnum.PHB_REQUEST_LINE_ITEMS,
      isNewView: true,
    });

  const {
    requestedDataQueries,
    deleteDrawRequestMutation,
    requestMilestonesQuery,
    filteredMilestonesQuery,
    bulkMilestoneMutation,
    onMilestoneUpdate,
    patchDrawRequestProdBuildGroupMutation,
    patchDrawRequestProdBuildMilestoneMutation,
  } = useGetQueries({
    filterTotalKey,
    filterKey,
    updateListItemsWithMsList,
    isSubmissionProcess,
    updateListItemsWithParentGroup,
    typeFilterValue: getTypeFilter(!!unitsFilterValues?.length).filterKey,
    unitsFilterValue: unitsFilterValues.join(),
    setListItems,
  });
  const { unitLineItemGrouping } = usePHBGrouping();

  const project = requestedDataQueries[0].data;
  const drawRequest = requestedDataQueries[1].data;
  const requestMilestones = requestMilestonesQuery.data;

  const retainageRate = useMemo(() => project?.retainage_rate, [project]);

  const isReallocationAllowed = useMemo(
    () => isReallocationEnabled(drawRequest, project),
    [drawRequest, project],
  );

  const initColumns = useMemo(
    () =>
      getInitColumns({
        isSubmissionProcess,
        isReallocationAllowed,
        drawRequest,
        retainageRate,
        flags,
      }),
    [isSubmissionProcess, retainageRate, isReallocationAllowed, drawRequest, project, flags],
  );

  const activeToEdit = useMemo(
    () =>
      (drawRequest?.waits_current_user_approval && !checkIsOwner(teamRole)) ||
      (checkIsCustomerSuccess(teamRole) && isRequestInReview(drawRequest?.status)) ||
      isEditTable,
    [drawRequest, teamRole, isEditTable],
  );

  useEffect(() => {
    setListItems(null);
  }, [filterValue, drawRequestId]);

  useEffect(() => {
    if (!requestMilestones?.results || listItems?.length || !drawRequest) return;
    const clonedMilestones = cloneDeep(requestMilestones.results);
    setListItems(
      clonedMilestones.map((item: IMilestone, index: number) => createTableObject({ item, index })),
    );
  }, [requestMilestones?.results, drawRequest, listItems]);

  const patchMsGroup = useCallback(
    (params) =>
      patchDrawRequestProdBuildGroupMutation.mutate({
        project: projectId,
        drawRequest: drawRequestId,
        group_by: unitLineItemGrouping,
        tags: params.milestoneTags,
        json: params.json,
        filterKey,
      }),
    [drawRequestId, projectId, unitLineItemGrouping, filterKey],
  );
  const patchLineItem = useCallback(
    (params) => {
      patchDrawRequestProdBuildMilestoneMutation.mutate({
        project: projectId,
        milestone: params.milestone,
        drawRequest: drawRequestId,
        json: params.json,
      });
    },
    [drawRequestId, projectId],
  );

  const deleteRequest = useCallback(
    () =>
      deleteDrawRequestMutation.mutateAsync({
        project: projectId,
        drawRequest: drawRequestId,
      }),
    [deleteDrawRequestMutation, projectId, drawRequestId],
  );

  const handleAutofillLenderAllowance = (autofillValue) => {
    const json = {
      autofill_key: autofillValue,
    };
    bulkMilestoneMutation.mutate({
      projectId,
      drawRequestId,
      json,
    });
  };

  const showAutofillButton = useMemo(
    () => autofillCanBeShown(drawRequest, teamRole),
    [drawRequest, teamRole],
  );

  const filteredMilestones = useMemo(
    () =>
      filteredMilestonesQuery.data?.results
        ?.sort(sortByUnitName)
        ?.map((item, index) =>
          createTableObject({ item: { ...item, milestone_groups: [] }, index }),
        ),
    [filteredMilestonesQuery.data?.results, activeToEdit],
  );

  const isLineItemsView = useMemo(() => activeView === viewTypes[1].value, [activeView]);

  return {
    initColumns,
    units: listItems,
    onExpandClick,
    filterOptions,
    filterValue,
    handleShowFilterClick: (value: string) => {
      handleFilterClick(value);
      showTemporaryLoadingSkeleton();
    },
    patchMsGroup,
    patchLineItem,
    isLoading:
      showLoadingSkeleton || requestMilestonesQuery.isLoading || requestMilestonesQuery.isIdle,
    canDeleteRequest: canDeleteRequest(drawRequest),
    showDeleteModal,
    setShowDeleteModal,
    deleteRequest,
    isDeleting: deleteDrawRequestMutation.isLoading,
    showAutofillButton,
    drawRequest,
    handleAutofillLenderAllowance,
    isAutofillLoading: bulkMilestoneMutation.isLoading,
    totals: drawRequest?.totals?.[LineItemFilterValues[LineItemFilterEnum.VERTICAL_COST].totalKey],
    groupByKeys: unitLineItemGrouping,
    updateRightDrawer,
    rightMenu,
    onMilestoneUpdate,
    activeView,
    setActiveView,
    filteredMilestones,
    isLineItemsView,
    viewTypes,
    handleUnitsFilterClick,
    unitsFilterValues,
  };
};
