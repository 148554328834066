import React from 'react';
import { Box, Stack, Typography } from '@mui/material';

import { CustomCurrencyTextField, CustomPercentTextField } from '@components';
import { calculateAmount, calculateFraction, roundToTwoDigits, currencyFormatter } from '@utils';
import { colors } from '@theme';
import { ILoanInfo } from '@interfaces';
import { StringFieldModel } from '@models';
import { useLaunchDarklyFlags } from '@context';

const PaymentConfigurationEdit = ({
  postFundingConstructionBudget,
  constructionHoldback,
  constructionHoldbackFraction,
  borrowerEquity,
  borrowerEquityFraction,
  source,
  budgetEditDisabled = false,
  totalAmountLabel = 'Construction budget post-funding ($)',
  isEditable = true,
  clearOnTouchPostFundingConstructionBudget = false,
  showBudgetValues = false,
  loan,
  isCreateProject = false,
}: {
  postFundingConstructionBudget: StringFieldModel;
  constructionHoldback: StringFieldModel;
  constructionHoldbackFraction: StringFieldModel;
  borrowerEquity: StringFieldModel;
  borrowerEquityFraction: StringFieldModel;
  source: string;
  budgetEditDisabled?: boolean;
  totalAmountLabel?: string;
  isEditable?: boolean;
  clearOnTouchPostFundingConstructionBudget?: boolean;
  showBudgetValues?: boolean;
  loan?: ILoanInfo;
  isCreateProject?: boolean;
}) => {
  const flags = useLaunchDarklyFlags();
  return (
    <Stack spacing={3}>
      <Stack direction="row" spacing={2}>
        <Stack sx={{ width: '100%' }}>
          <CustomCurrencyTextField
            label={totalAmountLabel}
            field={postFundingConstructionBudget}
            onChange={(e) => {
              postFundingConstructionBudget.setValue(e.target.value);

              constructionHoldback.setValue(
                calculateAmount(constructionHoldbackFraction.value, e.target.value).toString(),
              );
              borrowerEquity.setValue(
                calculateAmount(borrowerEquityFraction.value, e.target.value).toString(),
              );
            }}
            disabled={budgetEditDisabled || !isEditable}
            inputProps={{ 'data-cy': `${source}__construction_budget__input` }}
            required={!isCreateProject || !flags?.['ENG_8524_optional_fields_on_create_project']}
            validationText="Construction budget post-funding is required"
            clearOnTouch={clearOnTouchPostFundingConstructionBudget}
          />
          {showBudgetValues && (
            <Typography
              variant="label"
              sx={{ display: 'inline-block', color: colors.status.information.medium }}
            >
              Budget: Construction budget post-funding =
              {currencyFormatter(loan?.budget_post_funding_construction_budget)}
            </Typography>
          )}
        </Stack>
        <Stack sx={{ width: '100%' }}>
          <CustomCurrencyTextField
            label="Construction holdback ($)"
            field={constructionHoldback}
            onChange={(e) => {
              constructionHoldback.setValue(e.target.value);
              if (+postFundingConstructionBudget.value) {
                borrowerEquity.setValue(
                  roundToTwoDigits(
                    +postFundingConstructionBudget.value - +e.target.value,
                  )?.toString(),
                );
                const contributionFraction = calculateFraction(
                  e.target.value,
                  postFundingConstructionBudget.value,
                );
                constructionHoldbackFraction.setValue(contributionFraction?.toString());
                borrowerEquityFraction.setValue(
                  calculateFraction(
                    roundToTwoDigits(+postFundingConstructionBudget.value - +e.target.value),
                    postFundingConstructionBudget.value,
                  )?.toString(),
                );
              }
            }}
            inputProps={{ 'data-cy': `${source}__construction_holdback__input` }}
            disabled={!+postFundingConstructionBudget.value || !isEditable}
            validationText={constructionHoldback.errorTip}
          />
          {showBudgetValues && (
            <Typography
              variant="label"
              sx={{ display: 'inline-block', color: colors.status.information.medium }}
            >
              Budget: Construction holdback ={currencyFormatter(loan?.budget_construction_holdback)}
            </Typography>
          )}
        </Stack>
        <Stack sx={{ width: '100%' }}>
          <CustomCurrencyTextField
            label="Borrower equity ($)"
            field={borrowerEquity}
            onChange={(e) => {
              borrowerEquity.setValue(e.target.value);
              if (+postFundingConstructionBudget.value) {
                constructionHoldback.setValue(
                  roundToTwoDigits(
                    +postFundingConstructionBudget.value - +e.target.value,
                  )?.toString(),
                );
                const borrowerFraction = calculateFraction(
                  e.target.value,
                  postFundingConstructionBudget.value,
                );
                borrowerEquityFraction.setValue(borrowerFraction?.toString());
                constructionHoldbackFraction.setValue(
                  calculateFraction(
                    roundToTwoDigits(+postFundingConstructionBudget.value - +e.target.value),
                    postFundingConstructionBudget.value,
                  )?.toString(),
                );
              }
            }}
            inputProps={{ 'data-cy': `${source}__borrower_equity__input` }}
            disabled={!+postFundingConstructionBudget.value || !isEditable}
            validationText={borrowerEquity.errorTip}
          />
          {showBudgetValues && (
            <Typography
              variant="label"
              sx={{ display: 'inline-block', color: colors.status.information.medium }}
            >
              Budget: Borrower equity ={currencyFormatter(loan?.budget_borrower_equity)}
            </Typography>
          )}
        </Stack>
      </Stack>
      <Stack direction="row" spacing={2}>
        <Box width="100%" />
        <CustomPercentTextField
          label="Construction holdback (%)"
          field={constructionHoldbackFraction}
          onChange={(e) => {
            constructionHoldbackFraction.setValue(e.target.value);
            borrowerEquityFraction.setValue((100 - +e.target.value)?.toString());
            if (+postFundingConstructionBudget.value) {
              const constructionAmount = calculateAmount(
                e.target.value,
                postFundingConstructionBudget.value,
              );
              borrowerEquity.setValue(
                roundToTwoDigits(
                  +postFundingConstructionBudget.value - constructionAmount,
                )?.toString(),
              );
              constructionHoldback.setValue(constructionAmount?.toString());
            }
          }}
          inputProps={{ 'data-cy': `${source}__construction_holdback_fraction__input` }}
          disabled={!+postFundingConstructionBudget.value || !isEditable}
          validationText={constructionHoldbackFraction.errorTip}
        />
        <CustomPercentTextField
          field={borrowerEquityFraction}
          label="Borrower equity (%)"
          onChange={(e) => {
            borrowerEquityFraction.setValue(e.target.value);
            constructionHoldbackFraction.setValue((100 - +e.target.value)?.toString());
            if (+postFundingConstructionBudget.value) {
              const borrowerAmount = calculateAmount(
                e.target.value,
                postFundingConstructionBudget.value,
              );
              constructionHoldback.setValue(
                roundToTwoDigits(+postFundingConstructionBudget.value - borrowerAmount)?.toString(),
              );
              borrowerEquity.setValue(borrowerAmount?.toString());
            }
          }}
          inputProps={{ 'data-cy': `${source}__borrower_equity_fraction__input` }}
          disabled={!+postFundingConstructionBudget.value || !isEditable}
          validationText={borrowerEquityFraction.errorTip}
        />
      </Stack>
    </Stack>
  );
};

export default PaymentConfigurationEdit;
