import React, { createContext, FC, useMemo } from 'react';
import { Table, TableBody, TableRow, TableCell } from '@mui/material';
import MilestoneColumns from '../MilestoneList/columns';
import { GeneralRowTablInterface } from './interface';
import { LineItemRightDrawer, MessagePanel, RightDrawer } from '@components';
import { MessagePanelTabsEnum } from '@interfaces';
import { useLaunchDarklyFlags } from '@context';

export const GeneralRowContext = createContext<{ refetch?: () => void }>({});

const GeneralRowTable: FC<GeneralRowTablInterface> = ({
  canAddPhotos,
  photos,
  refetch,
  rightMenu,
  rightDrawerParams,
  updateRightDrawer,
  comments_preview,
}) => {
  const tableContext = useMemo(
    () => ({
      ...(refetch ? { refetch } : {}),
    }),
    [refetch],
  );
  const flags = useLaunchDarklyFlags();

  return (
    <GeneralRowContext.Provider value={tableContext}>
      <Table
        sx={{
          mt: 1,
        }}
      >
        <TableBody>
          <TableRow sx={{ paddingX: '16px' }}>
            {[
              'name',
              ...(flags?.['ENG_8812_photos_right_panel_on_inspections']
                ? ['inspectionPhotoUploader', 'inspectionPhotosV2']
                : ['inspectionPhotos']),
              'comments',
            ]
              .map((item) => MilestoneColumns[item])
              .map((column) => (
                <TableCell
                  key={column.name}
                  sx={{
                    padding: '0',
                    width: '100%',
                    '& first-of-type': { width: '100%' },
                    ':first-of-type': {
                      paddingLeft: '16px',
                    },
                    ':last-child': {
                      paddingRight: '16px',
                    },
                  }}
                >
                  {column.renderCell({
                    row: {
                      name: 'General photos (not tagged to a line item)',
                      canAddPhotos,
                      inspectionPhotos: photos,
                      refetch,
                      updateRightDrawer,
                      comments_preview,
                      generalRowDataSource: 'inspections__result__general_row',
                    },
                  })}
                </TableCell>
              ))}
          </TableRow>
        </TableBody>
      </Table>
      <RightDrawer {...rightMenu}>
        {flags?.['ENG_8812_photos_right_panel_on_inspections'] ? (
          <LineItemRightDrawer
            setOpen={rightMenu.setOpen}
            rightDrawerParams={{ ...rightDrawerParams, tab: rightDrawerParams.tab }}
            source="inspections__result__general_row__right_drawer"
          />
        ) : (
          <MessagePanel
            {...rightDrawerParams}
            source="inspections__result__general_row__comments"
            tab={MessagePanelTabsEnum.SERVICES}
            showTabs={false}
          />
        )}
      </RightDrawer>
    </GeneralRowContext.Provider>
  );
};

export default GeneralRowTable;
