import React, { FC, useContext, useEffect, useMemo } from 'react';
import { Box, MenuItem, OutlinedInput, Select, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

import { ColumnLabel, CommonRowType, HeaderText, MilestoneListColumnType } from './common';
import { TableContext } from '../controller';
import { PermissionsContext, SettingsContext } from '@context';
import { getMilestoneGroupsTagsIds, isRestricted } from '@utils';
import { useStringFieldModel } from '@models';
import { costTypeMap } from '@constants';
import { ColumnAlign, ColumnV2Width, PermissionNamesEnums } from '@interfaces';

const LineItemCostType: FC<{ row: CommonRowType }> = ({ row }) => {
  const { permissions } = useContext(PermissionsContext);
  const { isCurrentProjectActive, isCurrentProjectArchived } = useContext(SettingsContext);
  const { apiUpdate, getLineItemError } = useContext(TableContext);
  // edit can be done only for budget so no requestId
  const { requestId } = useParams();
  const error = getLineItemError(row.id, 'cost_type');
  const tags = useMemo(() => getMilestoneGroupsTagsIds(row.tags), [row.tags]);

  const cost_type = useStringFieldModel({
    initError: error,
    initValue: (row.cost_type?.['key'] || 'HARD').toString(),
  });

  const isEditable = useMemo(
    () =>
      !row.disabled?.value &&
      !(row?.draw_requests?.length && row.retainage_rate) &&
      row.activeToEdit &&
      row.localNew &&
      // budget can be edited only for created project
      !requestId &&
      !isRestricted(PermissionNamesEnums.DRAWREQUESTS_LINE_ITEM_CREATE, permissions) &&
      !isCurrentProjectActive &&
      !isCurrentProjectArchived,
    [row, permissions, requestId],
  );

  useEffect(() => {
    cost_type.setValue(row.cost_type?.['key'] || 'HARD');
  }, [row.cost_type?.['key']]);

  useEffect(() => {
    if (row.cost_type?.['key'] !== cost_type.value) {
      apiUpdate?.({
        milestoneId: row.project_milestone?.id || row.id,
        name: 'cost_type',
        value: cost_type.value,
        needToCompare: false,
        isNonNumber: true,
        ...(row.tags && { milestoneTags: tags }),
      });
    }
  }, [cost_type?.value]);

  return isEditable ? (
    <Box flex={1} textAlign={ColumnAlign.TEXT} sx={{ maxWidth: '100%' }}>
      <Select
        size="small"
        sx={{ width: '100%', maxWidth: 300 }}
        value={cost_type.value}
        onChange={(event) => cost_type.setValue(event.target.value)}
        data-cy={`milestone_row_cost_type_index_${row.index}`}
        input={
          <OutlinedInput
            sx={{
              '& .MuiInputBase-input': {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              },
            }}
          />
        }
        renderValue={(selected) => costTypeMap[selected]}
      >
        {Object.keys(costTypeMap).map((type, index) => (
          <MenuItem
            value={type}
            key={type}
            data-cy={`milestone_row_cost_type_index_${row.index}_select_item_${index}`}
          >
            {costTypeMap[type]}
          </MenuItem>
        ))}
      </Select>
    </Box>
  ) : (
    <Typography
      align="left"
      component="div"
      variant="body3"
      data-cy={`milestone_row_cost_type_index_${row.index}`}
    >
      {row.cost_type?.['display'] || ''}
    </Typography>
  );
};

const costType: MilestoneListColumnType = {
  name: 'cost_type',
  columnText: 'Cost type',
  isEditable: (permissions) =>
    !isRestricted(PermissionNamesEnums.DRAWREQUESTS_LINE_ITEM_CREATE, permissions),
  justifyContent: 'flex-start',
  renderCell: ({ row }) => <LineItemCostType row={row} />,
  minWidth: (activeToEdit = false) =>
    activeToEdit ? ColumnV2Width.SELECT_MIN : ColumnV2Width.NUMBER_MIN,
  maxWidth: (activeToEdit = false) => (activeToEdit ? ColumnV2Width.INPUT : ColumnV2Width.TEXT_MAX),
  Header: (
    <HeaderText
      alignItems="flex-start"
      justifyContent="center"
      tooltipText="Type of construction cost"
    >
      <ColumnLabel>Cost type</ColumnLabel>
    </HeaderText>
  ),
};

export default costType;
