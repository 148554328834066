// TODO delete after ENG_5946_show_old_inspections

import React, { useContext } from 'react';
import { Box, Skeleton, Stack, Tab, Tabs } from '@mui/material';
import { Link, matchPath, Navigate, Route, Routes, useLocation, useParams } from 'react-router-dom';

import {
  Budget,
  MessagePanel,
  RightDrawer,
  StyledBox,
  TabSwitcher,
  ToolTipLine,
} from '@components';
import { colors } from '@theme';
import PaymentsTab from './PaymentsTab';
import {
  Documents,
  DrawsTab,
  Inspections,
  OverviewTab,
  Photos,
  Policies,
  ProjectMainInfo,
} from './components';
import { DocumentTabEnums, HookState } from '@interfaces';
import { isMobileOnly } from 'react-device-detect';
import { useRightMenu } from '@hooks';
import { DotIcon } from '@svgAsComponents';
import { useGetActionTabs, useProject } from './controller';
import { ProductionBuildBudget } from '../productionBuild';
import { SettingsContext } from '@context';

const Project = () => {
  const { isPHBProject } = useContext(SettingsContext);
  const { projectId } = useParams();
  const {
    TABS,
    DRSwitcherTabs,
    activeDrawRequestId,
    activeDrTab,
    handleActiveDRTabChange,
    state,
    updateCommentsPreviewInfo,
    showInspectionsTab,
    showPoliciesTab,
    tabLinkPath,
    isActiveRequestHistorical,
  } = useProject(projectId);
  const { pathname } = useLocation();
  const match = matchPath('/projects/:projectId/:tab/*', pathname);
  const pathBase = `/projects/${projectId}`;
  const { handleRightDrawerOpenerClick, ...rightMenu } = useRightMenu({
    onClose: updateCommentsPreviewInfo,
  });
  const tabCircle = useGetActionTabs();

  // !include here the tabs where you want to see DR switcher
  const showDRSwitcher =
    [
      TABS.DOCUMENTS.value,
      TABS.DRAWS.value,
      TABS.INSPECTIONS.value,
      TABS.PAYMENTS.value,
      TABS.PHOTOS.value,
      TABS.POLICIES.value,
    ].includes(match?.params?.tab) &&
    Boolean(DRSwitcherTabs.length) &&
    !isMobileOnly;

  return match?.params?.tab ? (
    <Box sx={{ display: 'flex', flexFlow: 'column nowrap', height: '100%' }}>
      <ProjectMainInfo handleRightDrawerOpenerClick={handleRightDrawerOpenerClick} />

      {!isMobileOnly && (
        <Box
          sx={{
            mt: '.5rem',
            position: 'relative',
            '& .MuiTabs-root': { height: '60px' },
          }}
        >
          <Tabs
            value={match?.params?.tab || 'overview'}
            TabIndicatorProps={{
              style: { transition: 'none' },
              children: <span />,
            }}
          >
            <Tab
              data-cy={`project_tab_${TABS.OVERVIEW.value}`}
              label={TABS.OVERVIEW.label}
              value={TABS.OVERVIEW.value}
              component={Link}
              to={`${pathBase}/${TABS.OVERVIEW.value}`}
              sx={{ ml: 2 }}
            />
            <Tab
              data-cy={`project_tab_${TABS.BUDGET.value}`}
              label={TABS.BUDGET.label}
              value={TABS.BUDGET.value}
              component={Link}
              to={`${pathBase}/${TABS.BUDGET.value}`}
              sx={{ ml: 2 }}
            />
            <Tab
              data-cy={`project_tab_${TABS.DRAWS.value}`}
              label={TABS.DRAWS.label}
              value={TABS.DRAWS.value}
              component={Link}
              to={tabLinkPath(TABS.DRAWS.value)}
              icon={<ActionIcon showIcon={tabCircle[TABS.DRAWS.label]} />}
              iconPosition="end"
              data-tour="requests_tab"
            />
            <Tab
              data-cy={`project_tab_${TABS.DOCUMENTS.value}`}
              label={TABS.DOCUMENTS.label}
              value={TABS.DOCUMENTS.value}
              component={Link}
              to={tabLinkPath(TABS.DOCUMENTS.value)}
            />
            {showInspectionsTab && (
              <Tab
                data-cy={`project_tab_${TABS.INSPECTIONS.value}`}
                label={TABS.INSPECTIONS.label}
                value={TABS.INSPECTIONS.value}
                component={Link}
                to={tabLinkPath(TABS.INSPECTIONS.value)}
                icon={<ActionIcon showIcon={tabCircle[TABS.INSPECTIONS.label]} />}
                iconPosition="end"
              />
            )}
            <Tab
              data-cy={`project_tab_${TABS.PHOTOS.value}`}
              label={TABS.PHOTOS.label}
              value={TABS.PHOTOS.value}
              component={Link}
              to={tabLinkPath(TABS.PHOTOS.value)}
            />
            <Tab
              data-cy={`project_tab_${TABS.PAYMENTS.value}`}
              label={TABS.PAYMENTS.label}
              value={TABS.PAYMENTS.value}
              component={Link}
              to={tabLinkPath(TABS.PAYMENTS.value)}
              icon={<ActionIcon showIcon={tabCircle[TABS.PAYMENTS.label]} />}
              iconPosition="end"
            />
            {showPoliciesTab && (
              <Tab
                data-cy={`project_tab_${TABS.POLICIES.value}`}
                label={TABS.POLICIES.label}
                value={TABS.POLICIES.value}
                component={Link}
                to={tabLinkPath(TABS.POLICIES.value)}
                data-tour="policy_tab"
              />
            )}
          </Tabs>
        </Box>
      )}
      <Stack sx={{ flex: 1, backgroundColor: colors.background.gray, p: 2 }}>
        {showDRSwitcher &&
          (state === HookState.SUCCESS ? (
            <>
              <StyledBox sx={{ mb: 2, p: 0 }}>
                <TabSwitcher
                  tabs={DRSwitcherTabs}
                  onTabChange={handleActiveDRTabChange}
                  activeTab={activeDrTab}
                  source="project__tab_switcher"
                />
              </StyledBox>
              {isActiveRequestHistorical && (
                <Box mb={2}>
                  <ToolTipLine
                    text="This is the historical request and may have limited data"
                    typographyVariant="body2"
                    fullWidth
                    dataTestName="draw_historical_text"
                  />
                </Box>
              )}
            </>
          ) : (
            <Skeleton variant="rectangular" width="100%" height={72} sx={{ mb: 2 }} />
          ))}
        <Routes>
          <Route path={`${TABS.OVERVIEW.value}`} element={<OverviewTab />} />
          <Route
            path={`${TABS.BUDGET.value}`}
            element={isPHBProject ? <ProductionBuildBudget /> : <Budget />}
          />
          <Route
            path={`${TABS.DRAWS.value}/*`}
            element={<DrawsTab drawRequest={activeDrawRequestId} />}
          />
          <Route
            path={`${TABS.DOCUMENTS.value}/*`}
            element={
              <StyledBox sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, px: 0 }}>
                <Documents
                  source="documents_tab__documents"
                  isAllDocumentsTab={activeDrTab === DocumentTabEnums.ALL}
                />
              </StyledBox>
            }
          />
          <Route path={`${TABS.INSPECTIONS.value}/*`} element={<Inspections />} />
          <Route path={`${TABS.PAYMENTS.value}/*`} element={<PaymentsTab />} />
          <Route path={`${TABS.PHOTOS.value}/*`} element={<Photos />} />
          <Route path={`${TABS.POLICIES.value}/*`} element={<Policies />} />
        </Routes>
      </Stack>

      <RightDrawer {...rightMenu}>
        <MessagePanel projectId={projectId} source={'project__comments'} />
      </RightDrawer>
    </Box>
  ) : (
    <Navigate to={`${pathBase}/${TABS.OVERVIEW.value}`} replace />
  );
};

const ActionIcon = ({ showIcon }) => {
  return showIcon ? (
    <Stack sx={{ position: 'relative', bottom: '14px' }}>
      <DotIcon size={8} color={colors.icons.green} />
    </Stack>
  ) : null;
};
export default Project;
