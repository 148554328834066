import { currencyFormatter } from '@utils';
import { MilestoneListColumnTypeV2 } from './common';

const retainageApprovedHoldback: MilestoneListColumnTypeV2 = {
  field: 'retainage_approved_amount_holdback',
  headerAlign: 'right',
  headerClassName: 'number',
  cellClassName: 'number',
  headerName: 'Retainage heldback for this draw',
  description: 'Amount retainage heldback for this draw ($)',
  valueFormatter: (value) => currencyFormatter(value, '-'),
  minWidth: 120,
  editable: false,
};

export default retainageApprovedHoldback;
