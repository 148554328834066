import React, { FC } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useVerifyBorrowerModal } from './controller';
import { CustomTextField, InternationalPhoneField, VerifyBorrowerModalV2 } from '@components';
import { EditProfileImage } from '@svgAsComponents';
import { colors } from '@theme';
import { isMobile } from 'react-device-detect';
import ReactDOM from 'react-dom';
import { useLaunchDarklyFlags } from '@context';

const VerifyBorrowerModal: FC = () => {
  const { open, firstNameField, lastNameField, emailField, phoneNumberField, onConfirm } =
    useVerifyBorrowerModal();

  const flags = useLaunchDarklyFlags();
  if (flags?.['ENG_8628_onboarding_ui_improvements']) return <VerifyBorrowerModalV2 />;

  const body = () => (
    <Stack
      sx={{
        backgroundColor: colors.background.gray,
        position: 'fixed',
        top: 0,
        height:
          isMobile && document.documentElement.style.getPropertyValue('--app-height')
            ? 'var(--app-height)'
            : '100vh',
        width: '100%',
        left: 0,
        margin: 0,
        zIndex: 1200,
      }}
    >
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{
          flex: 1,
        }}
      >
        <Stack
          sx={{
            backgroundColor: colors.background.white,
            py: 3,
            minWidth: { lg: 950, md: 800, xs: '100%' },
            minHeight: { md: 'auto', xs: '100%' },
            alignItems: 'center',
          }}
        >
          <Typography variant="h1">Before we start, confirm your personal information.</Typography>
          <Stack sx={{ justifyContent: 'space-between', flexDirection: 'row', pt: 8 }}>
            <Stack spacing={2.5} marginRight={11} sx={{ width: '50%' }}>
              <CustomTextField
                field={firstNameField}
                label="First name"
                inputProps={{
                  'data-cy': 'verify_borrower_modal__first_name__input',
                }}
              />
              <CustomTextField
                field={lastNameField}
                label="Last name"
                inputProps={{
                  'data-cy': 'verify_borrower_modal__last_name__input',
                }}
              />
              <InternationalPhoneField
                field={phoneNumberField}
                inputProps={{
                  'data-cy': 'verify_borrower_modal__phone__input',
                }}
              />
              <CustomTextField field={emailField} label="Email" disabled />
            </Stack>
            <EditProfileImage />
          </Stack>
        </Stack>
      </Stack>
      <Box
        p={3}
        sx={{
          backgroundColor: colors.background.white,
          flexDirestion: 'row',
          display: 'flex',
          justifyContent: { xs: 'center', md: 'flex-end' },
        }}
      >
        <LoadingButton onClick={onConfirm} data-cy="verify_borrower_modal__confirm__button">
          Confirm
        </LoadingButton>
      </Box>
    </Stack>
  );
  if (!open) return null;
  return <>{ReactDOM.createPortal(body(), document.body)}</>;
};

export default VerifyBorrowerModal;
