import { useContext, useEffect, useMemo, useState } from 'react';
import { useQueries, useQuery } from 'react-query';
import { useParams, useLocation, matchPath } from 'react-router-dom';

import {
  getCustomer,
  getHookState,
  getPoliciesByRole,
  getPoliciesListTitle,
  isProjectPolicies,
} from '@utils';
import {
  IProjectChecklist,
  PolicyListSourceEnums,
  PolicyTitleEnum,
  QueryNamesEnums,
  TTeamRole,
} from '@interfaces';
import {
  getDrawRequestItemChecklist,
  getProjectChecklist,
  getProjectTeams,
  getRequestChecklistTemplates,
} from '@globalService';
import { AuthContext, PermissionsContext } from '@context';
import { PoliciesTypeMap, TEAM_ROLES } from '@constants';
import { ControllerInterface } from './interface';
import { useLeavePageBlocker } from '@hooks';

export const usePolicies = (): ControllerInterface => {
  const { projectId } = useParams();
  const { pathname } = useLocation();
  const { user } = useContext(AuthContext);
  const match = matchPath('/projects/:projectId/:tab/*', pathname);
  const tabPathname = match?.params['*']?.split('/')[0];
  const drawRequestId = match?.params['*']?.split('/')[1];
  const { permissions } = useContext(PermissionsContext);
  const [editMode, setEditMode] = useState<PolicyListSourceEnums>();
  const { getLeavePageConfirmModal, setLeaveModalOpen, setTriggerExit } = useLeavePageBlocker({
    currentPagePathname: `/projects/${projectId}/policies/${tabPathname}`,
    confirmTitle: 'Exit policy editing',
    isUpdated: Boolean(editMode),
  });

  const handleEditMode = (source: PolicyListSourceEnums) => {
    if (editMode) {
      setLeaveModalOpen(true);
      setTriggerExit({
        isNavigationConfirmed: false,
        path: `/projects/${projectId}/policies/${tabPathname}`,
        confirmCallbackFn: () => {
          setEditMode(source);
        },
      });
      return;
    }
    setEditMode(source);
  };

  const handleCloseEdit = () => {
    setEditMode(null);
    setTriggerExit({
      isNavigationConfirmed: false,
      path: `/projects/${projectId}/policies/${tabPathname}`,
    });
  };

  useEffect(() => {
    setEditMode(null);
  }, [tabPathname]);

  const isProjectPoliciesTab = useMemo(() => isProjectPolicies(tabPathname), [tabPathname]);

  const drawRequestQuery = useQuery(
    [QueryNamesEnums.GET_DRAW_REQUEST_ITEM_CHECKLIST, { projectId, drawRequestId }],
    getDrawRequestItemChecklist.bind(this, projectId, drawRequestId),
    { enabled: Boolean(drawRequestId) },
  );
  const requestPoliciesData = drawRequestQuery.data;

  const projectChecklistTemplateQuery = useQuery<IProjectChecklist[], Error>(
    [QueryNamesEnums.GET_PROJECT_CHECKLIST_TEMPLATES, { projectId }],
    getRequestChecklistTemplates.bind(this, projectId),
    { enabled: !drawRequestId },
  );
  const projectPoliciesTemplates = projectChecklistTemplateQuery.data;

  const requestedDataQueries = useQueries([
    {
      queryKey: [QueryNamesEnums.GET_PROJECT_CHECKLIST, { projectId }],
      queryFn: getProjectChecklist.bind(this, projectId),
    },
    {
      queryKey: [QueryNamesEnums.GET_PROJECT_TEAMS, { projectId, companyId: null }],
      queryFn: getProjectTeams.bind(this, { projectId }),
    },
  ]);
  const projectPoliciesData = requestedDataQueries[0].data;
  const projectTeamsData = requestedDataQueries[1].data;

  const policiesToProcess = useMemo(() => {
    if (drawRequestId) return requestPoliciesData;

    if (isProjectPoliciesTab) return projectPoliciesData;

    return projectPoliciesTemplates?.filter((o) => o.type === PoliciesTypeMap[tabPathname]) || [];
  }, [requestPoliciesData, projectPoliciesData, tabPathname, projectPoliciesTemplates]);

  const policies = useMemo(() => {
    const customer = getCustomer(projectTeamsData?.results);
    return getPoliciesByRole({
      policies: policiesToProcess,
      teamRole: customer?.role as TTeamRole,
      teamId: user?.active_team?.id,
    });
  }, [policiesToProcess, projectTeamsData, permissions]);

  const borrowerPolicies = useMemo(
    () =>
      getPoliciesByRole({
        policies: policiesToProcess,
        teamRole: TEAM_ROLES.Owner as TTeamRole,
        teamId: user?.active_team?.id,
      }),
    [policiesToProcess, permissions],
  );

  const submissionPolicyTitle = useMemo(() => {
    if (isProjectPoliciesTab) return PolicyTitleEnum.BORROWER;
    return !drawRequestId
      ? PolicyTitleEnum.SUBMISSION
      : getPoliciesListTitle(PolicyTitleEnum.SUBMISSION, borrowerPolicies?.items);
  }, [borrowerPolicies, isProjectPoliciesTab, drawRequestId]);

  const approvalPolicyTitle = useMemo(() => {
    if (isProjectPoliciesTab) return PolicyTitleEnum.LENDER;
    if (!drawRequestId) return PolicyTitleEnum.APPROVAL;
    return getPoliciesListTitle(PolicyTitleEnum.APPROVAL, policies?.items);
  }, [policies, isProjectPoliciesTab, drawRequestId]);

  return {
    state: drawRequestId
      ? getHookState([drawRequestQuery, ...requestedDataQueries])
      : getHookState([projectChecklistTemplateQuery, ...requestedDataQueries]),
    submissionPolicyTitle,
    approvalPolicyTitle,
    borrowerPolicies,
    policies,
    isProjectPolicies: isProjectPoliciesTab,
    editMode,
    handleCloseEdit,
    handleEditMode,
    getLeavePageConfirmModal,
  };
};
