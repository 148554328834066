import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  emptyHTML,
  getActiveDrawRequestFromList,
  getBasicUrl,
  getCurrentInspection,
  getHookState,
  getTagsList,
  isAutomatedInspection,
  isOrderedService,
  isRestricted,
  getServiceTypeDisplayName,
} from '@utils';
import {
  HookState,
  IDrawRequest,
  IInspection,
  IMilestone,
  IProjectComment,
  PermissionNamesEnums,
  QueryNamesEnums,
  ITablePagination,
  IServiceOrder,
} from '@interfaces';
import { useSafeSnackbar, useTablePagination } from '@hooks';
import {
  getCommentsWithPagination,
  getProjectDrawRequestsList,
  getProjectInspectionById,
  getProjectInspectionsList,
  getProjectMilestone,
  patchComment as patch,
  postComment as post,
  getServiceOrderById,
} from '@globalService';
import { PermissionsContext, SettingsContext } from '@context';
import { StringFieldModel, useStringFieldModel, useDebouncedEffect } from '@models';
import { ACTIVE_COMMENTS_TYPES, commentsListFields } from '@constants';

export interface ControllerInterface {
  state: HookState;
  postMessage: (message: string) => Promise<Response>;
  commentField: StringFieldModel;
  comments: IProjectComment[];
  handleSearchSubmit: (value: string) => void;
  clearSearch: () => void;
  search: string;
  commentsAreLoading: boolean;
  isPostingComment: boolean;
  handleFilters: (values: string[]) => void;
  filters: string[];
  tags: string[];
  reportTag: boolean;
  setReportTag: (value: boolean) => void;
  updateComment: (comment: IProjectComment) => (reportTag: boolean) => void;
  pinComment: (comment: IProjectComment) => () => void;
  showReportTag: boolean;
  showSendToInspectorCheckbox: boolean;
  isSendToInspectorChecked: boolean;
  setSendToInspectorChecked: Dispatch<SetStateAction<boolean>>;
  commentsCount: number;
  tablePagination: ITablePagination;
}

export const useProjectComments = ({
  projectId,
  requestId,
  milestoneId,
  inspectionId: inspectionIdFromProps,
  serviceOrderId,
  documentId,
  isProjectComments,
}: {
  projectId: string;
  requestId: string;
  milestoneId: string;
  inspectionId: string;
  serviceOrderId: string;
  documentId: string;
  isProjectComments: boolean;
}): ControllerInterface => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSafeSnackbar();
  const { permissions } = useContext(PermissionsContext);
  const { isPHBProject, settings } = useContext(SettingsContext);
  const [reportTag, setReportTag] = useState<boolean>(false);
  const [currentInspectionId, setCurrentInspectionId] = useState<string>(inspectionIdFromProps);

  const [isSendToInspectorChecked, setSendToInspectorChecked] = useState<boolean>(
    Boolean(inspectionIdFromProps),
  );

  const commentField = useStringFieldModel({
    initValue: '',
    validationRule: (value) => Boolean(value.trim()) && !emptyHTML(value),
  });

  const projectInspectionsQuery = useQuery<{ results: IInspection[] }, Error>(
    [QueryNamesEnums.GET_PROJECT_INSPECTIONS, { projectId }],
    getProjectInspectionsList.bind(this, { projectId }),
    {
      enabled: false,
    },
  );

  useEffect(() => {
    if (
      !inspectionIdFromProps &&
      !isRestricted(PermissionNamesEnums.INSPECTIONS_VIEW, permissions) &&
      !documentId &&
      !milestoneId
    ) {
      projectInspectionsQuery.refetch().then(() => {
        const activeInspection = getCurrentInspection(projectInspectionsQuery.data?.results);
        if (activeInspection) {
          setCurrentInspectionId(activeInspection.id);
        }
      });
    }
  }, [inspectionIdFromProps, projectId]);

  const applicableInspectionId = useMemo(
    () => inspectionIdFromProps || (isSendToInspectorChecked && currentInspectionId),
    [isSendToInspectorChecked, currentInspectionId, inspectionIdFromProps],
  );

  const drawRequestsQuery = useQuery<{ results: IDrawRequest[] }, Error>(
    [QueryNamesEnums.GET_PROJECT_DRAW_REQUEST_LIST, { projectId }],
    getProjectDrawRequestsList.bind(this, projectId),
    { enabled: Boolean(projectId) },
  );

  const milestoneQuery = useQuery<IMilestone, Error>(
    [QueryNamesEnums.GET_PROJECT_MILESTONE, { projectId, milestoneId }],
    getProjectMilestone.bind(this, { projectId, milestoneId }),
    { enabled: Boolean(milestoneId && !isPHBProject) },
  );

  const queryInspectionFields = '{status,inspection_agency,service_type,completed_at}';
  const inspectionQuery = useQuery<IInspection, Error>(
    [
      QueryNamesEnums.GET_PROJECT_INSPECTION_BY_ID,
      {
        projectId,
        inspectionId: currentInspectionId,
        query: queryInspectionFields,
      },
    ],
    getProjectInspectionById.bind(this, {
      projectId,
      inspectionId: currentInspectionId,
      query: queryInspectionFields,
    }),
    { enabled: Boolean(currentInspectionId) },
  );

  const queryServiceOrderFields = '{status,service_agency,service_type,completed_at}';
  const serviceOrderQuery = useQuery<IServiceOrder, Error>(
    [
      QueryNamesEnums.GET_PROJECT_SERVICE_ORDER_BY_ID,
      { projectId, serviceOrderId, restQlquery: queryServiceOrderFields },
    ],
    getServiceOrderById.bind(this, {
      projectId,
      serviceOrderId,
      restQlquery: queryServiceOrderFields,
    }),
    { enabled: Boolean(serviceOrderId) },
  );

  const showSendToInspectorCheckbox = useMemo(
    () =>
      !milestoneId &&
      !documentId &&
      !isRestricted(PermissionNamesEnums.INSPECTIONS_VIEW, permissions) &&
      isOrderedService(inspectionQuery.data?.status) &&
      isAutomatedInspection(inspectionQuery.data?.inspection_agency?.service),
    [permissions, inspectionQuery?.data, milestoneId, documentId],
  );

  const currentRequest = useMemo(
    () =>
      requestId
        ? drawRequestsQuery.data?.results?.find((x) => x?.id === requestId)
        : getActiveDrawRequestFromList(drawRequestsQuery.data),
    [drawRequestsQuery.data],
  );

  const [search, setSearch] = useState('');
  const handleSearchSubmit = setSearch;

  const clearSearch = () => setSearch('');
  const [debouncedSearch, setDebouncedSearch] = useState('');
  useDebouncedEffect(
    () => {
      setDebouncedSearch(search);
    },
    [search],
    500,
  );

  const tablePagination = useTablePagination({ initialRowsPerPage: 10, rowsPerPageOptions: [] });
  useEffect(() => {
    tablePagination.setPage(0);
  }, [debouncedSearch]);

  const [filters, setFilters] = useState([]);
  const handleFilters = (values) => setFilters(values);

  const commentsUrl = useMemo(() => {
    let basicPath =
      getBasicUrl({
        requestType: 'get',
        projectId,
        requestId,
        milestoneId,
        inspectionId: inspectionIdFromProps, // to show only inspection comments only if it's passed from props
        serviceOrderId,
        documentId,
        isPHBProject,
      }) + '?';

    if (debouncedSearch.trim())
      basicPath += `${new URLSearchParams({
        q: debouncedSearch,
      }).toString()}`;
    if (filters?.length) {
      const filtersSearchParams = new URLSearchParams({
        comment_type: filters.join(','),
      }).toString();
      basicPath += debouncedSearch ? `&${filtersSearchParams}` : `?${filtersSearchParams}`;
    }
    const paginationParams = `&offset=${
      tablePagination.page * tablePagination.rowsPerPage
    }&limit=${tablePagination.rowsPerPage}`;

    const restQLparams = `&query={${commentsListFields.join()}}`;

    return `${basicPath}${paginationParams}${restQLparams}&has_parent=false`;
  }, [
    debouncedSearch,
    filters,
    projectId,
    requestId,
    milestoneId,
    inspectionIdFromProps,
    serviceOrderId,
    documentId,
    tablePagination,
  ]);

  const postCommentsUrl = useMemo(
    () =>
      getBasicUrl({
        requestType: 'post',
        projectId,
        requestId: milestoneId ? requestId : currentRequest?.id,
        milestoneId,
        inspectionId: applicableInspectionId,
        serviceOrderId,
        documentId,
        isPHBProject,
      }),
    [
      projectId,
      currentRequest,
      milestoneId,
      isSendToInspectorChecked,
      inspectionIdFromProps,
      currentInspectionId,
      serviceOrderId,
      documentId,
      isPHBProject,
    ],
  );

  const serviceTypeDisplayName = useMemo(() => {
    if (!serviceOrderQuery?.data) return '';
    return getServiceTypeDisplayName({
      serviceTypesMap: settings?.display?.service_types,
      serviceType: serviceOrderQuery?.data?.service_type,
    });
  }, [settings?.display?.service_types, serviceOrderQuery?.data?.service_type]);

  // tags to show under comment input field to indicate current comment type
  const tags = useMemo(
    () =>
      getTagsList({
        request: !isProjectComments ? currentRequest : null,
        milestone: milestoneQuery.data,
        inspection: applicableInspectionId ? inspectionQuery?.data : null,
        serviceOrder: serviceOrderId ? serviceOrderQuery?.data : null,
        documentId,
        isAutomatedInspection:
          isAutomatedInspection(inspectionQuery?.data?.inspection_agency?.service) &&
          isSendToInspectorChecked,
        serviceType: serviceTypeDisplayName,
      }),
    [
      currentRequest,
      milestoneQuery.data,
      inspectionQuery?.data,
      documentId,
      applicableInspectionId,
      isSendToInspectorChecked,
      isProjectComments,
      serviceOrderId,
      serviceOrderQuery?.data,
      serviceTypeDisplayName,
    ],
  );

  const projectCommentsQuery = useQuery<{ results: IProjectComment[]; count: number }, Error>(
    [QueryNamesEnums.GET_PROJECT_COMMENTS, { url: commentsUrl }],
    getCommentsWithPagination.bind(this, { url: commentsUrl }),
    {
      enabled: Boolean(projectId),
      staleTime: 0,
    },
  );

  const postComment = useMutation<
    Response,
    Error,
    { url: string; value: { message: string; tags?: string[]; should_send_to_inspector: boolean } }
  >(post, {
    onSuccess: () => {
      commentField.setValue('');
      if (!inspectionIdFromProps) setSendToInspectorChecked(false);
      queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_COMMENTS, { url: commentsUrl }]);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const postMessage = (message: string) => {
    const regex = /<a\s+(?:[^>]*?\s+)?href="(?:https?:\/\/)?([^"]*)"/g;
    return postComment.mutateAsync({
      url: postCommentsUrl,
      value: {
        // add https:// to all links that don't have it
        message: message.replaceAll(regex, '<a href="https://$1"'),
        ...(reportTag && { tags: ['Report'] }),
        should_send_to_inspector: showSendToInspectorCheckbox && isSendToInspectorChecked,
      },
    });
  };

  const patchCommentsUrl = useCallback(
    (commentId) => {
      const basicUrl = getBasicUrl({
        requestType: 'post',
        projectId,
        requestId: milestoneId ? requestId : currentRequest?.id,
        milestoneId,
        inspectionId: applicableInspectionId,
        serviceOrderId,
        documentId,
        isPHBProject,
      });
      return basicUrl + `${commentId}/`;
    },
    [
      projectId,
      currentRequest,
      milestoneId,
      isSendToInspectorChecked,
      inspectionIdFromProps,
      currentInspectionId,
      serviceOrderId,
      documentId,
    ],
  );

  const patchComment = useMutation<
    Response,
    Error,
    { url: string; value: { tags?: string[]; pinned_at?: Date | null } }
  >(patch, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_COMMENTS, { url: commentsUrl }]);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const updateComment = (comment: IProjectComment) => (reportTag: boolean) =>
    patchComment.mutateAsync({
      url: patchCommentsUrl(comment?.id),
      value: {
        tags: reportTag
          ? ['Report', ...(comment?.tags || [])]
          : comment?.tags?.filter((tag) => tag !== 'Report'),
      },
    });

  const comments = useMemo(
    () =>
      projectCommentsQuery?.data?.results?.filter(
        (comment: IProjectComment) =>
          comment.message && ACTIVE_COMMENTS_TYPES.includes(comment.content_type),
      ),
    [projectCommentsQuery?.data?.results],
  );

  const showReportTag = useMemo(
    () => !isRestricted(PermissionNamesEnums.PROJECTS_REPORT_VIEW, permissions),
    [permissions],
  );

  const pinComment = (comment: IProjectComment) => () =>
    patchComment.mutateAsync({
      url: patchCommentsUrl(comment?.id),
      value: {
        pinned_at: comment.pinned_at ? null : new Date(),
      },
    });

  return {
    state: getHookState(drawRequestsQuery),
    postMessage,
    commentField,
    comments,
    commentsCount: projectCommentsQuery?.data?.count,
    handleSearchSubmit,
    clearSearch,
    search,
    commentsAreLoading: projectCommentsQuery?.isLoading,
    isPostingComment: postComment.isLoading,
    handleFilters,
    filters,
    tags,
    reportTag,
    setReportTag,
    updateComment,
    showReportTag,
    showSendToInspectorCheckbox,
    isSendToInspectorChecked,
    setSendToInspectorChecked,
    pinComment,
    tablePagination,
  };
};
