import {
  useCallback,
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useContext,
} from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import { useSafeSnackbar } from '@hooks';

import {
  HookState,
  QueryNamesEnums,
  ITeam,
  BulkUpdateTeamsPayload,
  UpdateCompanySettingsPayload,
  ICompanySettings,
  PermissionNamesEnums,
} from '@interfaces';
import { getHookState, sortTeamsByApprovalLevel, isRestricted } from '@utils';
import {
  getCompanyTeams,
  patchBulkTeamsUpdate,
  updateCompanySettings,
  getCompanySettings,
} from '@globalService';
import { PermissionsContext } from '@context';

export type ControllerInterface = {
  state: HookState;
  teamsInApprovalFlow: ITeam[];
  teamsExcludedFromApproval: ITeam[];
  setTeamsInApprovalFlow: Dispatch<SetStateAction<ITeam[]>>;
  handleBulkUpdateTeam: () => void;
  reviewsQuantity: number;
  fundingApproveRequired: boolean;
  isSettingsMutating: boolean;
  handleQuantityClick: (item: number) => () => void;
  requeredReviewsValuesArray: number[];
  handleFundingReviewClick: (value: boolean) => void;
  hasCompanyTeamsEditPermission: boolean;
  handleTeamApprovalFlow: (value: boolean, index: number) => void;
  isApprovalFlowMutating: boolean;
};

export const useCompanyWorkflow = (): ControllerInterface => {
  const { companyId } = useParams();
  const { enqueueSnackbar } = useSafeSnackbar();
  const queryClient = useQueryClient();
  const { permissions } = useContext(PermissionsContext);

  const [teamsInApprovalFlow, setTeamsInApprovalFlow] = useState<ITeam[]>([]);
  const [teamsExcludedFromApproval, setTeamsExcludedFromApproval] = useState<ITeam[]>([]);
  const [initialTeamsInApprovalFlow, setInitialTeamsInApprovalFlow] = useState<ITeam[]>([]);
  const [initialTeamsExcludedFromApproval, setInitialTeamsExcludedFromApproval] = useState<ITeam[]>(
    [],
  );

  const [reviewsQuantity, setReviewsQuantity] = useState<number | null>(null);
  const [fundingApproveRequired, setFundingApproveRequired] = useState<boolean | null>(null);

  const companyTeamsQuery = useQuery<{ results: ITeam[] }, Error>(
    [QueryNamesEnums.GET_COMPANY_TEAMS, { companyId }],
    getCompanyTeams.bind(this, companyId),
  );

  useEffect(() => {
    const { data } = companyTeamsQuery;
    if (!data) return;
    const sortedTeams = sortTeamsByApprovalLevel(data.results?.filter((o) => o.approval_level > 0));
    const excludedTeams = data.results?.filter((o) => o.approval_level === 0);

    setTeamsInApprovalFlow(sortedTeams);
    setTeamsExcludedFromApproval(excludedTeams);

    // Save initial values
    setInitialTeamsInApprovalFlow(sortedTeams);
    setInitialTeamsExcludedFromApproval(excludedTeams);
  }, [companyTeamsQuery.data]);

  const arraysAreEqual = (a: ITeam[], b: ITeam[]) => {
    if (a.length !== b.length) return false;
    const sortedA = sortBy(a, 'id');
    const sortedB = sortBy(b, 'id');
    return isEqual(sortedA, sortedB);
  };

  const [isProcessing, setIsProcessing] = useState(false);
  const handleTeamApprovalFlow = (value: boolean, index: number) => {
    setIsProcessing(true);
    const updatedTeamsInApprovalFlow = [...teamsInApprovalFlow];
    const updatedTeamsExcludedFromApproval = [...teamsExcludedFromApproval];

    if (value) {
      // Include team back to approval flow
      const teamToInclude = teamsExcludedFromApproval[index];
      updatedTeamsExcludedFromApproval.splice(index, 1);
      updatedTeamsInApprovalFlow.push(teamToInclude);
    } else {
      // Exclude team from approval flow
      const teamToExclude = teamsInApprovalFlow[index];
      updatedTeamsInApprovalFlow.splice(index, 1);
      updatedTeamsExcludedFromApproval.push(teamToExclude);
      updatedTeamsExcludedFromApproval.sort((a, b) => a.name.localeCompare(b.name));
    }
    setTeamsInApprovalFlow(updatedTeamsInApprovalFlow);
    setTeamsExcludedFromApproval(updatedTeamsExcludedFromApproval);
  };

  const handleBulkUpdateTeam = useCallback(() => {
    updateTeamsMutation.mutateAsync({
      companyId,
      data: {
        teams: [
          ...teamsInApprovalFlow.map((o, index) => ({ id: o.id, approval_level: index + 1 })),
          ...teamsExcludedFromApproval.map((o) => ({ id: o.id, approval_level: 0 })),
        ],
      },
    });
  }, [teamsInApprovalFlow, teamsExcludedFromApproval]);

  useEffect(() => {
    if (
      !arraysAreEqual(initialTeamsInApprovalFlow, teamsInApprovalFlow) ||
      !arraysAreEqual(initialTeamsExcludedFromApproval, teamsExcludedFromApproval)
    ) {
      handleBulkUpdateTeam();
    }
  }, [
    teamsInApprovalFlow,
    teamsExcludedFromApproval,
    initialTeamsInApprovalFlow,
    initialTeamsExcludedFromApproval,
  ]);

  const companySettingsQuery = useQuery<ICompanySettings, Error>(
    [QueryNamesEnums.GET_COMPANY_SETTINGS, { companyId }],
    getCompanySettings.bind(this, companyId),
  );

  useEffect(() => {
    const { data } = companySettingsQuery;
    if (!data) return;
    setReviewsQuantity(data.reviews_required_quantity || null);
    setFundingApproveRequired(data.funding_approve_required || null);
  }, [companySettingsQuery.data]);

  const updateTeamsMutation = useMutation<Response, Error, BulkUpdateTeamsPayload>(
    patchBulkTeamsUpdate,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryNamesEnums.GET_COMPANY_TEAMS, { companyId }]);
        queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT_TEAMS);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
      onSettled: () => {
        setIsProcessing(false);
      },
    },
  );

  const updateCompanySettingsMutation = useMutation<Response, Error, UpdateCompanySettingsPayload>(
    updateCompanySettings,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryNamesEnums.GET_COMPANY_SETTINGS, { companyId }]);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const handleQuantityClick = (item: number) => () => {
    if (!updateCompanySettingsMutation.isLoading) {
      updateCompanySettingsMutation.mutateAsync({
        companyId,
        data: {
          reviews_required_quantity: item,
        },
      });
      setReviewsQuantity(item);
    }
  };

  const handleFundingReviewClick = (value: boolean) => {
    if (!updateCompanySettingsMutation.isLoading) {
      updateCompanySettingsMutation.mutateAsync({
        companyId,
        data: {
          funding_approve_required: value,
        },
      });
      setFundingApproveRequired(value);
    }
  };

  const requeredReviewsValuesArray = useMemo(() => {
    if (!companyTeamsQuery.data) return [];
    const maxLength =
      companyTeamsQuery.data?.results.length < 5 ? companyTeamsQuery.data?.results.length : 5;
    return Array.from({ length: maxLength }, (_, index) => index + 1);
  }, [companyTeamsQuery.data]);

  const hasCompanyTeamsEditPermission = useMemo(
    () => !isRestricted(PermissionNamesEnums.COMPANY_TEAMS_EDIT, permissions),
    [permissions],
  );

  return {
    state: getHookState(companyTeamsQuery),
    teamsInApprovalFlow,
    teamsExcludedFromApproval,
    setTeamsInApprovalFlow,
    handleBulkUpdateTeam,
    reviewsQuantity,
    fundingApproveRequired,
    isSettingsMutating: updateCompanySettingsMutation.isLoading,
    handleQuantityClick,
    requeredReviewsValuesArray,
    handleFundingReviewClick,
    hasCompanyTeamsEditPermission,
    handleTeamApprovalFlow,
    isApprovalFlowMutating: updateTeamsMutation.isLoading || isProcessing,
  };
};
