import { useEffect, useMemo, useContext } from 'react';
import { useLocation, matchPath, useNavigate } from 'react-router-dom';

import { PermissionsContext } from '@context';
import { PermissionNamesEnums } from '@interfaces';
import { isRestricted } from '@utils';

const TABS = {
  REQUESTS: { label: 'Draws / Changes', value: 'draws-and-changes' },
  INSPECTIONS: { label: 'Inspections', value: 'inspections' },
};

interface TabType {
  label: string;
  value: string;
}
export interface ControllerInterface {
  activeTab: string;
  TABS: {
    [key: string]: TabType;
  };
  showInspectionsTab: boolean;
}

export const useRequestsQueue = (): ControllerInterface => {
  const { pathname } = useLocation();
  const match = matchPath('/requests/:tab', pathname);
  const navigate = useNavigate();
  const activeTab = match?.params.tab || TABS.REQUESTS.value;

  useEffect(() => {
    if (!match?.params.tab) navigate(`/requests/${TABS.REQUESTS.value}`);
  }, [match?.params.tab]);

  const { permissions } = useContext(PermissionsContext);

  const showInspectionsTab = useMemo(
    () => !isRestricted(PermissionNamesEnums.INSPECTIONS_VIEW, permissions),
    [permissions],
  );

  return {
    TABS,
    activeTab,
    showInspectionsTab,
  };
};
