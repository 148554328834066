import { useContext, useState, useEffect, useMemo, useCallback } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useFormik } from 'formik';
import { useLocation, useSearchParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { useMsal } from '@azure/msal-react';

import { ErrorDual, IdentityServiceTypeEnum } from '@interfaces';
import { useLogin } from '@hooks';
import { getIntegrationData } from '@globalService';
import { domainValidationSchema, getErrorText } from '@utils';
import { SSOContext } from '@context';
export interface LoginPayload {
  sso_access_token?: string;
  auth_connector?: string;
  sso_domain_name?: string;
}
export interface ControllerInterface {
  formik: any;
  inProgress: boolean;
  sso_access_token: string;
  fromRedirect: boolean;
}
interface CustomizedState {
  fromRedirect?: boolean;
  from?: string;
  ssoAccessToken?: string;
}

export const useSSOIntegration = (): ControllerInterface => {
  const { ssoType, setSSOConfig, setSSOType, clearSSOData } = useContext(SSOContext);

  const cleanupSSOData = () => {
    localStorage.clear();
    sessionStorage.clear();
    if (clearSSOData) clearSSOData();
  };

  const { postLoginData } = useLogin({
    errorCallback: (error) => {
      formik.setFieldError('domainName', getErrorText(error as ErrorDual));
    },
    onSettledCallback: () => {
      setInProgress(false);
    },
    successCallback: () => {
      cleanupSSOData();
    },
  });
  const { instance } = useMsal();

  const okta = useOktaAuth();
  const { authState, oktaAuth } = okta || {};

  const [inProgress, setInProgress] = useState(false);
  const authConnector = JSON.parse(sessionStorage.getItem('ssoConfig'))?.state;
  // to define if it's initial sso login page or after redirect callback
  const location = useLocation();
  const state = location?.state as CustomizedState;

  const [searchParams] = useSearchParams();
  const iss = searchParams.get('iss')?.split('//').pop();
  const sso_domain_name = searchParams.get('sso_domain_name');
  const sso_access_token = searchParams.get('sso_access_token') || state?.ssoAccessToken;

  // location.state - 'fromRedirect' flag is used to identify redirect after okta sso login
  const fromRedirect = useMemo(() => {
    return state?.fromRedirect;
  }, [state]);

  const getConnectorData = (domainName) => {
    cleanupSSOData();
    getIntegrationData(domainName, 'INTERNAL')
      .then((data) => {
        if (data) {
          let config = {};
          if (data?.identity_service_type === IdentityServiceTypeEnum.OKTA) {
            config = {
              issuer: `https://${data.domain}`,
              clientId: data.client_id,
              redirectUri: `${window.location.origin}/login-with-sso/callback`,
              state: data.id,
            };
          }

          if (data?.identity_service_type === IdentityServiceTypeEnum.MICROSOFT) {
            config = {
              authority: `https://${data.domain}`,
              clientId: data.client_id,
              redirectUri: `${window.location.origin}/login-with-sso/callback`,
              state: data.id,
              responseType: ['token'],
              scopes: ['openid'],
              pkce: false,
            };
          }

          // when redirect from identity provider will be done we need to initiate the app with this provider instance
          // as we don't ask for config data on redirect we'll use them from SS
          sessionStorage.setItem('ssoConfig', JSON.stringify(config));
          sessionStorage.setItem('ssoType', JSON.stringify(data?.identity_service_type));
          // location.state - 'from' flag is used to redirect user to original url if he was logged out due to access token expiration
          if (state?.from) sessionStorage.setItem('originalUrl', JSON.stringify(state?.from));
          setSSOConfig(config);
          setSSOType(data?.identity_service_type);
        }
      })
      .catch((error) => {
        formik.setFieldError('domainName', error?.message);
        formik.setSubmitting(false);
        setInProgress(false);
      });
  };

  const formik = useFormik({
    initialValues: { domainName: '' },
    validationSchema: domainValidationSchema,
    onSubmit: (values) => {
      getConnectorData(values.domainName);
    },
  });

  useEffect(() => {
    if (!authConnector && iss) getConnectorData(iss);

    // check if it initial page and we re-init app with okta provider -> initiate okta auth
    if (authConnector && !fromRedirect && ssoType === IdentityServiceTypeEnum.OKTA)
      initiateOktaAuth();

    // check if it initial page and we re-init app with microsoft provider -> initiate microsoft auth
    if (authConnector && !sso_access_token && ssoType === IdentityServiceTypeEnum.MICROSOFT) {
      setInProgress(true);
      // timeout is needed to let msal instance to be initialized
      setTimeout(() => initiateMSalAuth(), 1000);
    }

    if ((authConnector && fromRedirect) || sso_access_token) handleLogin();
  }, [fromRedirect, authConnector, iss, sso_domain_name, sso_access_token, ssoType]);

  const initiateOktaAuth = async () => {
    try {
      setInProgress(true);
      await oktaAuth.signInWithRedirect();
    } catch (err: any) {
      console.log('Okta login error:', err);

      const error =
        typeof err === 'object'
          ? err?.errorSummary
          : typeof err === 'string'
            ? err
            : 'Unknown Okta login error';
      formik.setFieldError('domainName', error);
    } finally {
      setInProgress(false);
    }
  };

  const initiateMSalAuth = useCallback(async () => {
    const request = {
      scopes: ['openid'],
    };
    try {
      await instance.loginRedirect(request);
    } catch (err) {
      console.log('Microsoft login error:', err);

      const error =
        typeof err === 'object'
          ? JSON.stringify(err)
          : typeof err === 'string'
            ? err
            : 'Unknown Microsoft login error';
      formik.setFieldError('domainName', error);
    } finally {
      setInProgress(false);
    }
  }, [instance]);

  const handleLogin = useCallback(async () => {
    let params = {};
    if (sso_domain_name && sso_access_token)
      params = {
        sso_domain_name,
        sso_access_token,
      };

    // Okta SSO
    if (authState?.accessToken?.accessToken && authConnector)
      params = {
        sso_access_token: authState.accessToken.accessToken,
        auth_connector: authConnector,
      };

    // Microsoft SSO
    if (ssoType === IdentityServiceTypeEnum.MICROSOFT) {
      params = {
        sso_access_token,
        auth_connector: authConnector,
      };
    }

    if (isEmpty(params)) return;
    setInProgress(true);
    await postLoginData.mutateAsync(params);
  }, [authConnector, sso_access_token, sso_domain_name, ssoType, authState]);

  return {
    formik,
    inProgress,
    sso_access_token,
    fromRedirect,
  };
};
