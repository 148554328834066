import React, { FC, useContext } from 'react';
import { Button, Stack, Typography, IconButton } from '@mui/material';
import { colors } from '@theme';
import {
  ConfirmationModal,
  Filter,
  MilestoneListWithPatch,
  RightDrawer,
  MessagePanel,
} from '@components';
import { PopupTypeEnum, TableKeyEnum } from '@interfaces';
import { useRequestTable } from './controller';
import { useNavigate, useParams } from 'react-router-dom';
import { DeleteIcon, CommentIconWithIndicator } from '@svgAsComponents';
import { getTooltipText } from '@utils';
import { SettingsContext, useLaunchDarklyFlags } from '@context';
import { AutofillAllowanceV2 } from '../../project/components';

const RequestTable: FC<{ isSubmissionProcess?: boolean; showEditRequest?: boolean }> = ({
  isSubmissionProcess,
  showEditRequest,
}) => {
  const navigate = useNavigate();
  const { projectId, requestId } = useParams();
  const { isCurrentProjectArchived } = useContext(SettingsContext);
  const {
    initColumns,
    onExpandClick,
    groupByOptions,
    filterOptions,
    milestones,
    filterValue,
    handleShowFilterClick,
    groupByValue,
    handleGroupByFilterClick,
    patchMsGroup,
    isLoading,
    canDeleteRequest,
    showDeleteModal,
    setShowDeleteModal,
    deleteRequest,
    isDeleting,
    showAutofillButton,
    drawRequest,
    handleAutofillLenderAllowance,
    isAutofillLoading,
    totals,
    groupByKeys,
    updateRightDrawer,
    rightMenu,
    onMilestoneUpdate,
  } = useRequestTable(isSubmissionProcess);

  const flags = useLaunchDarklyFlags();
  return (
    <Stack
      sx={{
        backgroundColor: colors.background.white,
        pt: 1,
        borderRadius: '4px',
        flex: 1,
      }}
    >
      <MilestoneListWithPatch
        withColumnIndication
        tableKey={isSubmissionProcess ? TableKeyEnum.SUBMISSION : TableKeyEnum.LINE_ITEMS}
        initColumns={initColumns}
        milestones={milestones}
        key="listWithEditModels"
        exportToCSV
        onExpandTable={onExpandClick}
        requestId={requestId}
        totals={totals}
        headerLeftPart={[
          {
            Component: (
              <Filter
                filterLabel="Show"
                onChangeCallback={handleShowFilterClick}
                options={filterOptions}
                filterValue={filterValue}
                source="requests_tab__line_items_table__filter"
              />
            ),
          },
          {
            Component: (
              <Stack sx={{ ml: 1.5 }}>
                <Filter
                  filterLabel="Group by"
                  onChangeCallback={handleGroupByFilterClick}
                  options={groupByOptions}
                  filterValue={groupByValue}
                  source="requests_tab__line_items_table__group_by"
                />
              </Stack>
            ),
          },
        ]}
        headerRightPart={[
          ...(showEditRequest
            ? [
                {
                  Component: (
                    <Button
                      size="small"
                      variant="new"
                      color="secondary"
                      onClick={() => {
                        navigate(`/projects/${projectId}/requests/${requestId}/table-edit/`);
                      }}
                    >
                      Edit request
                    </Button>
                  ),
                },
              ]
            : []),
          ...(showAutofillButton
            ? [
                {
                  Component: (
                    <AutofillAllowanceV2
                      drawRequest={drawRequest}
                      handleAutofillLender={handleAutofillLenderAllowance}
                      loading={isAutofillLoading}
                    />
                  ),
                },
              ]
            : []),
        ]}
        menuItems={
          canDeleteRequest
            ? [
                {
                  action: () => setShowDeleteModal(true),
                  text: (
                    <Typography variant="body3SemiBold" sx={{ color: colors.status.error.medium }}>
                      Delete request
                    </Typography>
                  ),
                  icon: <DeleteIcon size={16} color={colors.status.error.medium} />,
                  disabled: isCurrentProjectArchived,
                  disabledTooltipText: getTooltipText({ isCurrentProjectArchived }),
                  dataTestName: 'delete_request__menu_item',
                },
              ]
            : []
        }
        source="requests_tab__line_items_table"
        patchMilestone={patchMsGroup}
        isLoading={isLoading}
        groupByFilter={groupByKeys}
        requestStatus={drawRequest?.status}
        onCreditChangeCompleted={onMilestoneUpdate}
        lockSecondColumn
        withCredit
        withProgress
        commentIconComponent={
          flags?.['ENG_8325_commenting_lender_and_borrower_views'] ? (
            <IconButton
              onClick={updateRightDrawer()}
              data-cy="requests_tab__comments__icon"
              size="large"
            >
              <CommentIconWithIndicator
                hasComments={drawRequest?.comments_preview?.has_comments}
                hasUnreadComments={drawRequest?.comments_preview?.has_unread_comments}
              />
            </IconButton>
          ) : null
        }
      />
      <ConfirmationModal
        open={showDeleteModal}
        title="Delete request"
        text="This action cannot be undone. All edits associated with this draw request will be permanently deleted."
        onClose={() => setShowDeleteModal(false)}
        confirmCallback={deleteRequest}
        type={PopupTypeEnum.ERROR}
        isLoading={isDeleting}
        confirmButtonLabel="Delete"
        icon={DeleteIcon}
      />
      <RightDrawer {...rightMenu}>
        <MessagePanel
          projectId={projectId}
          requestId={drawRequest?.id}
          source="requests_tab__comments"
        />
      </RightDrawer>
    </Stack>
  );
};
export default RequestTable;
