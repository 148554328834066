import { useContext, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { IDrawRequest, IDrawRequestsStats, QueryNamesEnums } from '@interfaces';
import {
  checkIsOwner,
  getColorCodingScheme,
  getHookState,
  getSortQueryString,
  getTeamRole,
  isChangeRequest,
} from '@utils';
import { getDrawRequestList, getDrawRequestListStats } from '@globalService';
import { drawRequestFields, statusMap } from '@constants';
import { AuthContext, SettingsContext, useLaunchDarklyFlags } from '@context';

export const useDrawRequestList = ({ page, rowsPerPage, sortValue, filterStringQuery = '' }) => {
  const [filtersReady, setFiltersReady] = useState<boolean>(false);
  const { user } = useContext(AuthContext);
  const flags = useLaunchDarklyFlags();
  const { settings } = useContext(SettingsContext);
  const teamRole = getTeamRole(user);
  const isOwner = checkIsOwner(teamRole);
  const sortingString = getSortQueryString({
    sortValue,
    statusFieldSortName: 'draw_request_status',
  });
  const sorting = isOwner
    ? `draw_request_status_with_resubmit${sortingString ? ',' + sortingString : ''}`
    : sortingString;

  const params = {
    pagination: 'true',
    offset: (page * rowsPerPage).toString(),
    limit: rowsPerPage,
    ...(sorting && { sorting }),
  };

  const queryParams = new URLSearchParams(params);
  let stringQueryParams = queryParams.toString();
  if (filterStringQuery) {
    stringQueryParams += `&${filterStringQuery}`;
  }

  const query = stringQueryParams + `&query={${drawRequestFields.join(',')}}`;

  const requestsQuery = useQuery<{ results: IDrawRequest[]; count: number }, Error>(
    [QueryNamesEnums.GET_DRAW_REQUEST_LIST, { queryURL: query }],
    getDrawRequestList.bind(this, {
      queryURL: query,
    }),
    { ...(flags?.['eng_8858_combine_projects_requests'] ? { enabled: filtersReady } : {}) },
  );

  const { data: statsData } = useQuery<IDrawRequestsStats, Error>(
    [QueryNamesEnums.GET_DRAW_REQUEST_LIST_STATS],
    getDrawRequestListStats.bind(this, ''),
  );
  const allRequestCount = useMemo(() => statsData?.total_count, [statsData?.total_count]);

  const formattedData = useMemo(() => {
    const requests = requestsQuery.data?.results || [];

    return requests.map((request: IDrawRequest) => ({
      ...request,
      name: `${isChangeRequest(request) ? 'Change' : 'Draw'}\u00A0#${request.number}`,
      requestStatus: statusMap(request.status, settings.display, 'draw_request')?.text,
      projectStatus:
        statusMap(request.project.status, settings.display, 'project')?.text ||
        request.project.status,
      colorCodingScheme: getColorCodingScheme({ request }),
    }));
  }, [requestsQuery.data]);

  return {
    state: getHookState(requestsQuery),
    count: requestsQuery.data?.count,
    results: requestsQuery.data?.results,
    allRequestCount,
    formattedData,
    onFiltersReady: () => setFiltersReady(true),
  };
};
