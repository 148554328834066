import React, { FC } from 'react';
import { Stack, Box } from '@mui/material';
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridSlots,
} from '@mui/x-data-grid';

import { HookState, ITablePagination, TableKeyEnum, MessagePanelTabsEnum } from '@interfaces';
import {
  ServiceMessage,
  StyledBox,
  FiltersV2,
  RightDrawer,
  TableItemRightDrawer,
  TablePaginationNew,
} from '@components';
import * as Controller from './controller';
import { colors } from '@theme';
import { ControllerInterface } from './interface';

const ServicesQueue = () => {
  const controller = Controller.useServicesQueue();

  switch (controller.state) {
    case HookState.FETCHING: {
      return <Table controller={controller} />;
    }
    case HookState.ERROR: {
      return <ServiceMessage text="services" />;
    }

    case HookState.SUCCESS: {
      return <Table controller={controller} />;
    }

    default:
      return null;
  }
};

export default ServicesQueue;

const Table: FC<{
  controller: ControllerInterface;
}> = ({ controller }) => {
  const {
    rows,
    columns,
    hiddenColumns,
    setColumnVisibilityModel,
    setFilterStringQuery,
    isFetching,
    paginationProps,
    isColumnFilterUpdating,
    rightDrawerParams,
    rightMenu,
    handleRowClick,
    selectedRowId,
  } = controller;

  // TODO:
  // 1. add context menu
  return (
    <Stack sx={{ flex: 1, backgroundColor: colors.background.gray, p: 2 }}>
      <StyledBox sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, p: 0 }}>
        <DataGrid
          onRowClick={handleRowClick}
          rows={rows}
          columns={columns}
          loading={isFetching}
          columnVisibilityModel={hiddenColumns.reduce((acc, key) => {
            acc[key] = false;
            return acc;
          }, {})}
          onColumnVisibilityModelChange={setColumnVisibilityModel}
          slots={{
            toolbar: CustomToolbar as GridSlots['toolbar'],
          }}
          slotProps={{
            toolbar: {
              setFilterStringQuery,
              paginationProps,
            },
          }}
          columnBufferPx={200}
          sx={{
            flexGrow: 1,
            '& .MuiDataGrid-virtualScroller': {
              overflow: 'auto',
            },
            '& .MuiDataGrid-row': {
              cursor: 'pointer',
            },
          }}
          hideFooter={true}
          disableColumnSelector={isColumnFilterUpdating}
          getRowClassName={(params) => (params.id === selectedRowId ? 'selected-table-row' : '')}
        />
      </StyledBox>
      <RightDrawer {...rightMenu}>
        <TableItemRightDrawer
          {...rightDrawerParams}
          requestDocuments={false}
          messagePanelTab={MessagePanelTabsEnum.SERVICES}
          showMessagePanelTabs={false}
          source="all_services__list__right_drawer"
        />
      </RightDrawer>
    </Stack>
  );
};

const CustomToolbar = ({
  setFilterStringQuery,
  paginationProps,
}: {
  setFilterStringQuery: any;
  paginationProps: ITablePagination;
}) => {
  const { page, rowsPerPage, onPageChange, onRowsPerPageChange, rowsPerPageOptions, itemsCount } =
    paginationProps;

  return (
    <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center" p={2}>
      <FiltersV2
        skipToggle
        source="all_services__list"
        setFilterStringQuery={setFilterStringQuery}
        filters={['servicesTypeFilter', 'servicesListStatusFilter', 'customerFilter']}
        padding={0}
        width="unset"
        tableKey={TableKeyEnum.INSPECTION_LIST}
        onPageChange={onPageChange}
      />

      <Box sx={{ flexGrow: 1 }} />
      <TablePaginationNew
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
        itemsCount={itemsCount}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        source="all_services__list"
      />
      <GridToolbarColumnsButton
        slotProps={{
          button: { color: 'secondary', variant: 'new' },
        }}
      />
      <GridToolbarDensitySelector
        slotProps={{
          button: { color: 'secondary', variant: 'new' },
        }}
      />
    </Stack>
  );
};
