import { Dispatch, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQueries, useQueryClient } from 'react-query';
import {
  DocumentContentTypeEnum,
  ErrorDual,
  ITransloaditSignature,
  QueryNamesEnums,
  TransloaditTemplateEnum,
} from '@interfaces';
import {
  deleteProjectBuildingModels,
  getProject,
  getProjectBuildings,
  getProjectMilestonesList,
  getProjectModels,
} from '@globalService';
import { isCreatedProject, parsePathErrorDual } from '@utils';
import { useFilesUploader, useSafeSnackbar } from '@hooks';
import { LineItemFilterValues, PHBColumnsForCSV } from '@constants';

interface ControllerInterface {
  deleteBudget: () => void;
  projectName?: string;
  isConfirmOpen: boolean;
  setIsConfirmOpen: Dispatch<React.SetStateAction<boolean>>;
  navigateBack: () => void;
  isModelCreation: boolean;
  csvData: Record<string, string>;
  transloaditSignature: ITransloaditSignature;
  isEditable: boolean;
}

export const useCreateBudgetTableWrapper = (): ControllerInterface => {
  const navigate = useNavigate();
  const { projectId, action } = useParams();
  const { enqueueSnackbar } = useSafeSnackbar();
  const queryClient = useQueryClient();
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const { transloaditSignature, uploadMedia } = useFilesUploader();

  const isModelCreation = useMemo(() => action === 'edit-models', [action]);

  const query = '{}';
  const limit = '1';
  const requestedDataQueries = useQueries([
    {
      queryKey: [QueryNamesEnums.GET_PROJECT_BUILDING_MODELS, { projectId }],
      queryFn: getProjectModels.bind(this, projectId),
    },
    {
      queryKey: [QueryNamesEnums.GET_PROJECT, { projectId }],
      queryFn: getProject.bind(this, projectId),
    },
    {
      queryKey: [QueryNamesEnums.GET_PROJECT_BUILDING, { projectId }],
      queryFn: getProjectBuildings.bind(this, projectId),
    },
    {
      queryKey: [
        QueryNamesEnums.GET_PROJECT_MILESTONES,
        { projectId, query, filterKey: LineItemFilterValues.VERTICAL_COST.filterKey, limit },
      ],
      queryFn: getProjectMilestonesList.bind(this, {
        projectId,
        query,
        filterKey: LineItemFilterValues.VERTICAL_COST.filterKey,
        limit,
      }),
    },
  ]);

  const projectModels = requestedDataQueries[0]?.data?.results;
  const project = requestedDataQueries[1]?.data;
  const projectUnits = requestedDataQueries[2]?.data?.results;
  const verticalMilestonesCount = requestedDataQueries[3].data?.count;

  const deleteAllModelsMutation = useMutation<Response, ErrorDual, { project: string }>(
    deleteProjectBuildingModels,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_BUILDING_MODELS, { projectId }]);
        queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_BUILDING, { projectId }]);
        queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_MILESTONE_TAGS, { projectId }]);
        navigateBack();
      },
      onError: (error) => {
        enqueueSnackbar(parsePathErrorDual(error), { variant: 'error' });
      },
    },
  );

  useEffect(() => {
    uploadMedia({
      fields: { content_type: DocumentContentTypeEnum.PROJECT, object_id: projectId },
      templateType: TransloaditTemplateEnum.DOCUMENTS,
    });
  }, []);

  const deleteBudget = () => {
    setIsConfirmOpen(false);
    deleteAllModelsMutation.mutate({
      project: projectId,
    });
  };

  const navigateBack = () => {
    if (isModelCreation)
      queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_BUILDING, { projectId }]);
    else
      queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_BUILDING_MODELS, { projectId }]);

    navigate(`/projects/${projectId}/budget`);
  };

  const csvData = useMemo(() => {
    const arr = isModelCreation ? projectModels : projectUnits;
    if (!arr) return {};

    // We create CSV headers like (Model(Unit)/adjustments, Model(Unit)/approved amount, etc.)
    const resultObject = {};

    for (const item of arr) {
      const name = item.name;

      for (const field of PHBColumnsForCSV) {
        const key = `${name} / ${field}`;
        resultObject[key] = '';
      }
    }

    return {
      ['Line item']: '',
      ...resultObject,
    };
  }, [projectModels, projectUnits, isModelCreation]);

  const isEditable = useMemo(
    () => isCreatedProject(project?.status) && !verticalMilestonesCount,
    [project?.status, verticalMilestonesCount],
  );

  return {
    deleteBudget,
    projectName: project?.name,
    isConfirmOpen,
    setIsConfirmOpen,
    navigateBack,
    isModelCreation,
    csvData,
    transloaditSignature,
    isEditable,
  };
};
