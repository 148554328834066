import { MilestoneListColumnTypeV2 } from './common';

const externalId: MilestoneListColumnTypeV2 = {
  field: 'external_id',
  headerName: 'ID',
  description: 'External ID',
  headerClassName: 'text',
  headerAlign: 'left',
  align: 'left',
  cellClassName: 'text',
  editable: false,
  sortable: false,
  filterable: false,
  groupable: false,
  aggregable: false,
  hideable: false,
  resizable: false,
  width: 40,
};
export default externalId;
