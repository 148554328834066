import { MilestoneListColumnTypeV2 } from './common';
import { currencyFormatter, isRestricted } from '@utils';
import { PermissionNamesEnums } from '@interfaces';

const originalEstimate: MilestoneListColumnTypeV2 = {
  field: 'original_estimate',
  valueFormatter: (value) => currencyFormatter(value, '-'),
  headerAlign: 'right',
  headerClassName: 'number',
  cellClassName: 'number',
  headerName: 'Original scheduled value ($)',
  description: 'Original construction budget post funding',
  editableByMilestone: (row) => row.isEditableV2 && row.localNew,
  editableByPermissions: (permissions) =>
    !isRestricted(PermissionNamesEnums.DRAWREQUESTS_LINE_ITEM_CREATE, permissions),
  minWidth: 120,
};

export default originalEstimate;
