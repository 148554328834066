import { useMemo } from 'react';
import { MilestoneTag, MilestoneTagsTypesEnums, QueryNamesEnums } from '@interfaces';
import { getMilestoneTagName } from '@utils/index';
import { useQuery } from 'react-query';
import { getProjectMilestoneTags } from '@globalService';
import { useParams } from 'react-router-dom';

interface IPHBNames {
  modelName: string;
  unitName: string;
}

const usePHBNaming = (): IPHBNames => {
  const { projectId } = useParams();

  const msTagsQuery = useQuery<
    {
      results: MilestoneTag[];
    },
    Error
  >(
    [QueryNamesEnums.GET_PROJECT_MILESTONE_TAGS, { projectId }],
    getProjectMilestoneTags.bind(this, projectId),
    { enabled: Boolean(projectId) },
  );

  const msTags = msTagsQuery.data;

  const modelName = useMemo(
    () => getMilestoneTagName(msTags?.results, MilestoneTagsTypesEnums.MODEL) || 'Model',
    [msTags],
  );
  const unitName = useMemo(
    () => getMilestoneTagName(msTags?.results, MilestoneTagsTypesEnums.UNIT) || 'Unit',
    [msTags],
  );

  return {
    modelName,
    unitName,
  };
};

export default usePHBNaming;
