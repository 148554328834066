import { useCallback, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  ICompany,
  ICoordinator,
  IProjectCoordinator,
  IUser,
  QueryNamesEnums,
  UpdateRequestPayload,
} from '@interfaces';
import {
  getCompanyUsers,
  getMyCompany,
  updateDrawRequestCoordinator,
  updateProjectCoordinator,
} from '@globalService';
import { useAssignCoordinatorInvalidation, useSafeSnackbar } from '@hooks';
import { SelectChangeEvent } from '@mui/material';
import { filterActiveUsers, getUserFullName } from '@utils';
import { useLaunchDarklyFlags } from '@context';

interface ControllerInterface {
  coordinatorId: string;
  users: ICoordinator[];
  onAssignCoordinator: () => void;
  handleChange: (event: SelectChangeEvent) => void;
  shouldUpdateProjectCoordinator: boolean;
  setUpdateProjectCoordinator: (value: boolean) => void;
}

export const useAssignCoordinatorPopup = (
  initCoordinator: ICoordinator,
  projectId: string,
  requestId: string,
  onClose: () => void,
): ControllerInterface => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSafeSnackbar();
  const [coordinatorId, setCoordinatorId] = useState<string>(initCoordinator?.id || '');
  const [shouldUpdateProjectCoordinator, setUpdateProjectCoordinator] = useState<boolean>(false);
  const handleAssignCoordinatorInvalidation = useAssignCoordinatorInvalidation();
  const flags = useLaunchDarklyFlags();

  const companyQuery = useQuery<ICompany, Error>(
    [QueryNamesEnums.GET_MY_COMPANY],
    getMyCompany.bind(this),
  );

  const companyUsersQuery = useQuery<{ results: IUser[] }, Error>(
    [
      QueryNamesEnums.GET_COMPANY_USERS,
      { companyId: companyQuery.data?.id, sorting: 'first_name' },
    ],
    getCompanyUsers.bind(this, {
      companyId: companyQuery.data?.id,
      sorting: '&sorting=first_name',
    }),
    { enabled: Boolean(companyQuery.data?.id) },
  );

  const handleChange = (event: SelectChangeEvent) => {
    setCoordinatorId(event.target.value);
  };

  const updateRequestMutation = useMutation<Response, Error, UpdateRequestPayload>(
    updateDrawRequestCoordinator,
    {
      onSuccess: () => {
        if (flags?.['ENG_7910_updates_based_on_draw_events']) {
          handleAssignCoordinatorInvalidation({ projectId, drawRequestId: requestId });
        } else {
          queryClient.invalidateQueries([
            QueryNamesEnums.GET_DRAW_REQUEST,
            { projectId, drawRequestId: requestId },
          ]);
          queryClient.invalidateQueries(QueryNamesEnums.GET_DRAW_REQUEST_LIST);
        }
        enqueueSnackbar('Coordinator assigned', { variant: 'success' });
        onClose();
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const updateCoordinatorMutation = useMutation<Response, Error, IProjectCoordinator>(
    updateProjectCoordinator,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT);
        queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT_COMPANIES);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const onAssignCoordinator = useCallback(async () => {
    await updateRequestMutation.mutateAsync({
      project: projectId,
      drawRequest: requestId,
      coordinator: coordinatorId,
    });

    if (shouldUpdateProjectCoordinator) {
      updateCoordinatorMutation.mutateAsync({ projectId, coordinatorId });
    }
  }, [coordinatorId, shouldUpdateProjectCoordinator]);

  const companyUsers = useMemo(() => {
    if (companyUsersQuery.data?.results?.length)
      return filterActiveUsers(companyUsersQuery.data.results)?.map((x) => ({
        id: x.id,
        full_name: getUserFullName(x),
      }));
    return [];
  }, [companyUsersQuery.data]);

  return {
    users: companyUsers,
    onAssignCoordinator,
    handleChange,
    coordinatorId,
    shouldUpdateProjectCoordinator,
    setUpdateProjectCoordinator,
  };
};
