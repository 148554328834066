import * as React from 'react';
import { DataGridPremium, GridToolbarQuickFilter } from '@mui/x-data-grid-premium';
import useDataTable from './useDataTable';
import { TableKeyEnum } from '@interfaces';
import { TableStyle } from './styled';
import Box from '@mui/material/Box';
interface DataTableV3Interface {
  columns: any[];
  rows: any[];
  totals?: any;
  tableKey: TableKeyEnum;
  errors?: any;
  rowUpdateApi?: ({ id, json }) => void;
}

const DataTableV3 = ({
  columns,
  rows,
  tableKey,
  rowUpdateApi,
  totals,
  errors,
}: DataTableV3Interface) => {
  const {
    isTablePinned,
    requiredToPin,
    tabelRef,
    apiRef,
    isCellEditable,
    processRowUpdate,
    handleColumnWidthChange,
    handlePinnedColumnsChange,
    handleColumnOrderChange,
    initialState,
  } = useDataTable(tableKey, rows, errors, rowUpdateApi);

  function QuickSearchToolbar() {
    return (
      <Box
        sx={{
          position: 'absolute',
          zIndex: 10000,
          p: 0.5,
          pb: 0,
          top: -60,
          left: 0,
          width: 100,
          ml: 1,
        }}
      >
        <GridToolbarQuickFilter
          quickFilterParser={(searchInput: string) =>
            searchInput
              .split(',')
              .map((value) => value.trim())
              .filter((value) => value !== '')
          }
        />
      </Box>
    );
  }

  return (
    <TableStyle ref={tabelRef} requiredToPin={requiredToPin} isTablePinned={isTablePinned}>
      <DataGridPremium
        autoHeight={requiredToPin}
        isCellEditable={isCellEditable}
        processRowUpdate={processRowUpdate}
        onColumnWidthChange={handleColumnWidthChange}
        onPinnedColumnsChange={handlePinnedColumnsChange}
        onColumnOrderChange={handleColumnOrderChange}
        getRowClassName={(params) => `${params.row.id}`}
        cellSelection
        rowReordering
        hideFooter
        apiRef={apiRef}
        columns={columns}
        initialState={{ ...initialState }}
        pinnedRows={{
          bottom: totals ? [totals] : [],
        }}
        rows={rows}
        slots={{ toolbar: isTablePinned ? null : QuickSearchToolbar }}
      />
    </TableStyle>
  );
};

export default DataTableV3;
