import React, { useMemo, FC, useContext, useCallback } from 'react';
import { CommonRowType, MilestoneListColumnTypeV2 } from './common';
import { PermissionsContext } from '@context';
import { isRestricted, useBlockerCell } from '@utils';
import { DeleteIcon } from '@svgAsComponents';
import { colors } from '@theme';
import { PermissionNamesEnums } from '@interfaces';
import { IconButton } from '@mui/material';
import { DrawerContext } from '../drawer';

const DeleteIconRow: FC<{ row: CommonRowType }> = ({ row }) => {
  const isDeleting = useBlockerCell({ milestoneId: row.id });
  const { permissions } = useContext(PermissionsContext);
  const { deleteMilestone } = useContext(DrawerContext);

  const isEditable = useMemo(
    () =>
      row.activeToEdit &&
      row.localNew &&
      !isRestricted(PermissionNamesEnums.DRAWREQUESTS_LINE_ITEM_CREATE, permissions),
    [permissions, row],
  );
  const deleteCallback = useCallback(() => deleteMilestone(row.id), [row.id]);

  return (
    <IconButton disabled={isDeleting || !isEditable} onClick={deleteCallback}>
      <DeleteIcon size={24} color={colors.icons.gray} />
    </IconButton>
  );
};

const deleteItem: MilestoneListColumnTypeV2 = {
  field: 'proofpoints',
  headerAlign: 'right',
  headerClassName: 'icon',
  cellClassName: 'icon',
  renderHeader: () => null,
  editable: false,
  pinnable: false,
  disableColumnMenu: true,
  sortable: false,
  filterable: false,
  groupable: false,
  aggregable: false,
  hideable: false,
  resizable: false,
  renderCell: (params) => <DeleteIconRow row={params.row} />,
  width: 50,
};

export default deleteItem;
