import React, { FC, memo, useContext, useMemo } from 'react';
import { CommonRowType, MilestoneListColumnType } from './common';
import { TableContext } from '../controller';
import { DocumentsIconWithIndicator, PhotoGalleryIconWithIndicator } from '@svgAsComponents';
import { ColumnV2Width, RequestTableTabsEnum } from '@interfaces';
import { VerticalMenu } from '@components';
import { IconButton } from '@mui/material';
import { colors } from '@theme';
import { SettingsContext } from '@context';

const DocumentsPhotosMenu: FC<{ row: CommonRowType }> = ({ row }) => {
  const { updateRightDrawer, source } = useContext(TableContext);
  const { isPHBProject } = useContext(SettingsContext);

  const menuItems = useMemo(
    () => [
      {
        action: updateRightDrawer
          ? updateRightDrawer({
              milestoneId: row?.id,
              milestoneSubmitId: row?.milestone_submit_id,
              milestoneName: row?.name,
              tab: RequestTableTabsEnum.DOCUMENTS,
            })
          : row?.updateRightDrawer?.(),
        text: 'Documents',
        icon: (
          <DocumentsIconWithIndicator
            hasDocuments={row.documents_preview?.has_docs}
            hasUnreadDocuments={row.documents_preview?.has_not_seen_docs}
          />
        ),
        dataTestName: `${source}__body__documents_gallery__menu_item`,
      },
      ...(isPHBProject
        ? []
        : [
            {
              action: updateRightDrawer({
                tab: RequestTableTabsEnum.PHOTOS,
                milestoneId: row?.id,
                milestoneSubmitId: row?.milestone_submit_id,
                milestoneName: row?.name,
              }),
              text: 'Progress photos',
              icon: (
                <PhotoGalleryIconWithIndicator
                  color={colors.icons.green}
                  hasImages={row.documents_preview?.has_photos}
                  hasUnseenImages={row.documents_preview?.has_not_seen_photos}
                />
              ),
              dataTestName: `${source}__body__photos_gallery__menu_item`,
            },
          ]),
    ],
    [row, updateRightDrawer, source, isPHBProject],
  );

  return (
    <>
      <VerticalMenu menuItems={menuItems}>
        <IconButton
          data-cy={`${source}__body__documents_gallery__icon__index_${row.project_milestone?.index}`}
        >
          <DocumentsIconWithIndicator
            hasDocuments={row.documents_preview?.has_docs || row.documents_preview?.has_photos}
            hasUnreadDocuments={
              row.documents_preview?.has_not_seen_docs || row.documents_preview?.has_not_seen_photos
            }
          />
        </IconButton>
      </VerticalMenu>
    </>
  );
};

const Memoized = memo(DocumentsPhotosMenu);

const documentsPhotosGalleryMenu: MilestoneListColumnType = {
  name: 'documents_photos_gallery_menu',
  columnText: '',
  Header: '',
  justifyContent: 'center',
  noBorder: true,
  noMargin: true,
  renderCell: ({ row }) => <Memoized row={row} />,
  minWidth: () => ColumnV2Width.ICON,
  maxWidth: () => ColumnV2Width.ICON,
};

export default documentsPhotosGalleryMenu;
