export enum PermissionNamesEnums {
  ANALYTICS_DASHBOARD_VIEW = 'analytics_dashboard_view',
  ANALYTICS_EXTERNAL_LOGIN = 'analytics_external_login',
  AUDITLOG_VIEW = 'auditlog_view',
  COMMENTS_ALL_VIEW = 'comments_all_view',
  COMPANY_EDIT = 'company_edit',
  COMPANY_POLICIES_VIEW = 'company_policies_view',
  COMPANY_TEAMS_EDIT = 'company_teams_edit',
  COMPANY_TEAMS_VIEW = 'company_teams_view',
  COMPANY_USERS_EDIT = 'company_users_edit',
  CUSTOMER_SUCCESS_ACCESS = 'customer_success_access',
  DOCUMENTS_EDIT = 'documents_edit',
  DRAWREQUESTS_APPROVE = 'drawrequests_approve',
  DRAWREQUESTS_AUTOFILL_INSPECTOR_ALLOWANCE_REQUESTED = 'drawrequests_autofill_inspector_allowance_requested',
  DRAWREQUESTS_COORDINATOR_EDIT = 'drawrequests_coordinator_edit',
  DRAWREQUESTS_COORDINATOR_VIEW = 'drawrequests_coordinator_view',
  DRAWREQUESTS_CREATE = 'drawrequests_create',
  DRAWREQUESTS_CREDIT_APPROVE = 'drawrequests_credit_approve',
  DRAWREQUESTS_CREDIT_REQUEST = 'drawrequests_credit_request',
  DRAWREQUESTS_FUND = 'drawrequests_fund',
  DRAWREQUESTS_LINE_ITEM_CREATE = 'drawrequests_line_item_create',
  DRAWREQUESTS_MANUAL_CREATE = 'drawrequests_manual_create',
  DRAWREQUESTS_REVIEWER_TEAM_EDIT = 'drawrequests_reviewer_team_edit',
  DRAWREQUESTS_REVIEWER_TEAM_VIEW = 'drawrequests_reviewer_team_view',
  INSPECTION_STATUS_VIEW = 'inspection_status_view',
  INSPECTIONS_EDIT = 'inspections_edit',
  INSPECTIONS_VIEW = 'inspections_view',
  PAYMENTS_GATEWAY_OPTIONS_EDIT = 'payments_gateway_options_edit',
  PAYMENTS_MARK_AS_PAID = 'payments_mark_as_paid',
  PAYMENTS_PARTIES_EDIT = 'payments_parties_edit',
  POLICIES_EDIT = 'policies_edit',
  POLICIES_MY_TEAM_ROLE_VIEW = 'policies_my_team_role_view',
  POLICIES_OWN_ADDITIONAL_ITEMS_EDIT = 'policies_own_additional_items_edit',
  POLICIES_VIEW = 'policies_view',
  PROJECT_BUDGET_EDIT = 'project_budget_edit',
  PROJECT_COMPANIES_VIEW = 'project_companies_view',
  PROJECT_ONBOARDING = 'project_onboarding',
  PROJECTS_DETAILS_EDIT = 'projects_details_edit',
  PROJECTS_EDIT = 'projects_edit',
  PROJECTS_EDIT_STATUS = 'projects_edit_status',
  PROJECTS_REPORT_VIEW = 'projects_report_view',
  RISKRADAR_VIEW = 'riskradar_view',
  SCORES_VIEW = 'scores_view',
}
