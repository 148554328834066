import { currencyFormatter } from '@utils';
import { MilestoneListColumnTypeV2 } from './common';

const inspectorAllowanceIncremental: MilestoneListColumnTypeV2 = {
  field: 'inspector_allowance_incremental',
  headerAlign: 'right',
  headerClassName: 'number',
  cellClassName: 'number',
  headerName: 'Inspection recommendation ($)',
  editable: false,
  valueFormatter: (value) => currencyFormatter(value, '-'),
  minWidth: 120,
};

export default inspectorAllowanceIncremental;
