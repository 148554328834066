import React, { FC } from 'react';
import { Stack } from '@mui/material';
import { colors } from '@theme';
import { Filter, MilestoneListWithPatch, ToggleButtonGroup } from '@components';
import { TableKeyEnum } from '@interfaces';
import { useInspectionTable } from './controller';
import UnitsFilter from '../UnitsFilter';

const InspectionTableV2: FC<{
  showRequestedAmount?: boolean;
  tableKey?: TableKeyEnum;
  containerRef: React.RefObject<HTMLDivElement>;
}> = ({ showRequestedAmount, tableKey = TableKeyEnum.INSPECTION_RESULTS, containerRef }) => {
  const {
    initColumns,
    onExpandClick,
    filterOptions,
    milestones,
    filterValue,
    handleFilterClick,
    isLoading,
    patchInspectionMSgroup,
    totals,
    activeView,
    setActiveView,
    viewTypes,
    handleUnitsFilterClick,
    unitsFilterValues,
    isLineItemsView,
    filteredMilestones,
    patchInspectionMilestone,
  } = useInspectionTable(showRequestedAmount);
  const isEditable = tableKey === TableKeyEnum.INSPECTION_ENTER_RESULTS;
  const patchFunction = isLineItemsView ? patchInspectionMilestone : patchInspectionMSgroup;

  return (
    <Stack
      sx={{ backgroundColor: colors.background.white, pt: 1, borderRadius: '4px', flexGrow: 1 }}
    >
      <MilestoneListWithPatch
        patchMilestone={isEditable ? patchFunction : undefined}
        withColumnIndication
        tableKey={tableKey}
        initColumns={initColumns}
        milestones={isLineItemsView ? filteredMilestones : milestones}
        key="listWithEditModels"
        onExpandTable={onExpandClick}
        totals={isLineItemsView ? undefined : totals}
        headerLeftPart={[
          {
            Component: (
              <Stack sx={{ mr: 2 }}>
                <ToggleButtonGroup
                  value={activeView}
                  typesList={viewTypes}
                  handleChange={(_e, value) => value && setActiveView(value)}
                  size="small"
                  source="inspection_results__line_items_table"
                />
              </Stack>
            ),
          },
          ...(isLineItemsView
            ? [
                {
                  Component: (
                    <UnitsFilter
                      unitsFilterValues={unitsFilterValues}
                      handleUnitsFilterClick={handleUnitsFilterClick}
                    />
                  ),
                },
              ]
            : [
                {
                  Component: (
                    <Filter
                      filterLabel="Show"
                      onChangeCallback={handleFilterClick}
                      options={filterOptions}
                      filterValue={filterValue}
                      source="inspection_results__line_items_table__filter"
                    />
                  ),
                },
              ]),
        ]}
        source="inspection_results__line_items_table"
        isLoading={isLoading}
        lockSecondColumn
        containerRef={containerRef}
      />
    </Stack>
  );
};
export default InspectionTableV2;
