import { MilestoneListColumnTypeV2 } from './common';
import { percentFormatter } from '@utils';

const requestedAdjustmentRate: MilestoneListColumnTypeV2 = {
  field: 'requested_adjustments_rate',
  headerAlign: 'right',
  valueFormatter: (value) => percentFormatter({ value }),
  headerClassName: 'number',
  cellClassName: 'number',
  headerName: 'Requested +/- change (%)',
  description: '% of requested change to revised scheduled value',
  editable: false,
  minWidth: 120,
};

export default requestedAdjustmentRate;
