import React from 'react';
import { currencyFormatter } from '@utils';
import { Stack } from '@mui/material';
import { ProgressionSum, MilestoneListColumnTypeV2 } from './common';

const retainageBalanceTodateRequested: MilestoneListColumnTypeV2 = {
  field: 'retainage_balance_to_date',
  headerAlign: 'right',
  headerClassName: 'number',
  cellClassName: 'number',
  headerName: 'Retainage balance to date ($)',
  description: 'Amount retainage balance to date ($)',
  renderCell: ({ row }) => {
    const progress = -1 * row.retainage_release_requested;
    const sum = row.retainage_balance_to_date + row.retainage_release_requested;

    return (
      <Stack lineHeight="14px" alignItems="flex-end">
        {currencyFormatter(sum, '-')}
        <ProgressionSum sum={sum} progress={progress} />
      </Stack>
    );
  },
  editable: false,
  minWidth: 120,
};

export default retainageBalanceTodateRequested;
