import { useContext, useMemo, useState } from 'react';
import {
  checkIsAdmin,
  getTeamRole,
  isActiveProject,
  isCreatedProject,
  isProjectPaused,
  isRestricted,
} from '@utils';
import {
  IProject,
  IRightMenu,
  PermissionNamesEnums,
  ProjectStatusEnum,
  QueryNamesEnums,
} from '@interfaces';
import { AuthContext, PermissionsContext, SettingsContext, useLaunchDarklyFlags } from '@context';
import { ProjectStatusMap } from '@constants';
import { useQuery } from 'react-query';
import { getProject } from '@globalService';
import { useParams } from 'react-router-dom';
import { useProjectActivationWarning, useRightMenu } from '@hooks';

export interface ControllerInterface {
  valuesBasedOnPermissions: {
    statusesList: { [key: string]: string };
    labelText: string;
  };
  changeStatusComponent: {
    open: boolean;
    handleOpen: (value: boolean) => void;
  };
  showAuditLog: boolean;
  rightMenu: IRightMenu;
  handleRightDrawerOpenerClick: ({ title }: { title: string }) => void;
  filters: { tags: { tag: string }[] };
  handleFiltersChange: (newFilters: { tags: string[] }) => void;
  handleSortClick: () => void;
  sorting: boolean;
}

export const useProjectOptions = (): ControllerInterface => {
  const flags = useLaunchDarklyFlags();
  const { projectId } = useParams();
  const [isChangeStatusModalOpened, setIsChangeStatusModalOpened] = useState(false);
  const handleChangeStatusModal = (value) => setIsChangeStatusModalOpened(value);
  const { isCurrentProjectActive, isCurrentProjectArchived } = useContext(SettingsContext);
  const { permissions } = useContext(PermissionsContext);
  const { user } = useContext(AuthContext);
  const teamRole = getTeamRole(user);
  const projectActivationWarning = useProjectActivationWarning({
    projectId,
  });

  const { handleRightDrawerOpenerClick, ...rightMenu } = useRightMenu({});

  const [sorting, setSorting] = useState<boolean>(true);
  const [filters, setFilters] = useState({ tags: [] });

  const handleSortClick = () => setSorting((old) => !old);

  const handleFiltersChange = (newFilters) =>
    setFilters((prevValue) => ({ ...prevValue, ...newFilters }));

  const projectQuery = useQuery<IProject, Error>(
    [QueryNamesEnums.GET_PROJECT, { projectId }],
    getProject.bind(this, projectId),
    { enabled: !!projectId },
  );

  const valuesBasedOnPermissions = useMemo(() => {
    const isEditStatusEnabled = !isRestricted(
      PermissionNamesEnums.PROJECTS_EDIT_STATUS,
      permissions,
    );

    let statusesList = {};

    if (isEditStatusEnabled) {
      if (
        isProjectPaused(projectQuery.data) ||
        (isCreatedProject(projectQuery.data?.status) && !projectActivationWarning) ||
        isCurrentProjectArchived
      ) {
        statusesList[ProjectStatusEnum.ACTIVE] = ProjectStatusMap[ProjectStatusEnum.ACTIVE];
      }

      if (
        flags?.['ENG_8529_project_status_discarded'] &&
        (isProjectPaused(projectQuery.data) ||
          isCreatedProject(projectQuery.data?.status) ||
          isCurrentProjectArchived)
      ) {
        statusesList[ProjectStatusEnum.DISCARDED] = ProjectStatusMap[ProjectStatusEnum.DISCARDED];
      }

      statusesList[ProjectStatusEnum.INACTIVE_COMPLETE] =
        ProjectStatusMap[ProjectStatusEnum.INACTIVE_COMPLETE];
      statusesList[ProjectStatusEnum.INACTIVE_INCOMPLETE] =
        ProjectStatusMap[ProjectStatusEnum.INACTIVE_INCOMPLETE];

      if (isActiveProject(projectQuery.data?.status)) {
        statusesList[ProjectStatusEnum.PAUSED] = ProjectStatusMap[ProjectStatusEnum.PAUSED];
      }

      if (isActiveProject(projectQuery.data?.status) || isProjectPaused(projectQuery.data)) {
        statusesList[ProjectStatusEnum.CREATED] = ProjectStatusMap[ProjectStatusEnum.CREATED];
      }
    }

    if (checkIsAdmin(teamRole)) {
      statusesList = ProjectStatusMap;
    }

    return {
      statusesList,
      labelText: 'Update project status',
    };
  }, [
    permissions,
    teamRole,
    isCurrentProjectActive,
    projectQuery.data,
    projectActivationWarning,
    isCurrentProjectArchived,
    flags,
  ]);

  const showAuditLog = useMemo(
    () => !isRestricted(PermissionNamesEnums.AUDITLOG_VIEW, permissions),
    [permissions],
  );

  return {
    valuesBasedOnPermissions,
    changeStatusComponent: {
      open: isChangeStatusModalOpened,
      handleOpen: handleChangeStatusModal,
    },
    showAuditLog,
    rightMenu,
    filters,
    handleFiltersChange,
    handleSortClick,
    sorting,
    handleRightDrawerOpenerClick,
  };
};
