import React from 'react';
import { useParams } from 'react-router';
import {
  Box,
  Button,
  Divider,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import findIndex from 'lodash/findIndex';
import { HookState, IPhoto, PopupTypeEnum } from '@interfaces';
import {
  AddPhotoBox,
  ConfirmationModal,
  Filter,
  ImageSlickCarousel,
  LoadingSkeleton,
  PhotoCardWithPopper,
  PhotoDetails,
  ServiceMessage,
  StyledBox,
  ViewSwitcher,
} from '@components';
import { AddPhotoIcon, PlusIcon } from '@svgAsComponents';
import { colors } from '@theme';
import NoImages from 'encirclelabs-assets/no_images.svg';
import * as Controller from './controller';

const PhotosTab = () => {
  const controller = Controller.usePhotos();
  const {
    state,
    VIEWS_TYPES,
    handleViewType,
    activePhoto,
    handleFiltersChange,
    filterOptions,
    filterValue,
    milestoneFilterValue,
    handleMilestoneFilterClick,
    milestonesOptions,
    isListView,
  } = controller;

  switch (state) {
    case HookState.FETCHING: {
      return <LoadingSkeleton type="overviewBlock" />;
    }
    case HookState.ERROR: {
      return <ServiceMessage text="photos" />;
    }

    case HookState.SUCCESS: {
      return (
        <StyledBox sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
          {activePhoto && <PhotosDetails controller={controller} />}
          {!activePhoto && (
            <>
              <Stack direction="row" justifyContent="space-between" sx={{ pb: 3 }}>
                <Stack direction="row" spacing={2} justifyContent="flex-start">
                  {/* if only default option 'All' and one other option not to show filters */}
                  {filterOptions?.length > 2 ? (
                    <Filter
                      filterLabel="Photos"
                      onChangeCallback={handleFiltersChange}
                      options={filterOptions}
                      filterValue={filterValue}
                      source="photos_tab__list__filter__by_source"
                    />
                  ) : (
                    <Box sx={{ flex: 1 }} />
                  )}
                  {isListView && (
                    <Filter
                      filterLabel="By line items"
                      onChangeCallback={handleMilestoneFilterClick}
                      options={milestonesOptions}
                      filterValue={milestoneFilterValue}
                      source="photos_tab__list__filter__by_line_item"
                    />
                  )}
                </Stack>

                <Stack direction="row" ml={5}>
                  <ViewSwitcher
                    isListView={isListView}
                    source="photos_tab__list__view_switcher"
                    handleChangeView={(listView) =>
                      handleViewType(
                        null,
                        listView ? VIEWS_TYPES.LIST.value : VIEWS_TYPES.GALLERY.value,
                      )
                    }
                  />
                </Stack>
              </Stack>
              {!isListView && <GalleryViewNew controller={controller} />}
              {isListView && <ListView controller={controller} />}
            </>
          )}
        </StyledBox>
      );
    }

    default:
      return null;
  }
};

export default PhotosTab;

const GalleryViewNew = ({ controller }) => {
  const { projectId } = useParams();
  const { photos, handlePhotoClick, drawRequestId, isAddPhotoAvailable } = controller;

  if (!photos?.length)
    return (
      <Stack justifyContent="center" alignItems="center" sx={{ flexGrow: 1, mb: 6 }}>
        <img src={NoImages} style={{ maxWidth: '100%', width: '182px' }} alt="no photos" />
        <Typography variant="h2" sx={{ mt: 3, color: colors.text.heading }}>
          No photos
        </Typography>
        {isAddPhotoAvailable && (
          <AddPhotoBox
            projectId={projectId}
            drawRequestId={drawRequestId}
            dataTestName="photos_tab__gallery__photos__upload__button"
            iconBackgroundColor={colors.main.primary.lightest}
          />
        )}
      </Stack>
    );

  return (
    <Grid container direction="row" spacing={2}>
      {isAddPhotoAvailable && (
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2} id="upload-photo">
          <Stack
            sx={{
              backgroundColor: colors.background.lightest,
              width: '100%',
              height: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              minHeight: 146,
            }}
          >
            <AddPhotoBox
              projectId={projectId}
              drawRequestId={drawRequestId}
              dataTestName="photos_tab__gallery__photos__upload__button"
              icon={<PlusIcon />}
              iconBackgroundColor={colors.main.primary.lightest}
            />
          </Stack>
        </Grid>
      )}
      {photos.map(
        (photo) =>
          photo.file_representations && (
            <Grid
              item
              key={photo.id}
              xs={12}
              sm={6}
              md={4}
              lg={3}
              xl={2}
              id="photo-container"
              onClick={handlePhotoClick(photo?.id)}
              data-cy="photos_tab__card__image__button"
            >
              <PhotoCardWithPopper
                photo={photo}
                size="medium"
                dataTestName={'photos_tab__card__image__button'}
              />
            </Grid>
          ),
      )}
    </Grid>
  );
};

const ListView = ({ controller }) => {
  const { photosByMilestone, handlePhotoClick, projectId, drawRequestId, isAddPhotoAvailable } =
    controller;

  return (
    <TableContainer>
      <Table>
        <TableBody>
          {photosByMilestone?.map((item, index) => (
            <TableRow
              key={index}
              sx={{
                maxWidth: '100%',
                '&:last-of-type': { '& td': { borderBottom: 'none' } },
              }}
            >
              <TableCell>
                <Typography variant="body3">
                  {item.milestone?.id
                    ? item.milestone?.name
                    : 'General (not tagged to a line item)'}
                </Typography>
              </TableCell>
              <TableCell sx={{ overflowX: 'scroll', maxWidth: '400px' }}>
                <Stack direction="row" spacing={1} sx={{ minHeight: '44px' }}>
                  {item.photos?.map((photo, idx) => (
                    <Box sx={{ minWidth: '107px' }} key={idx} onClick={handlePhotoClick(photo?.id)}>
                      <PhotoCardWithPopper
                        photo={{ ...photo, milestone: [item.milestone] }}
                        size="small"
                        dataTestName={`${'photos_tab__card__image__button'}_${index}_${idx}`}
                      />
                    </Box>
                  ))}
                </Stack>
              </TableCell>
              {isAddPhotoAvailable && (
                <TableCell>
                  <Box sx={{ width: 60, height: 60 }}>
                    <AddPhotoBox
                      projectId={projectId}
                      drawRequestId={drawRequestId}
                      milestoneId={item?.milestone?.id}
                      dataTestName={`photos_tab__list__photos__upload__icon_${index}`}
                      icon={<AddPhotoIcon color={colors.icons.green} />}
                    />
                  </Box>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const PhotosDetails = ({ controller }) => {
  const {
    closeCallback,
    photos,
    activePhoto,
    callbackOnPhotoChange,
    handlePhotoDelete,
    isPhotoDeleting,
    userId,
    handleProjectImageUpdate,
    confirmUpdateProjectCoverModal,
  } = controller;
  const lgMediaQuery = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const startIndex = findIndex(photos, (o) => (o as IPhoto).id === activePhoto?.id);

  return (
    <Stack
      sx={{
        width: '100%',
        maxWidth: '100%',
        height: '100%',
        flexDirection: { sm: 'column', lg: 'row' },
        alignItems: { sm: 'center', lg: 'flex-start' },
      }}
    >
      <Stack
        sx={{
          flex: 1,
          width: { sm: '90vh', lg: 'calc(100vw - 600px)' },
          mt: 4.5,
          borderRight: `1px solid ${colors.neutral.light}`,
        }}
      >
        <Stack justifyContent="center" alignItems="center" sx={{ flex: 1 }}>
          <ImageSlickCarousel
            slides={photos}
            startIndex={startIndex}
            callbackOnPhotoChange={callbackOnPhotoChange}
            activePhoto={activePhoto}
          />
        </Stack>
      </Stack>

      <Divider
        sx={{ mx: { sm: 0, lg: 4 }, my: { sm: 4, lg: 0 } }}
        orientation={lgMediaQuery ? 'vertical' : 'horizontal'}
      />
      <Stack justifyContent="space-between" sx={{ minWidth: 450, maxWidth: 450, height: '100%' }}>
        <PhotoDetails photo={activePhoto} size="medium" closeCallback={closeCallback} />
        <Stack direction="row" justifyContent="flex-end" spacing={1} mt={6} mb={2}>
          <LoadingButton
            color="error"
            variant="new"
            loading={isPhotoDeleting}
            onClick={() => handlePhotoDelete(activePhoto.id)}
            disabled={userId !== activePhoto.loaded_by?.id}
            data-cy="photos_tab__card__delete__button"
          >
            Delete
          </LoadingButton>
          <Button
            variant="new"
            color="secondary"
            component="a"
            href={activePhoto.link}
            download
            data-cy="photos_tab__card__download__button"
          >
            Download
          </Button>
          <Button
            onClick={confirmUpdateProjectCoverModal.askConfirm}
            variant="new"
            color="secondary"
            data-cy="photos_tab__card__make_as_cover__button"
          >
            Make as cover
          </Button>
        </Stack>
      </Stack>
      {confirmUpdateProjectCoverModal.isConfirmModalOpened && (
        <ConfirmationModal
          open={confirmUpdateProjectCoverModal.isConfirmModalOpened}
          title="Confirmation"
          text="This photo will be set as project cover photo."
          onClose={confirmUpdateProjectCoverModal.closeConfirmModal}
          confirmCallback={() => {
            handleProjectImageUpdate();
            confirmUpdateProjectCoverModal.closeConfirmModal();
          }}
          type={PopupTypeEnum.CONFIRMATION}
          source="photos_tab__card__make_as_cover"
        />
      )}
    </Stack>
  );
};
