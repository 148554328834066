import { useContext, useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import {
  CommonInspectionPayload,
  CreateAgencyPayload,
  CreateInspectionPayload,
  ErrorDual,
  ICompanyFull,
  IDrawRequest,
  IInspection,
  IInspectionAgency,
  IInspectionClosePopupEnum,
  IProject,
  IProjectDocument,
  QueryNamesEnums,
  UpdateInspectionPayload,
  DocumentTypeEnum,
} from '@interfaces';
import {
  createInspectionAgency,
  deleteInspection,
  getDrawRequest,
  getDrawRequestInspectionsList,
  getInspectionAgenciesByProject,
  getMyCompany,
  getProjectDocuments,
  getProjectInspectionsList,
  patchInspectionToProject,
  postInspectionToProject,
  scheduleInspectionToProject,
  updateProjectFields,
} from '@globalService';
import { AuthContext, useGetData } from '@context';
import { checkIsExternalUser, getTeamRole, isCreatedService } from '@utils';
import { useSafeSnackbar } from '@hooks';

export const useInspectionQueriesAndMutations = ({
  projectId,
  drawRequestId,
  onClose,
  isNewlyCreatedInspection,
  setIsNewlyCreatedInspection,
  setPopup,
}) => {
  const { user } = useContext(AuthContext);
  const teamRole = getTeamRole(user);
  const isExternalUser = checkIsExternalUser(teamRole);
  const { enqueueSnackbar } = useSafeSnackbar();
  const queryClient = useQueryClient();

  const getCreatedInspection = (inspectionsList) =>
    inspectionsList?.find(({ status }) => isCreatedService(status));

  const myCompanyQuery = useQuery<ICompanyFull, Error>(
    [QueryNamesEnums.GET_MY_COMPANY],
    getMyCompany.bind(this),
  );

  const drawRequestQuery = useQuery<IDrawRequest, Error>(
    [QueryNamesEnums.GET_DRAW_REQUEST, { projectId, drawRequestId }],
    getDrawRequest.bind(this, { projectId, drawRequestId }),
    { enabled: Boolean(drawRequestId) },
  );

  const drawRequestInspectionsQuery = useQuery<{ results: IInspection[] }, Error>(
    [QueryNamesEnums.GET_DRAW_REQUEST_INSPECTIONS, { projectId, drawRequestId }],
    getDrawRequestInspectionsList.bind(this, {
      projectId,
      drawRequestId,
    }),
    { enabled: Boolean(drawRequestId) },
  );

  const projectInspectionsQuery = useQuery<{ results: IInspection[] }, Error>(
    [QueryNamesEnums.GET_PROJECT_INSPECTIONS, { projectId }],
    getProjectInspectionsList.bind(this, {
      projectId,
    }),
    { enabled: Boolean(!drawRequestId && projectId) },
  );

  const createdInspection = useMemo(
    () =>
      getCreatedInspection(
        drawRequestId
          ? drawRequestInspectionsQuery.data?.results
          : projectInspectionsQuery.data?.results,
      ),

    [
      drawRequestInspectionsQuery.data?.results,
      projectInspectionsQuery.data?.results,
      drawRequestId,
    ],
  );

  const project = useGetData({
    type: QueryNamesEnums.GET_PROJECT,
    keys: [
      'inspection_agency',
      'inspection_additional_contact_name',
      'inspection_additional_contact_phone',
      'inspection_entry_access_code',
      'inspection_primary_contact_user_id',
      'id',
      'inspection_cadence',
    ],
    args: { projectId },
  });

  const stringQueryParamsByType = `&document_type=${DocumentTypeEnum.APPRAISAL}`;
  const projectDocumentsQuery = useQuery<{ results: IProjectDocument[] }, Error>(
    [
      QueryNamesEnums.GET_PROJECT_DOCUMENTS,
      { projectId, stringQueryParams: stringQueryParamsByType },
    ],
    getProjectDocuments.bind(this, { projectId, stringQueryParams: stringQueryParamsByType }),
    { enabled: Boolean(projectId) },
  );

  const inspectionAgenciesByProjectId = useQuery<{ results: IInspectionAgency[] }, Error>(
    [QueryNamesEnums.GET_INSPECTION_AGENCIES_BY_PROJECT, { projectId }],
    getInspectionAgenciesByProject.bind(this, projectId),
  );

  const deleteInspectionMutation = useMutation<Response, Error, CommonInspectionPayload>(
    deleteInspection,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_DRAW_REQUEST_INSPECTIONS,
          { projectId, drawRequestId },
        ]);
        queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_INSPECTIONS, { projectId }]);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const updateInfoProject = useMutation<
    Response,
    Error,
    { projectId: string; json: Partial<IProject> }
  >(updateProjectFields, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const scheduleInspection = useMutation<Response, ErrorDual, CommonInspectionPayload>(
    scheduleInspectionToProject,
    {
      onSuccess: () => {
        setPopup(IInspectionClosePopupEnum.SUCCESS);
      },
      onError: (error: ErrorDual) => {
        if (error?.code === 'inspection.schedule_missing_borrower_error') {
          setPopup(IInspectionClosePopupEnum.MISSING_BORROWER_PHONE);
          return;
        }
        onClose();
        const errorText = error?.message?.includes('status_code=200')
          ? 'An open order already exist and this product does not allow multiple open orders'
          : isExternalUser
            ? 'Project is not configured properly, please ask Customer Success for help'
            : 'Project is not configured properly, please check required fields';
        enqueueSnackbar(errorText, { variant: 'error' });
      },
      onSettled: (_data, _error, variables) => invalidateQueries(variables),
    },
  );

  const createInspection = useMutation<IInspection, Error, CreateInspectionPayload>(
    postInspectionToProject,
    {
      onSuccess: async () => {
        if (!isNewlyCreatedInspection) {
          setIsNewlyCreatedInspection(true);
        }
        if (drawRequestId) {
          drawRequestInspectionsQuery.refetch();
        } else {
          projectInspectionsQuery.refetch();
        }
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const orderInspection = useMutation<IInspection, Error, UpdateInspectionPayload>(
    patchInspectionToProject,
    {
      onSuccess: async (data: IInspection) => {
        await handleSchedulingInspection(data);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
      onSettled: (_data, _errors, variables) => invalidateQueries(variables),
    },
  );

  const createInspectionAgencyMutation = useMutation<IInspectionAgency, Error, CreateAgencyPayload>(
    createInspectionAgency,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryNamesEnums.GET_INSPECTION_AGENCIES);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const handleSchedulingInspection = async (inspection: IInspection) => {
    await scheduleInspection.mutateAsync({
      projectId,
      inspectionId: inspection?.id,
    });
  };

  const invalidateQueries = (variables) => {
    queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT_INSPECTIONS);
    queryClient.invalidateQueries(QueryNamesEnums.GET_DRAW_REQUEST_INSPECTIONS);
    queryClient.invalidateQueries([
      QueryNamesEnums.GET_PROJECT_INSPECTION_BY_ID,
      { projectId, inspectionId: variables?.inspectionId },
    ]);
  };

  return {
    myCompanyQuery,
    drawRequestQuery,
    drawRequestInspectionsQuery,
    projectInspectionsQuery,
    projectQuery: project,
    projectDocumentsQuery,
    inspectionAgenciesData: inspectionAgenciesByProjectId,
    deleteInspectionMutation,
    updateInfoProject,
    createInspection,
    orderInspection,
    createInspectionAgencyMutation,
    createdInspection,
    getCreatedInspection,
    scheduleInspection,
  };
};
