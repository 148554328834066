import { useCallback, useContext, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useLocation } from 'react-router-dom';
import { GridEventListener } from '@mui/x-data-grid';

import {
  convertReactTableColumnsToMuiDataGridColumns,
  getHookState,
  getLink,
  getSortQueryString,
  getTeamRole,
  isRestricted,
  tableHeaders as headers,
} from '@utils';
import {
  ColumnWidth,
  IInspection,
  IRightDrawerParams,
  IServiceOrder,
  PermissionNamesEnums,
  QueryNamesEnums,
  TableKeyEnum,
  ServiceOrderStatusEnum,
} from '@interfaces';
import { AuthContext, PermissionsContext, SettingsContext } from '@context';
import { getInspectionsListNew, getServiceOrdersListForAllProjects } from '@globalService';
import {
  useColumnFilterV2,
  useCommentsPreview,
  useRightMenu,
  useSorting,
  useTablePagination,
} from '@hooks';
import { ControllerInterface } from './interface';

export const useServicesQueue = (): ControllerInterface => {
  const { permissions } = useContext(PermissionsContext);
  const { settings } = useContext(SettingsContext);
  const { user } = useContext(AuthContext);
  const teamRole = getTeamRole(user);
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedRowId, setSelectedRowId] = useState(null);

  const serviceTypesMap = useMemo(
    () => settings?.display?.service_types,
    [settings?.display?.service_types],
  );

  const { page, rowsPerPage, rowsPerPageOptions, onPageChange, onRowsPerPageChange } =
    useTablePagination({});
  const { handleSortClick, sortValue } = useSorting();

  const [filterStringQuery, setFilterStringQuery] = useState<string>('');

  const { hiddenColumns, setColumnVisibilityModel, isColumnFilterUpdating } = useColumnFilterV2(
    TableKeyEnum.INSPECTION_LIST,
  );
  const [rightDrawerParams, setRightDrawerParams] = useState<IRightDrawerParams>({});

  const { updateCommentsPreviewInfo } = useCommentsPreview({
    projectId: rightDrawerParams.projectId,
    drawRequestId: rightDrawerParams.requestId,
  });

  const onRightDrawerClose = useCallback(() => {
    updateCommentsPreviewInfo();
    setRightDrawerParams((old) => ({ ...old, activeTab: '' }));
  }, [updateCommentsPreviewInfo]);

  const { handleRightDrawerOpenerClick, ...rightMenu } = useRightMenu({
    onClose: onRightDrawerClose,
  });
  const updateRightDrawer = ({
    title,
    projectId,
    requestId,
    inspectionId,
    serviceOrderId,
    activeTab,
  }: IRightDrawerParams) => {
    handleRightDrawerOpenerClick({ title });
    setRightDrawerParams({ projectId, requestId, inspectionId, serviceOrderId, activeTab });
  };

  const sortingString = getSortQueryString({ sortValue });
  const params = {
    pagination: 'true',
    offset: (page * rowsPerPage).toString(),
    limit: rowsPerPage.toString(),
    sorting: sortingString,
  };

  const queryParams = new URLSearchParams(params);
  const queryURL =
    queryParams.toString() +
    `${filterStringQuery ? `&${filterStringQuery}` : ''}` +
    `&excluded_status=${ServiceOrderStatusEnum.CREATED}`;

  const inspectionsQuery = useQuery<{ results: IInspection[]; count: number }, Error>(
    [QueryNamesEnums.GET_INSPECTIONS_LIST, { queryURL }],
    getInspectionsListNew.bind(this, { queryURL }),
    { keepPreviousData: true },
  );
  const { data, isLoading, isFetching } = useMemo(() => inspectionsQuery, [inspectionsQuery]);

  const serviceOrdersQuery = useQuery<{ results: IServiceOrder[]; count: number }, Error>(
    [QueryNamesEnums.GET_SERVICE_ORDERS_LIST, { queryURL }],
    // some huck to make agency filter work for both inspections and service orders
    getServiceOrdersListForAllProjects.bind(this, {
      stringQueryParams: queryURL.replace('inspection_agency', 'service_agency'),
    }),
    { keepPreviousData: true },
  );

  const rows = useMemo(
    () =>
      isLoading || serviceOrdersQuery.isLoading
        ? Array.from({ length: 10 }, (_, index) => ({ id: index.toString() }))
        : [...(data?.results || []), ...(serviceOrdersQuery.data?.results || [])].sort(
            (a, b) => new Date(b.ordered_at).getTime() - new Date(a.ordered_at).getTime(),
          ),
    [isLoading, data, serviceOrdersQuery],
  );

  const columns = useMemo(
    () =>
      convertReactTableColumnsToMuiDataGridColumns([
        headers.serviceName({
          isLoading,
          serviceTypesMap,
        }),
        headers.serviceType({
          isLoading,
          serviceTypesMap,
        }),
        headers.serviceStatus({
          isLoading,
          disableSortBy: true,
        }),
        headers.headerTemplate({
          headerName: 'Loan #',
          accessor: 'project.loan.external_id',
          isLoading,
          disableSortBy: true,
        }),
        headers.headerTemplate({
          isLoading,
          headerName: 'Project',
          accessor: 'project.name',
          disableSortBy: true,
          minWidth: ColumnWidth.WIDE_TEXT,
        }),
        headers.serviceAgencyColumn({
          isLoading,
          teamRole,
        }),
        headers.inspectionAgencyStatus({
          isLoading,
        }),
        headers.requestedServiceDate({
          header: 'Date requested',
          isLoading,
          disableSortBy: true,
        }),
        headers.scheduledInspectionDate({
          accessor: 'scheduled_at',
          header: 'Date scheduled',
          isLoading,
          disableSortBy: true,
        }),
        headers.date({
          accessor: 'completed_at',
          header: 'Date completed',
          isLoading,
          disableSortBy: true,
        }),
        headers.headerTemplate({
          headerName: 'External Id',
          accessor: 'provider_order_id',
          isLoading,
          disableSortBy: true,
        }),
        headers.drNumber({
          isLoading,
        }),
        headers.inspectorAllowanceColumn({
          isLoading,
        }),
        headers.projectStatus({
          isLoading,
          accessor: 'project.status',
          disableSortBy: true,
        }),
        ...(!isRestricted(PermissionNamesEnums.CUSTOMER_SUCCESS_ACCESS, permissions)
          ? [
              headers.headerTemplate({
                headerName: 'Customer',
                accessor: 'customer.name',
                isLoading,
                disableSortBy: true,
              }),
            ]
          : []),
        headers.serviceActions({
          isLoading,
          source: 'services_queue__table__body__actions',
        }),

        headers.serviceDocumentsAndComments({
          isLoading,
          updateRightDrawer,
          setSelectedRowId,
          serviceTypesMap,
          source: 'services_queue__table__body',
        }),
      ]),
    [isLoading, hiddenColumns, permissions, teamRole, serviceTypesMap],
  );

  const handleRowClick: GridEventListener<'rowClick'> = (params) => {
    const link = getLink({ row: params.row, tableKey: TableKeyEnum.SERVICES_LIST });
    link && navigate(link, { state: location.pathname });
  };

  const itemsCount = useMemo(
    () => data?.count + serviceOrdersQuery?.data?.count,
    [data, serviceOrdersQuery?.data?.count],
  );

  return {
    state: getHookState(inspectionsQuery),
    rows,
    columns,
    handleSortClick,
    sortValue,
    isFetching,
    isLoading,
    hiddenColumns,
    isColumnFilterUpdating,
    handleRowClick,
    setFilterStringQuery,
    setColumnVisibilityModel,
    paginationProps: {
      page,
      rowsPerPage,
      rowsPerPageOptions,
      onPageChange,
      onRowsPerPageChange,
      itemsCount,
    },
    rightDrawerParams,
    rightMenu,
    selectedRowId,
  };
};
