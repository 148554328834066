import { Dispatch, useCallback, useEffect, useMemo, useState } from 'react';

import {
  IInspection,
  IInspectionAgency,
  IInspectionClosePopupEnum,
  InspectionServiceEnum,
  ServiceOrderStatusEnum,
  IProject,
  IInspectionFields,
} from '@interfaces';
import { DateFieldModel, StringFieldModel, useStringFieldModel } from '@models';
import {
  isAutomatedInspection,
  debounceFunction,
  isValidDate,
  createDateAsLocal,
  isPseudoAutomatedInspection,
} from '@utils';
import { ConfirmModalHookInterface, useConfirmationModal, useInspectionFields } from '@hooks';
import { useInspectionQueriesAndMutations } from './utils';
export interface ControllerInterface {
  inspectionRequestedAt: DateFieldModel;
  inspectionComment: StringFieldModel;
  drNumber: string;
  project: IProject;
  cancelInputsValues: () => void;
  handleInspectionRequest: () => Promise<void>;
  isSubmitting: boolean;
  inspectionAgencySelected: IInspectionAgency;
  setInspectionAgencySelected: Dispatch<React.SetStateAction<IInspectionAgency>>;
  inspectionAgenciesList: IInspectionAgency[];
  manualInspectionAgenciesList: IInspectionAgency[];
  otherInspectionName: IInspectionAgency;
  setOtherInspection: Dispatch<React.SetStateAction<IInspectionAgency>>;
  isOtherInspectionSelected: boolean;
  isAutomatedInspectionSelected: boolean;
  isSubmitDisabled: boolean;
  orderInspectionWithoutAppraisalModal: ConfirmModalHookInterface;
  handleCheckAppraisalBeforeOrderingInspection: () => void;
  borrowerComment: string;
  inspectionFields: IInspectionFields;
  tooltipText: string;
  submitDisabledTooltipText: string;
  createdInspection: IInspection;
  isPseudoAutomatedInspectionSelected: boolean;
}

export const useInspectionDialog = (
  projectId: string,
  onClose: (popupType?: IInspectionClosePopupEnum) => void,
  drawRequestId?: string,
): ControllerInterface => {
  const [isNewlyCreatedInspection, setIsNewlyCreatedInspection] = useState(false);
  const [inspectionAgencySelected, setInspectionAgencySelected] = useState(null);
  const [otherInspectionName, setOtherInspection] = useState(null);
  const inspectionComment = useStringFieldModel({
    initValue: '',
  });
  const orderInspectionWithoutAppraisalModal = useConfirmationModal();

  const {
    myCompanyQuery,
    drawRequestQuery,
    drawRequestInspectionsQuery,
    projectInspectionsQuery,
    projectQuery,
    inspectionAgenciesData,
    deleteInspectionMutation,
    updateInfoProject,
    createInspection,
    orderInspection,
    createInspectionAgencyMutation,
    createdInspection,
    getCreatedInspection,
    scheduleInspection,
    projectAppraisalDocumentsQuery,
  } = useInspectionQueriesAndMutations({
    projectId,
    drawRequestId,
    onClose,
    isNewlyCreatedInspection,
    setIsNewlyCreatedInspection,
  });

  const {
    additionalContactName,
    additionalContactPhone,
    accessCode,
    primaryContactUserNotRequired,
    primaryContactUserList,
    isBorrowerUsersLoading,
    inspectionRequestedAt,
    isProjectInspectionSettingsUpdated,
    primaryContactPhone,
  } = useInspectionFields({
    project: projectQuery?.data,
  });

  useEffect(() => {
    if (drawRequestId ? !drawRequestInspectionsQuery.isSuccess : !projectInspectionsQuery.isSuccess)
      return;
    const createdInspectionInner = getCreatedInspection(
      drawRequestId
        ? drawRequestInspectionsQuery.data?.results
        : projectInspectionsQuery.data?.results,
    );

    if (!createdInspectionInner) {
      createInspection.mutateAsync({
        projectId,
        inspectionData: { ...(drawRequestId ? { draw_request: drawRequestId } : {}) },
      });
    } else {
      inspectionComment.setValue(createdInspectionInner.gc_comment || '');
      if (createdInspectionInner?.gc_requested_at) {
        inspectionRequestedAt.setValue(createDateAsLocal(createdInspectionInner.gc_requested_at));
      }
    }
  }, [drawRequestInspectionsQuery.isSuccess, projectInspectionsQuery.isSuccess, drawRequestId]);

  const isOtherInspectionSelected = useMemo(
    () => inspectionAgencySelected?.display_name === 'Other',
    [inspectionAgencySelected],
  );

  const isAutomatedInspectionSelected = useMemo(
    () => isAutomatedInspection(inspectionAgencySelected?.service),
    [inspectionAgencySelected],
  );
  const isPseudoAutomatedInspectionSelected = useMemo(
    () => isPseudoAutomatedInspection(inspectionAgencySelected?.service),
    [inspectionAgencySelected],
  );

  const handleInspectionRequest = useCallback(async () => {
    let inspectionAgencyId = '';

    if (!isOtherInspectionSelected) {
      inspectionAgencyId = inspectionAgencySelected?.id;
    } else if (!otherInspectionName?.id) {
      // Create new inspection agency if it's newly created input without id
      const data = await createInspectionAgencyMutation.mutateAsync({
        company: myCompanyQuery.data?.id,
        display_name: otherInspectionName?.display_name,
        service: InspectionServiceEnum.MANUAL,
      });
      inspectionAgencyId = data?.id;
    } else {
      inspectionAgencyId = otherInspectionName.id;
    }

    const inspectionValues = {
      comment: inspectionComment.value,
      inspection_agency: inspectionAgencyId,
      gc_requested_at:
        inspectionRequestedAt?.value && isValidDate(inspectionRequestedAt.value)?.value
          ? inspectionRequestedAt.value
          : new Date(),
      ...(isOtherInspectionSelected && { status: ServiceOrderStatusEnum.ORDERED }),
    };

    if (isProjectInspectionSettingsUpdated) {
      await updateInfoProject.mutateAsync({
        projectId,
        json: {
          inspection_additional_contact_name: additionalContactName.value,
          inspection_additional_contact_phone: additionalContactPhone.valueToSave,
          inspection_entry_access_code: accessCode.value,
          inspection_primary_contact_user_id: primaryContactUserNotRequired.value?.id || null,
        },
      });
    }

    const payload = {
      projectId,
      inspectionData: {
        ...inspectionValues,
        inspectionId: createdInspection.id,
      },
    };

    await orderInspection.mutateAsync(payload);
  }, [
    createdInspection,
    inspectionAgencySelected,
    otherInspectionName,
    myCompanyQuery.data?.id,
    inspectionComment.value,
    inspectionRequestedAt.value,
    isOtherInspectionSelected,
    isProjectInspectionSettingsUpdated,
    projectId,
    updateInfoProject,
    additionalContactName.value,
    additionalContactPhone.valueToSave,
    accessCode.value,
    primaryContactUserNotRequired.value,
  ]);

  const cancelInputsValues = () => {
    inspectionComment.setValue(createdInspection?.gc_comment || '');
    inspectionRequestedAt.setValue(
      createdInspection?.gc_requested_at ? new Date(createdInspection.gc_requested_at) : null,
    );
    if (isNewlyCreatedInspection) {
      deleteInspectionMutation.mutateAsync({ projectId, inspectionId: createdInspection?.id });
    }
  };

  const inspectionAgenciesList = useMemo(
    () => [
      ...(inspectionAgenciesData.data?.results?.filter(
        (o) => o.service !== InspectionServiceEnum.MANUAL,
      ) || []),
      { display_name: 'Other', service: InspectionServiceEnum.MANUAL, id: null },
    ],
    [inspectionAgenciesData.data],
  );

  const manualInspectionAgenciesList = useMemo(
    () =>
      inspectionAgenciesData.data?.results?.filter(
        (o) => o.service === InspectionServiceEnum.MANUAL && o.display_name !== 'Other',
      ) || [],
    [inspectionAgenciesData.data],
  );

  useEffect(() => {
    if (inspectionAgenciesList.length) {
      const defaultAgency =
        createdInspection?.inspection_agency || projectQuery.data?.inspection_agency;
      const serviceFromList = inspectionAgenciesList?.find((item) => item.id === defaultAgency?.id);

      setInspectionAgencySelected(serviceFromList || inspectionAgenciesList[0]);
    }
  }, [inspectionAgenciesList]);

  const submitDisabledTooltipText = useMemo(() => {
    if (isOtherInspectionSelected && !otherInspectionName?.display_name)
      return 'Please enter the name of the inspection agency.';

    if (
      !primaryContactUserNotRequired?.value &&
      (!additionalContactName.isValid || !additionalContactPhone.isValid)
    )
      return 'At least one contact with phone number (primary or additional) is required.';

    if (
      !(inspectionRequestedAt?.value
        ? inspectionRequestedAt.validate() && isValidDate(inspectionRequestedAt?.value)?.value
        : true) ||
      !additionalContactPhone.validate()
    )
      return 'Please enter valid values.';
    return '';
  }, [
    isOtherInspectionSelected,
    otherInspectionName,
    inspectionRequestedAt?.value,
    additionalContactPhone?.isValid,
    additionalContactName?.isValid,
    primaryContactUserNotRequired?.value,
  ]);

  const handleCheckAppraisalBeforeOrderingInspection = useCallback(
    debounceFunction(async () => {
      if (isAutomatedInspectionSelected && !projectAppraisalDocumentsQuery.data?.results?.length) {
        orderInspectionWithoutAppraisalModal.askConfirm();
      } else {
        handleInspectionRequest();
      }
    }, 300),
    [
      isAutomatedInspectionSelected,
      projectAppraisalDocumentsQuery.data?.results,
      handleInspectionRequest,
    ],
  );

  const tooltipText = useMemo(() => {
    if (isNewlyCreatedInspection || !createdInspection) return '';
    const hasComment = Boolean(createdInspection.gc_comment);
    const hasDate = Boolean(createdInspection.gc_requested_at);

    if (hasComment && hasDate) {
      return 'Borrower has already supplied the earliest availability date and additional information with the Draw request submission.';
    }
    if (hasComment) {
      return 'Borrower has already supplied additional information with the Draw request submission.';
    }
    if (hasDate) {
      return 'Borrower has already supplied the earliest availability date with the Draw request submission.';
    }
    return '';
  }, [createdInspection, isNewlyCreatedInspection]);

  return {
    inspectionComment,
    borrowerComment: createdInspection?.gc_comment || '',
    drNumber: drawRequestQuery.data?.number,
    project: projectQuery.data,
    cancelInputsValues,
    handleInspectionRequest,
    isSubmitting:
      orderInspection.isLoading || createInspection.isLoading || scheduleInspection.isLoading,
    inspectionAgencySelected,
    setInspectionAgencySelected,
    inspectionAgenciesList,
    manualInspectionAgenciesList,
    otherInspectionName,
    setOtherInspection,
    isOtherInspectionSelected,
    isAutomatedInspectionSelected,
    isSubmitDisabled: Boolean(submitDisabledTooltipText),
    orderInspectionWithoutAppraisalModal,
    handleCheckAppraisalBeforeOrderingInspection,
    inspectionRequestedAt,
    inspectionFields: {
      additionalContactName,
      additionalContactPhone,
      accessCode,
      primaryContactUserList,
      isBorrowerUsersLoading,
      inspectionRequestedAt,
      primaryContactUserNotRequired,
      primaryContactPhone,
    },
    tooltipText,
    submitDisabledTooltipText,
    createdInspection,
    isPseudoAutomatedInspectionSelected,
  };
};
